import BoxContent from "components/common/BoxContent";
import BoxLightBlue from "components/common/BoxLightBule";
import { Box } from "@mui/system";
import React, { useCallback, useEffect, useState } from "react";
import { Typography, Button, FormHelperText } from "@material-ui/core";
import CardTable from "components/common/ListTable/CardTable";
import { headerTableBudgetCategories } from "Datasource/dataCommonTable";
import Pagination from "@mui/material/Pagination";
import ModalComponent from "components/common/Modal";
import { Controller, useForm } from "react-hook-form";
import TextFieldComponent from "components/common/Input/TextField";
import SelectComponent from "components/common/Select/defeault";
import useMessage from "Hooks/useMessage";
import { useAppDispatch } from "App/Store";
import { AxiosError } from "axios";
import { changeLoading } from "App/Features/Animation";
import { contentApi } from "Datasource/content";
import ModalDelete from "components/common/Modal/ModalDelete";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import {Accordion, AccordionDetails, AccordionSummary} from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from "@mui/icons-material/Search";
import {newsApi} from "../../../Datasource/news";

interface CategoriesProps {}

interface FormSubmit {
  type?: any;
  name?: string;
  description?: string;
}

const listCategoryType = [
  { label: "Thu", value: "Thu" },
  { label: "Chi", value: "Chi" },
];

function Categories(props: CategoriesProps) {
  const { openMessage } = useMessage();

  const [pageNumberCategoriesList, setPageNumberCategoriesList] =
    useState<number>(1);
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("add");
  const [selectedBudgetCategoryID, setSelectedBudgetCategoryID] =
    useState<string>("");
  const [dataBudgetCategoriesList, setDataBudgetCategoriesList] =
    useState<any>();

  const dispatch = useAppDispatch();

  const getBudgetCategoriesList = async (pageNumber: number) => {
    try {
      const param: any = {
        page: pageNumber,
      };

      const {
        data: { META_DATA },
      }: any = await contentApi.getBudgetCategory(param);

      setPageNumberCategoriesList(META_DATA.currentPage);
      setDataBudgetCategoriesList(META_DATA);
      dispatch(changeLoading(false));
    } catch (error) {
      dispatch(changeLoading(false));

      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  useEffect(() => {
    dispatch(changeLoading(true));
    getBudgetCategoriesList(pageNumberCategoriesList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberCategoriesList]);

  const {
    register,
    handleSubmit: handleSave,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormSubmit>();

  const {
    register: register2,
    setValue: setValue2,
    control: control2,
    reset: reset2,
    formState: { errors: errors2 },
    handleSubmit: handleSearch,
  } = useForm<FormSubmit>();

  const renderModal = useCallback(() => {
    return (
      <>
        <Controller
          name="type"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <div className="mb-8">
              <SelectComponent
                value={value}
                onChange={onChange}
                data={listCategoryType}
                className="mb-4"
                placeholder="Loại thu/chi"
                customStyle={{ height: "40px" }}
                isSearchable={false}
                isClearable={false}
              />
                {errors.type && (
                  <FormHelperText
                      id="component-error-text"
                      className="text-red-500"
                  >
                    Đây là 1 trường bắt buộc
                  </FormHelperText>
                )}
            </div>
          )}
        />
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextFieldComponent
              label="Tên"
              value={value}
              onChangeValue={onChange}
              warningText={errors.name && "Đây là 1 trường bắt buộc"}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextFieldComponent
              label="Miêu tả"
              value={value}
              onChangeValue={onChange}
              warningText={errors.description && "Đây là 1 trường bắt buộc"}
            />
          )}
        />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listCategoryType]);

  const setValueEditingCategory = useCallback((item: any) => {
    const categoryTypeSelected = listCategoryType.filter(
      (type: any) => item.type === type.value
    );
    setValue("name", item.name);
    setValue("description", item.description);
    setValue("type", categoryTypeSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalType]);

  const handleResetAll = () => {
    reset();
    setOpenModal(false);
    setOpenDeleteModal(false);
    getBudgetCategoriesList(1);
    dispatch(changeLoading(false));
    setModalType("add");
    setSelectedBudgetCategoryID("");
  };

  const handleChangeRow = () => {
    setOpenModal(true);
  };

  const search = async (data: any) => {
    try {
      const param: any = {
        name: data.name,
        description: data.description,
      };

      const {
        data: { META_DATA },
      }: any = await contentApi.getBudgetCategory(param);

      setPageNumberCategoriesList(META_DATA.currentPage);
      setDataBudgetCategoriesList(META_DATA);
      dispatch(changeLoading(false));
    } catch (error) {
      dispatch(changeLoading(false));

      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const errorObject: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const onSubmit = async (data: any) => {
    try {
      if (modalType === "add") {
        dispatch(changeLoading(true));
        const params = {
          ...data,
          type: data.type.value,
          newCategory: data.name,
        };
        const res = await contentApi
          .postNewBudgetCategory(params)
          .then((res: any) => {
            openMessage({
              variant: `${res.data.STATUS_CODE === 201 ? "success" : "error"}`,
              message: `${
                res.data.STATUS_CODE === 201
                  ? "Thêm mới thành công"
                  : res.data.MESSAGE_CLIENT
              }`,
            });
            if (res.data.STATUS_CODE === 201) {
              handleResetAll();
            }
          });
        return res;
      } else if (modalType === "edit") {
        dispatch(changeLoading(true));
        const params = {
          name: data.name,
          description: data.description,
        };
        const res = await contentApi
          .handleEditBudgetCategory(params, selectedBudgetCategoryID)
          .then((res: any) => {
            openMessage({
              variant: `${res.data.STATUS_CODE === 200 ? "success" : "error"}`,
              message: `${
                res.data.STATUS_CODE === 200
                  ? "Cập nhật thành công"
                  : res.data.MESSAGE_CLIENT
              }`,
            });
            if (res.data.STATUS_CODE === 200) {
              handleResetAll();
            }
          });
        return res;
      }
    } catch (error) {
      dispatch(changeLoading(false));
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };
  

  const handleDeleteBudgetCategory = async (id: string) => {
    try {
      const res = await contentApi.handleDeleteBudgetCategory(id).then((res: any) => {
        openMessage({
          variant: `${res.data.STATUS_CODE === 204 ? "success" : "error"}`,
          message: `${
            res.data.STATUS_CODE === 204
              ? "Xóa thành công"
              : res.data.MESSAGE_CLIENT
          }`,
        });
        if (res.data.STATUS_CODE === 204) {
          setOpenDeleteModal(false);
          setSelectedBudgetCategoryID("");
          getBudgetCategoriesList(1);
          reset();
        }
      });
      return res;
    } catch (error) {
      const { response } = error as AxiosError;
      const { ...errorObject }: any = response;
      openMessage({
        variant: "error",
        message: errorObject?.data.message,
      });
    }
  };

  return (
    <>
      <BoxLightBlue />
      <BoxContent>
        <Accordion className="px-4">
          <AccordionSummary
              className="filter-div"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
          >
            <Typography className='font-bold'>Bộ lọc</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <form
                onSubmit={handleSearch(search)}
                id="content-form"
                className="mt-5"
            >
              <Box
                  display={"flex"}
                  sx={{"gap": "10px"}}
                  justifyContent={"space-between"}
                  marginTop={1}
              >
                <Controller
                  name="name"
                  control={control2}
                  rules={{ required: false }}
                  render={({ field: { onChange, onBlur, value } }) => (
                      <TextFieldComponent
                          label="Tên"
                          value={value}
                          onChangeValue={onChange}
                          // className="w-50"
                          customStyle={{ height: "40px" }}
                      />
                  )}
                />
                <Controller
                    name="description"
                    control={control2}
                    rules={{ required: false }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <TextFieldComponent
                            label="Miêu tả"
                            value={value}
                            onChangeValue={onChange}
                            // className="w-50"
                            customStyle={{ height: "40px" }}
                        />
                    )}
                />
              </Box>
              <Box className="w-full" display={"flex"}>
                <Button
                  className="m-button m-button-warning m-button-middle-div"
                  type="submit"
                >
                  <SearchIcon />
                  Tìm kiếm
                </Button>
              </Box>
            </form>
          </AccordionDetails>
        </Accordion>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className="my-4"
        >
          <Typography>Danh sách loại thu chi</Typography>
          <Button
              className="m-button m-button-success"
              type="button"
              onClick={() => {
                setOpenModal(true);
              }}
          >
            <AddIcon/> Thêm mới
          </Button>
        </Box>
        <CardTable
          dataHeaderTable={headerTableBudgetCategories}
          className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white overflow-x-auto"
          dataBodyTable={dataBudgetCategoriesList}
          handleAction={handleChangeRow}
          currentPage={1}
        >
          {dataBudgetCategoriesList &&
            dataBudgetCategoriesList.docs?.map((item: any, index: number) => (
              <tr key={index}>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                  <Typography className={"ml-3 font-bold text-blueGray-600"}>
                    {dataBudgetCategoriesList.currentPage <= 1
                      ? index + 1
                      : (dataBudgetCategoriesList.currentPage - 1) * 10 +
                        index +
                        1}
                  </Typography>
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                  <Typography>{item?.type}</Typography>
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                  <Typography>{item?.name}</Typography>
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                  <Typography>{item?.description}</Typography>
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center w-100">
                  <Box display={"flex"}>
                    <IconButton aria-label="delete" className='m-button-rounded m-button-warning mr-5' onClick={() => {
                      setOpenModal(true);
                      setValueEditingCategory(item);
                      setModalType("edit");
                      setSelectedBudgetCategoryID(item._id);
                    }}>
                      <EditIcon />
                    </IconButton>
                    <IconButton aria-label="delete" className='m-button-rounded m-button-danger' onClick={() => {
                      setOpenDeleteModal(true);
                      setSelectedBudgetCategoryID(item._id);
                    }}>
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </td>
              </tr>
            ))}
        </CardTable>
        <Pagination
          style={{ justifyContent: "center", display: "flex" }}
          count={1}
          onChange={(e, numberPage: number) => {
            setPageNumberCategoriesList(numberPage);
          }}
          color="primary"
        />
      </BoxContent>
      <ModalComponent
        classNameContent="bg-white"
        open={isOpenModal}
        modalType = {modalType}
        handleClose={() => {
          handleResetAll();
        }}
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 1000,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <>
          <Typography>
            {modalType === "add" ? "Thêm mới loại" : "Cập nhật loại"}
          </Typography>
          <form
            onSubmit={handleSave(onSubmit)}
            id="content-form-a"
            className="mt-5"
          >
            <Box overflow={"visible"} maxHeight={600} className="p-3">
              {renderModal()}
            </Box>
          </form>
        </>
      </ModalComponent>
      <ModalDelete
        classNameContent="bg-white"
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        isOpenModal={isOpenDeleteModal}
        onCloseModal={() => {
          handleResetAll();
        }}
        onClickConfirm={() => {
          handleDeleteBudgetCategory(selectedBudgetCategoryID);
        }}
      />
    </>
  );
}

export default Categories;
