import * as React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import {Tabs, Typography} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";


interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

interface Props {
    monthList: Array<any>;
    handleSelectMonth: (month: string) => void;
}

function PlanRevenueExpensesNavbar (props: Props) {
    const {
        monthList,
        handleSelectMonth
    } = props
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const result: any = [];

    for(let i = 0; i < monthList.length; i++) {
        result.push(
            monthList[i].isLocked ?
            <Tab label={monthList[i].month}
                 {...a11yProps(i)}
                 key={monthList[i].month}
                 onClick={() => handleSelectMonth(monthList[i])}
                 icon={<LockIcon />}
                 iconPosition='start'
            />
                : <Tab label={monthList[i].month}
                     {...a11yProps(i)}
                     key={monthList[i].month}
                     onClick={() => handleSelectMonth(monthList[i])}
                />
        )
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" className="w-full" centered variant="standard" >
                    {result}
                </Tabs>
            </Box>
            {/*<TabPanel value={value} index={0}>*/}
            {/*    Item One*/}
            {/*</TabPanel>*/}
            {/*<TabPanel value={value} index={1}>*/}
            {/*    Item Two*/}
            {/*</TabPanel>*/}
            {/*<TabPanel value={value} index={2}>*/}
            {/*    Item Three*/}
            {/*</TabPanel>*/}
        </Box>
    );
}
export default PlanRevenueExpensesNavbar
