/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useEffect, useState } from "react";
import BoxContent from "components/common/BoxContent";
import BoxLightBlue from "components/common/BoxLightBule";
import CardTable from "components/common/ListTable/CardTable";
import {
  headerTableTeams,
  headerTableDuLeader,
  headerTableMember,
} from "Datasource/dataCommonTable";
import {
  Avatar,
  Button,
  FormHelperText,
  Pagination,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import ModalComponent from "components/common/Modal";
import { Controller, useForm } from "react-hook-form";
import TextFieldComponent from "components/common/Input/TextField";
import { useDropzone } from "react-dropzone";
import { AxiosError } from "axios";
import useMessage from "Hooks/useMessage";
import { contentApi } from "Datasource/content";
import StatusSelect from "components/common/Select/status";
import SelectComponent from "components/common/Select/defeault";
import CloseIcon from "@mui/icons-material/Close";
import AvaDefault from "common/assets/images/1053244.png";
import ModalDelete from "components/common/Modal/ModalDelete";
import { useAppDispatch } from "App/Store";
import { changeLoading } from "App/Features/Animation";
import DatePickerComponent from "components/common/DatePicker";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
interface Props {}
interface FromSubmit {
  name?: string;
  order?: string | number;
  status: string;
  photo?: any;
  position?: string;
  team?: string;
  parentId?: string;
  onBoardDate?: Date;
  email?: string;
  dateOfBirth?: Date;
  employeeId?: string;
  displayName?: string;
}

const WhoWeAre = (props: Props) => {
  const {} = props;
  const { openMessage } = useMessage();
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [nameAction, setNameAction] = useState<string>("");
  const [imageUpload, setImageUpload] = useState<any>(null);
  const [nameImage, setNameImage] = useState<string>("");
  const [pageNumberTeamList, setPageNumberTeamList] = useState<number>(1);
  const [dataTeamList, setDataTeamList] = useState<any>();
  const [pageNumberMemberList, setPageNumberMemberList] = useState<number>(1);
  const [dataMemberList, setDataMemberList] = useState<any>();
  const [listTeam, setListTeam] = useState<any[]>([]);
  const [listMember, setListMember] = useState<any[]>([]);
  const [imageFile, setImageFile] = useState<any>();
  const [isOpenModalDelete, setOpenModalDeleted] = useState<boolean>(false);
  const [fieldChoose, setFieldChoose] = useState<any>();
  const [isOpenModalMemberDelete, setModalMemberDelete] =
    useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    getTeamList(pageNumberTeamList, "", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberTeamList]);

  useEffect(() => {
    getMemberPhotos(pageNumberMemberList, "", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberMemberList]);

  useEffect(() => {
    dispatch(changeLoading(true));
    getListTeam();
    getListMember();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FromSubmit>();

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (fs: File[]) => {
      setImageFile(fs[0]);
      setNameImage(fs[0].name);
      setImageUpload(URL.createObjectURL(fs[0]));
    },
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
    },
  });

  const getListTeam = async () => {
    try {
      const { data } = await contentApi.getAllTeam();
      let newParams: any = [];
      data.META_DATA.map((item: any, index: any) => {
        const param = {
          value: item._id,
          label: item.name,
        };
        newParams.push(param);
      });

      setListTeam(newParams);
      dispatch(changeLoading(false));
    } catch (error) {
      dispatch(changeLoading(false));
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const getTeamList = async (pageNumber: number, sortBy: string, orderBy: string) => {
    try {
      const param: any = {
        page: pageNumber,
        sortBy,
        orderBy
      };
      const { META_DATA }: any = await contentApi.getTeams(param);
      setPageNumberTeamList(META_DATA?.currentPage);
      setDataTeamList(META_DATA);
      dispatch(changeLoading(false));
      return;
    } catch (error) {
      dispatch(changeLoading(false));

      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const getListMember = async () => {
    try {
      const {
        data: { META_DATA },
      } = await contentApi.getAllMember();
      let newData: any = [];
      META_DATA.map((item: any, index: any) => {
        let newItem = {
          value: item._id,
          label: item.name,
          position: item.position,
        };
        newData.push(newItem);
      });
      setListMember(newData);
      dispatch(changeLoading(false));
      return;
    } catch (error) {
      dispatch(changeLoading(false));
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const getMemberPhotos = async (numberPage: number, sortBy: string, orderBy: string) => {
    try {
      const param: any = {
        page: numberPage,
        sortBy,
        orderBy
      };
      const {
        data: { META_DATA },
      } = await contentApi.getMembers(param);
      setPageNumberMemberList(META_DATA?.currentPage);
      setDataMemberList(META_DATA);
      dispatch(changeLoading(false));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(changeLoading(false));
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const renderModalByNameAction = useCallback(() => {
    switch (nameAction) {
      case "addTeam":
      case "editTeam":
        return (
          <>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextFieldComponent
                  label="Tên team"
                  value={value}
                  onChangeValue={onChange}
                  warningText={errors.name && "Đây là 1 trường bắt buộc"}
                />
              )}
            />
            <Controller
              name="order"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextFieldComponent
                  label="Xếp hạng"
                  value={value}
                  onChangeValue={onChange}
                  type="number"
                  warningText={errors.order && "Đây là 1 trường bắt buộc"}
                />
              )}
            />
            {/* <Controller
              name="status"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <StatusSelect value={value} onChange={onChange} />
                  {errors.status && (
                    <FormHelperText
                      id="component-error-text"
                      className="text-red-500"
                    >
                      Đây là 1 trường bắt buộc
                    </FormHelperText>
                  )}
                </>
              )}
            /> */}
          </>
        );
      case "add":
      case "edit":
        return (
          <>
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextFieldComponent
                  label="Name"
                  value={value}
                  onChangeValue={onChange}
                  warningText={errors.name && "Field this is required"}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextFieldComponent
                  label="Email"
                  value={value}
                  onChangeValue={onChange}
                  warningText={errors.email && "Field this is required"}
                  type="email"
                />
              )}
            />
            <Controller
              name="displayName"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextFieldComponent
                  label="Display Name"
                  value={value}
                  onChangeValue={onChange}
                  warningText={errors.displayName && "Field this is required"}
                />
              )}
            />
            <Controller
              name="employeeId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextFieldComponent
                  label="Employee Code"
                  value={value}
                  onChangeValue={onChange}
                  warningText={errors.employeeId && "Field this is required"}
                />
              )}
            />
            <Controller
              name="position"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextFieldComponent
                  label="Vị trí"
                  value={value}
                  onChangeValue={onChange}
                  warningText={errors.position && "Field this is required"}
                />
              )}
            />

            <div className="mt-3 mb-4">
              <Controller
                name="team"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <SelectComponent
                      value={value}
                      onChange={onChange}
                      data={listTeam}
                      className="mb-4 z-3"
                      placeholder="Team"
                    />
                    {errors.team && (
                      <FormHelperText
                        id="component-error-text"
                        className="text-red-500"
                      >
                        Đây là 1 trường bắt buộc
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </div>
            <Controller
              name="parentId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <SelectComponent
                    value={value}
                    onChange={onChange}
                    data={listMember}
                    className="mb-4 z-2"
                    placeholder="Manager"
                  />
                  {errors.parentId && (
                    <FormHelperText
                      id="component-error-text"
                      className="text-red-500"
                    >
                      Đây là 1 trường bắt buộc
                    </FormHelperText>
                  )}
                </>
              )}
            />
            <Controller
              name="onBoardDate"
              control={control}
              rules={{ required: false }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Box className="w-full">
                  <DatePickerComponent
                    onChangeDate={onChange}
                    startDate={value}
                    // startDateDefault={startDate}
                    selectsEnd={true}
                    className="w-full"
                    placeHolder="On Board"
                  />
                </Box>
              )}
            />
            <Controller
              name="dateOfBirth"
              control={control}
              rules={{ required: false }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Box className="w-full mt-4 mb-4">
                  <DatePickerComponent
                    onChangeDate={onChange}
                    startDate={value}
                    // startDateDefault={startDate}
                    selectsEnd={true}
                    className="w-full"
                    placeHolder="Date Of Birth"
                    // disabled={startDate ? false : true}
                  />
                </Box>
              )}
            />
            <Controller
              name="order"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextFieldComponent
                  label="Order"
                  value={value}
                  onChangeValue={onChange}
                  type="number"
                  warningText={errors.order && "Field this is required"}
                />
              )}
            />
            <Controller
              name="status"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <>
                  <StatusSelect value={value} onChange={onChange} />
                  {errors.status && (
                    <FormHelperText
                      id="component-error-text"
                      className="text-red-500"
                    >
                      Đây là 1 trường bắt buộc
                    </FormHelperText>
                  )}
                </>
              )}
            />
            <TextFieldComponent
              label="Member photos"
              value={nameImage}
              // onChangeValue={(value: string) => setOrderNewTeam(value)}
              helperText="Vui lòng chỉ updload file dạng png, jpg, gif"
            />
            <Box className="boxUpload">
              <div className="w-full relative" style={{ padding: "0px 10px" }}>
                <div
                  className={`box-border p-2 mb-4`}
                  style={{
                    position: "relative",
                    border: "1px dotted rgba(59, 130, 246, 0.5)",
                  }}
                  {...getRootProps({
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  })}
                >
                  {isDragActive ? (
                    <div>
                      <Typography className="text-drop-file fw-600">
                        DRAG <span className="tractor">&</span> DROP
                      </Typography>
                    </div>
                  ) : (
                    <>
                      <div
                        className="box-content-drop"
                        {...getRootProps({
                          onClick: (e) => {
                            e.stopPropagation();
                          },
                        })}
                      >
                        <label
                          htmlFor="select_file"
                          className="text-f20 text-bold"
                        >
                          <span className="box-blue">Chọn ảnh</span> hoặc kéo &
                          thả nó ở đây
                        </label>
                        <input
                          {...getInputProps()}
                          type="file"
                          name="file"
                          id="select_file"
                          className="inputFile"
                          placeholder="Chọn ảnh"
                          style={{ display: "none" }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              {imageUpload && (
                <div className="w-full relative">
                  <Button
                    style={{ right: 0, top: 0, position: "absolute" }}
                    onClick={() => {
                      setImageUpload("");
                      setNameImage("");
                    }}
                  >
                    <CloseIcon style={{ color: "black" }} />
                  </Button>
                  <img
                    loading="lazy"
                    src={imageUpload}
                    className="rounded"
                    alt="image"
                  />
                </div>
              )}
            </Box>
          </>
        );
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nameAction, imageUpload, errors]);

  const onSubmit = async (data: any) => {
    try {
      if (nameAction === "addTeam") {
        dispatch(changeLoading(true));
        const order = parseInt(data.order);
        const params = {
          ...data,
          order,
        };
        const res = await contentApi.postNewTeam(params).then((res: any) => {
          openMessage({
            variant: `${res.data.STATUS_CODE === 201 ? "success" : "error"}`,
            message: `${
              res.data.STATUS_CODE === 201
                ? "Thêm mới dữ liệu thành công"
                : res.data.MESSAGE_CLIENT
            }`,
          });
          if (res.data.STATUS_CODE === 201) {
            getTeamList(1, "createdAt", "desc");
            handlerResetAll();
          }
        });
        return res;
      } else if (nameAction === "add") {
        dispatch(changeLoading(true));
        const newParam = {
          name: data?.name,
          order: data?.order,
          parentId: data?.parentId.value,
          position: data?.position,
          status: data?.status,
          team: data?.team.value,
          photo: imageFile.size > 0 ? imageFile : null,
          email: data?.email,
          onBoardDate: data?.onBoardDate,
          dateOfBirth: data?.dateOfBirth,
          employeeId: data?.employeeId,
          displayName: data?.displayName,
        };
        const res = await contentApi
          .handlerCreateMember(newParam)
          .then((res: any) => {
            openMessage({
              variant: `${res.data.STATUS_CODE === 201 ? "success" : "error"}`,
              message: `${
                res.data.STATUS_CODE === 201
                  ? "Thêm mới dữ liệu thành công"
                  : res.data.MESSAGE_CLIENT
              }`,
            });
            if (res.data.STATUS_CODE === 201) {
              getMemberPhotos(1, "createdAt", "desc");
              handlerResetAll();
            }
          });
        return res;
      } else if (nameAction === "editTeam") {
        dispatch(changeLoading(true));
        const order = parseInt(data.order);
        const newName = data?.name.trim();
        const params = {
          ...data,
          order,
          name: newName,
        };
        const res = await contentApi
          .handlerEditTeam(params, fieldChoose._id)
          .then((res: any) => {
            openMessage({
              variant: `${res.data.STATUS_CODE === 200 ? "success" : "error"}`,
              message: `${
                res.data.STATUS_CODE === 400
                  ? res.data.MESSAGE_CLIENT
                  : "Sửa dữ liệu thành công"
              }`,
            });
            if (res.data.STATUS_CODE === 200) {
              getTeamList(1, "", "");
              handlerResetAll();
            }
          });
        return res;
      } else if (nameAction === "edit") {
        dispatch(changeLoading(true));
        let newParam;
        if (imageFile) {
          newParam = {
            name: data?.name,
            order: data?.order,
            parentId: data?.parentId.value,
            position: data?.position,
            status: data?.status,
            team: data?.team.value,
            photo: imageFile,
            email: data?.email,
            onBoardDate: data?.onBoardDate,
            dateOfBirth: data?.dateOfBirth,
            employeeId: data?.employeeId,
            displayName: data?.displayName,
          };
        } else {
          newParam = {
            name: data?.name,
            order: data?.order,
            parentId: data?.parentId.value,
            position: data?.position,
            status: data?.status,
            team: data?.team.value,
            email: data?.email,
            onBoardDate: data?.onBoardDate,
            dateOfBirth: data?.dateOfBirth,
            employeeId: data?.employeeId,
            displayName: data?.displayName,
          };
        }
        const res = await contentApi
          .handlerEditMember(newParam, fieldChoose._id)
          .then((res: any) => {
            openMessage({
              variant: `${res.data.STATUS_CODE === 200 ? "success" : "error"}`,
              message: `${
                res.data.STATUS_CODE === 200
                  ? "Sửa dữ liệu thành công"
                  : res.data.MESSAGE_CLIENT
              }`,
            });
            if (res.data.STATUS_CODE === 200) {
              getMemberPhotos(1, "", "");
              handlerResetAll();
            }
          });
        return res;
      }
    } catch (error) {
      dispatch(changeLoading(false));
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const handleChangeRow = (action: string, field: any) => {
    if (action === "deleted") {
      setOpenModalDeleted(true);
      setFieldChoose(field);
    } else if (action === "edit") {
      setFieldChoose(field);
      setOpenModal(true);
      setNameAction("editTeam");
      setValue("name", `${field.name}`);
      setValue("order", `${parseInt(field.order)}`);
      setValue("status", `${field.status}`);
    }
  };

  const onDeletedItem = async (id: string) => {
    try {
      const res = await contentApi.deleteTeam(id).then((res: any) => {
        openMessage({
          variant: `${res.data.STATUS_CODE === 204 ? "success" : "error"}`,
          message: `${
            res.data.STATUS_CODE === 204
              ? "Xóa thành công"
              : res.data.MESSAGE_CLIENT
          }`,
        });
        if (res.data.STATUS_CODE === 204) {
          getTeamList(1, "", "");
          handlerResetAll();
        }
      });
      return res;
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const handlerResetAll = () => {
    dispatch(changeLoading(false));
    reset();
    setFieldChoose(null);
    setOpenModalDeleted(false);
    setOpenModal(false);
    setModalMemberDelete(false); 
    setNameAction("");
  };

  const setValueEditFormEditMember = useCallback(
    (item: any) => {
      const newParentId = listMember.filter((member: any) => {
        if (member.value === item.parentId) {
          return {
            ...member,
          };
        }
      });
      const newTeam = listTeam.filter((team: any) => {
        if (team.value === item.team._id) {
          return {
            ...team,
          };
        }
      });
      setValue("position", item.position.trim());
      setValue("order", parseInt(item.order));
      setValue("status", `${item.status}`);
      setValue("name", item.name);
      setValue("parentId", newParentId[0]);
      setValue("team", item?.team ? newTeam[0] : null);
      setImageUpload(item?.photo.link);
      setNameImage(item?.photo._id);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listMember, listTeam]
  );

  const handlerDeleteMember = async (id: string) => {
    try {
      const res = await contentApi.hanlderDeleteMember(id).then((res: any) => {
        openMessage({
          variant: `${res.data.STATUS_CODE === 204 ? "success" : "error"}`,
          message: `${
            res.data.STATUS_CODE === 204
              ? "Xóa thành công"
              : res.data.MESSAGE_CLIENT
          }`,
        });
        if (res.data.STATUS_CODE === 204) {
          getMemberPhotos(1, "", "");
          handlerResetAll();
        }
      });
      return res;
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  return (
    <>
      <BoxLightBlue />
      <BoxContent>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className="my-4"
        >
          <Typography>Danh sách thành viên DU Leader</Typography>
        </Box>
        <CardTable
          dataHeaderTable={headerTableDuLeader}
          className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white overflow-x-auto"
        />
        <Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className="my-4"
        >
          <Typography>Danh sách Team</Typography>
          <Button
              className="m-button m-button-success"
              type="button"
              onClick={() => {
                setOpenModal(true);
                setNameAction("addTeam");
              }}
          >
            <AddIcon/> Thêm mới
          </Button>
        </Box>
        <CardTable
          dataHeaderTable={headerTableTeams}
          className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white overflow-x-auto"
          dataBodyTable={dataTeamList?.items}
          handleAction={handleChangeRow}
          currentPage={dataTeamList?.currentPage}
        />
        <Pagination
          style={{ justifyContent: "center", display: "flex" }}
          count={dataTeamList?.lastPage}
          onChange={(e, numberPage: number) => {
            setPageNumberTeamList(numberPage);
          }}
          color="primary"
        />
        </Box>
        {/* <Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            className="my-4"
          >
            <Typography>Ảnh thành viên</Typography>
            <Button
                className="m-button m-button-success"
                type="button"
                onClick={() => {
                  setOpenModal(true);
                  setNameAction("add");
                }}
            >
              <AddIcon/> Thêm mới
            </Button>
          </Box>
          <CardTable
            dataHeaderTable={headerTableMember}
            className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white overflow-x-auto"
            dataBodyTable={dataMemberList?.items}
          >
            <>
              {dataMemberList &&
                dataMemberList.items?.map((item: any, index: any) => (
                  <tr key={index}>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                      <Typography
                        className={"ml-3 font-bold text-blueGray-600"}
                      >
                        {dataMemberList.currentPage <= 1
                          ? index + 1
                          : (dataMemberList.currentPage - 1) * 10 + index + 1}
                      </Typography>
                    </th>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                      <Typography>{item?.name}</Typography>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4">
                      <Typography>{item?.position}</Typography>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4">
                      {item.team && <Typography>{item?.team.name}</Typography>}
                    </td>
                    <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">
                      {item.photo ? (
                        <Avatar alt={item?.photo._id} src={item.photo.link} />
                      ) : (
                        <img src={AvaDefault} width={50} height={50} />
                      )}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center w-100">
                      <Box display={"flex"}>
                        <IconButton aria-label="delete" className='m-button-rounded m-button-warning mr-5' onClick={() => {
                          setNameAction("edit");
                          setOpenModal(true);
                          setValueEditFormEditMember(item);
                          setFieldChoose(item);
                        }}>
                          <EditIcon />
                        </IconButton>
                        <IconButton aria-label="delete" className='m-button-rounded m-button-danger' onClick={() => {
                          setModalMemberDelete(true);
                          setFieldChoose(item);
                        }}>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </td>
                  </tr>
                ))}
            </>
          </CardTable>
          <Pagination
            style={{ justifyContent: "center", display: "flex" }}
            count={dataMemberList?.lastPage}
            onChange={(e, numberPage: number) => {
              setPageNumberMemberList(numberPage);
            }}
            color="primary"
          />
        </Box> */}
      </BoxContent>
      <ModalComponent
        classNameContent="bg-white"
        open={isOpenModal}
        handleClose={() => {
          setOpenModal(false);
          setNameAction("");
          reset();
          setNameImage('');
          setImageUpload('');
        }}
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        modalType={nameAction}
      >
        <>
          <Typography>
            {
              nameAction === "addTeam" ? "Thêm mới Team" :
              (nameAction === "editTeam" ? "Cập nhật Team" :
              (nameAction === "add" ? "Thêm mới ảnh thành viên" : "Cập nhật ảnh thành viên"))
            }
          </Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id="content-form-a"
            className="mt-5"
          >
            <Box overflow={"auto"} maxHeight={600} className="p-3">
              {renderModalByNameAction()}
            </Box>
          </form>
        </>
      </ModalComponent>
      <ModalDelete
          classNameContent="bg-white"
          styleComponent={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
          isOpenModal={isOpenModalDelete}
          onCloseModal={() => {
            setOpenModalDeleted(false);
          }}
          onClickConfirm={() => {
            onDeletedItem(fieldChoose._id);
            setOpenModalDeleted(false);
          }}
      />
    </>
  );
}

export default WhoWeAre;
