import React from "react";

interface Props {
  children?: any;
}

function BoxLightBlue(props: Props) {
  const { children } = props;

  return (
    <div className="relative pt-12 md:pt-32" style={{background: 'white'}}>
      {children}
    </div>
  );
}

export default BoxLightBlue;
