import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import BoxContent from "components/common/BoxContent";
import BoxLightBlue from "components/common/BoxLightBule";
import TextFieldComponent from "components/common/Input/TextField";
import EditIcon from "@mui/icons-material/Edit";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import React, {useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import ModalDelete from "components/common/Modal/ModalDelete";
import {changeLoading} from "../../App/Features/Animation";
import {useAppDispatch} from "../../App/Store";
import {AxiosError} from "axios";
import useMessage from "../../Hooks/useMessage";
import {Button} from "@material-ui/core";
import ModalComponent from "../../components/common/Modal";
import RevenueChart from "./RevenueChart";
import BarChartIcon from "@mui/icons-material/BarChart";
import {contentApi} from "../../Datasource/content";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import DateRangeIcon from '@material-ui/icons/DateRange';
import AddBoxIcon from '@mui/icons-material/AddBox';
import formatMoney from "utilities/formatMoney";
import _ from "lodash";

interface Props {}

interface FormSubmit {
  remainingLastMonth: string | number;
  member: string | number;
  company: string | number;
  export: string | number;
}

const inputProps = {
  step: 100000,
  min: 0,
};

const RevenueExpensesV2 = (props: Props) => {
  const {} = props;
  const { openMessage } = useMessage();
  const [editing, setEditing] = useState<boolean>(false);
  const [isOpenLockModal, setIsOpenLockModal] = useState<boolean>(false);
  const [editingId, setEditingId] = useState<string | number>("");
  const [remainingNumber, setRemainingNumber] = useState<number>(0);
  const [memberNumber, setMemberNumber] = useState<number>(0);
  const [companyNumber, setCompanyNumber] = useState<string>("");
  const [exportNumber, setExportNumber] = useState<string>("");
  const [revenueDataList, setRevenueDataList] = useState<any>();
  const [currentItem, setCurrentItem] = useState<any>({item: {}, index: 0});
  const [isOpenChartModal, setOpenChartModal] = useState<boolean>(false);
  const [startDate, setStartDate] = useState(new Date());
  const [addingNewItem, setAddingNewItem] = useState(false);
  const dispatch = useAppDispatch();
  const getRevenueData = async (year: number) => {
    try {
      const { data } = await contentApi.getAllRevenueData(year);
      setRevenueDataList(data);
      dispatch(changeLoading(false));
    } catch (error) {
      dispatch(changeLoading(false));

      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  }

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormSubmit>();

  const handleResetAll = () => {
    reset();
    setEditing(false);
    setIsOpenLockModal(false);
    setEditingId("");
    setRemainingNumber(0);
    setMemberNumber(0);
    setCompanyNumber("");
    setExportNumber("");
    setRevenueDataList([]);
  };

  useEffect(() => {
    getRevenueData(startDate.getFullYear());
    if (new Date().getFullYear() > startDate.getFullYear()) {
      setAddingNewItem(true);
    } else {
      setAddingNewItem(false);
    }
    dispatch(changeLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  const onSubmit = async (data: any) => {
    const params = {
      month: data.month,
      balance: _.isEmpty(data.balance) ? null : Number(data.balance.split(".").join("")),
      companyFund: _.isEmpty(companyNumber) ? null : Number(companyNumber.split(".").join("")),
      totalExpense: _.isEmpty(exportNumber) ? null : Number(exportNumber.split(".").join(""))
    };
    console.log("params", params)
    try {
      dispatch(changeLoading(true));
      if (data.isAddNew) {
        const res = await contentApi
            .handleCreateRevenueTransaction(params)
            .then((res: any) => {
              openMessage({
                variant: `${res.data.STATUS_CODE === 201 ? "success" : "error"}`,
                message: `${
                    res.data.STATUS_CODE === 201
                        ? "Thêm bản ghi thành công"
                        : res.data.MESSAGE_CLIENT
                }`,
              });
              if (res.data.STATUS_CODE === 201) {
                setEditing(false);
                getRevenueData(startDate.getFullYear());
                reset();
              }
            });
        return res;
      } else {
        const res = await contentApi
            .handleUpdateRevenueTransaction(data._id, params)
            .then((res: any) => {
              openMessage({
                variant: `${res.data.STATUS_CODE === 200 ? "success" : "error"}`,
                message: `${
                    res.data.STATUS_CODE === 200
                        ? "Cập nhật thành công"
                        : res.data.MESSAGE_CLIENT
                }`,
              });
              if (res.data.STATUS_CODE === 200) {
                setEditing(false);
                getRevenueData(startDate.getFullYear());
                reset();
              }
            });
        return res;
      }
    } catch (error) {
      dispatch(changeLoading(false));
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }

    /*setEditing(false);
    console.log("revenueDataList", revenueDataList);
    revenueDataList[editingId].companyFund = companyNumber;
    var newExportNumber = Number(remainingNumber) + Number(memberNumber) + Number(companyNumber);
    revenueDataList[editingId].totalRevenue = newExportNumber;
    revenueDataList[editingId].remaining = newExportNumber - revenueDataList[editingId].totalExpense;
    setExportNumber(newExportNumber);
    setRevenueDataList(revenueDataList);
    dispatch(changeLoading(false));
    console.log("data", {
      remainingNumber,
      memberNumber,
      companyNumber,
      exportNumber,
    });*/
  };

  const handleLockMonthlyBudget = async () => {
    try {
      dispatch(changeLoading(true));
      const res = await contentApi
          .handleLockRevenueTransaction(currentItem.item._id)
          .then((res: any) => {
            openMessage({
              variant: `${res.data.STATUS_CODE === 200 ? "success" : "error"}`,
              message: `${
                  res.data.STATUS_CODE === 200
                      ? "Chốt sổ thành công"
                      : res.data.MESSAGE_CLIENT
              }`,
            });
            if (res.data.STATUS_CODE === 200) {
              setIsOpenLockModal(false);
              getRevenueData(startDate.getFullYear());
              reset();
            }
          });
      return res;
    } catch (error) {
      dispatch(changeLoading(false));
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const handleChange = (date: Date) => {
    setIsOpen(!isOpen);
    setStartDate(date);
  };
  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleAddRow = () => {
    var date = new Date(startDate.setMonth(0));
    if (revenueDataList.length > 0) {
      date = new Date(revenueDataList[revenueDataList.length - 1].month);
    }
    if (date.getMonth() < 12) {
      revenueDataList.push({
        month: date.getMonth() === 0 ? date.toISOString() : new Date(date.setMonth(date.getMonth() + 1)).toISOString(),
        balance: revenueDataList.length > 0 ? revenueDataList[revenueDataList.length - 1].remaining : 0,
        membersFund: 0,
        companyFund: 0,
        totalRevenue: 0,
        totalExpense: 0,
        remaining: 0,
        isAddNew: true
      });
      setRevenueDataList([...revenueDataList]);
      setEditing(true);
      setAddingNewItem(true);
      setEditingId(revenueDataList.length - 1);
    } else {
      setAddingNewItem(false);
    }
  };

  const removeItem = (index: number) => {
    revenueDataList.splice(index, 1);
    setRevenueDataList([...revenueDataList]);
    setAddingNewItem(false);
  }

  const formatValue = (value: any, position: string) => {
    if(value === undefined) {
      return;
    }
    let newValue = value.toString().split(".").join("");
    switch (position) {
      case "remainingNumber":
        console.log("remainingNumber", newValue)
        setRemainingNumber(newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
        break;
      case "companyNumber":
        console.log("companyNumber", newValue)
        setCompanyNumber(newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
        break;
      case "exportNumber":
        console.log("exportNumber", newValue)
        setExportNumber(newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
        break;
      case "memberNumber":
        console.log("memberNumber", newValue)
        setMemberNumber(newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
        break;
    }
    return newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  return (
    <>
      <BoxLightBlue />
      <BoxContent>
        <Button className="m-button m-button-warning mr-5" onClick={() => {
          setOpenChartModal(true);
        }}>
          <BarChartIcon/>
          &nbsp;Báo cáo
        </Button>
          <Button
              className="m-button mr-5"
              variant="contained"
              color="primary"
              onClick={(e) => {e.preventDefault(); handleClick();}}>
            <DateRangeIcon/>
            &nbsp;{dayjs(`${startDate}`).format("YYYY")}
          </Button>


          {isOpen && (
              <DatePicker
                  startDate={new Date(2020, 1, 1)}
                  selected={startDate}
                  onChange={(date: Date) => handleChange(date)}
                  minDate={new Date(2020, 1, 1)}
                  maxDate={new Date()}
                  dateFormat="yyyy"
                  yearItemNumber={new Date().getFullYear() - new Date(2020, 1, 1).getFullYear() + 1}
                  showYearPicker
                  selectsStart
                  inline
              />
          )}

      </BoxContent>
      <BoxContent>
        <TableContainer component={Paper}>
          <Table
            aria-label="collapsible table"
            style={{ tableLayout: "fixed" }}
          >
            <TableHead sx={{}}>
              <TableRow sx={{ border: "black 1px solid" }}>
                <TableCell
                  variant="head"
                  align="center"
                  className="stick"
                  size="small"
                  width={100}
                  rowSpan={2}
                  style={{ backgroundColor: "#CFE2F3" }}
                >
                  <div className="font-bold text-lg">Tháng</div>
                </TableCell>
                <TableCell
                  width={300}
                  align="center"
                  className="col-item"
                  colSpan={4}
                  style={{ backgroundColor: "#B6D7A8" }}
                >
                  <div className="font-bold text-base">Tổng thu</div>
                </TableCell>
                <TableCell
                  variant="head"
                  align="center"
                  className="stick"
                  size="small"
                  width={100}
                  rowSpan={2}
                  style={{ backgroundColor: "#B6D7A8" }}
                >
                  <div className="font-bold text-lg">Tổng chi</div>
                </TableCell>
                <TableCell
                  variant="head"
                  align="center"
                  className="stick"
                  size="small"
                  width={100}
                  rowSpan={2}
                  style={{ backgroundColor: "#C9DAF8" }}
                >
                  <div className="font-bold text-lg">Còn lại</div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width={100}
                  align="left"
                  className="col-item"
                  style={{ backgroundColor: "#D9EAD3" }}
                >
                  Quỹ tồn <br />
                  (Tháng trước)
                </TableCell>
                <TableCell
                  width={100}
                  align="left"
                  className="col-item"
                  style={{ backgroundColor: "#D9EAD3" }}
                >
                  Quỹ thành viên
                </TableCell>
                <TableCell
                  width={100}
                  align="left"
                  className="col-item"
                  style={{ backgroundColor: "#D9EAD3" }}
                >
                  Quỹ công ty
                </TableCell>
                <TableCell
                  width={100}
                  align="left"
                  className="col-item"
                  style={{ backgroundColor: "#D9EAD3" }}
                >
                  Tổng cộng
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ border: "black 1px solid" }}>
              {revenueDataList && revenueDataList.map((item: any, index: number) => (
                <TableRow
                  sx={{ "& > *": { borderBottom: "unset" } }}
                  className="table-child"
                >
                  <TableCell>{dayjs(`${item.month}`).format("MM/YYYY")}</TableCell>
                  {editing && editingId === index ? (
                    <>
                      {revenueDataList.length < 2 ? <TableCell>
                        <TextFieldComponent
                            value={remainingNumber}
                            onChangeValue={(value: any) => formatValue(value, "remainingNumber")}
                            type="text"
                            inputProps={inputProps}
                            className=""
                            size="small"
                            margin="0"
                        />
                      </TableCell> : <TableCell>{formatMoney(item.balance)}</TableCell>}
                      <TableCell>{formatMoney(item.membersFund)}</TableCell>
                      <TableCell>
                        <TextFieldComponent
                          value={companyNumber}
                          onChangeValue={(value: any) => formatValue(value, "companyNumber")}
                          type="text"
                          inputProps={inputProps}
                          className=""
                          size="small"
                          margin="0"
                        />
                      </TableCell>
                      <TableCell>{formatMoney(item.totalRevenue)}</TableCell>
                      <TableCell>
                        <TextFieldComponent
                          value={exportNumber}
                          onChangeValue={(value: any) => formatValue(value, "exportNumber")}
                          type="text"
                          inputProps={inputProps}
                          className=""
                          size="small"
                          margin="0"
                        />
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell>{formatMoney(item.balance)}</TableCell>
                      <TableCell>{formatMoney(item.membersFund)}</TableCell>
                      <TableCell>{formatMoney(item.companyFund)}</TableCell>
                      <TableCell>{formatMoney(item.totalRevenue)}</TableCell>
                      <TableCell>{formatMoney(item.totalExpense)}</TableCell>
                    </>
                  )}
                  <TableCell>
                    {editing && editingId === index ? (
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        {formatMoney(item.remaining)}
                        <div className="pl-2">
                          <IconButton size="small" onClick={() => onSubmit(item)}>
                            <CheckBoxIcon color="success" />
                          </IconButton>
                          {item.isAddNew ? <IconButton
                              size="small"
                              onClick={() => removeItem(index)}
                          >
                            <DisabledByDefaultIcon color="error" />
                          </IconButton> : <IconButton
                              size="small"
                              onClick={() => setEditing(false)}
                          >
                            <DisabledByDefaultIcon color="error" />
                          </IconButton>}
                        </div>
                      </Box>
                    ) : (
                      <div className="flex justify-between items-center p-2">
                        {formatMoney(item.remaining)}
                        <div>
                          {item.isLocked ? <EditIcon
                              className="cursor-pointer mr-2"
                              fontSize="small"
                          /> : <EditIcon
                              className="cursor-pointer mr-2"
                              fontSize="small"
                              onClick={() => {
                                setEditing(true);
                                setEditingId(index);
                                setRemainingNumber(formatValue(item.balance, "remainingNumber"));
                                setMemberNumber(formatValue(item.membersFund, "memberNumber"));
                                setCompanyNumber(formatValue(item.companyFund, "companyNumber"));
                                setExportNumber(formatValue(item.totalExpense, "exportNumber"));
                              }}/>}
                          {item.isLocked === true ? (
                            <LockOutlinedIcon className="cursor-pointer" />
                          ) : (
                            <LockOpenIcon
                              className="cursor-pointer"
                              onClick={() => {setIsOpenLockModal(true); setCurrentItem({item, index});}}
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
              {!addingNewItem &&
                  <TableRow>
                    <TableCell>
                      <Button onClick={handleAddRow}>
                        <AddBoxIcon style={{color: "green"}}>add_circle</AddBoxIcon>
                        Thêm bản ghi</Button>
                    </TableCell>
                  </TableRow>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </BoxContent>
      <ModalDelete
        classNameContent="bg-white"
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        title="Bạn chắc chắn muốn chốt sổ tháng này?"
        isOpenModal={isOpenLockModal}
        onCloseModal={() => {
          setIsOpenLockModal(false);
        }}
        onClickConfirm={() => {
          handleLockMonthlyBudget();
        }}
      />
      <ModalComponent
          hideSaveButton={true}
          classNameContent="bg-white"
          open={isOpenChartModal}
          handleClose={() => {
            setOpenChartModal(false);
          }}
          styleComponent={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            maxHeight: "745px",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            overflow: "auto",
          }}
      >
        <RevenueChart
            inputProps = {revenueDataList}
        />
      </ModalComponent>
    </>
  );
};


export default RevenueExpensesV2;
