import React from "react";

interface Props {}

function SupportPage(props: Props) {
  const {} = props;

  return (
    <h1 className="text-3xl font-bold underline">support page in here!</h1>
  );
}

export default SupportPage;
