import { Box, Button, Modal } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import CustomCloseButton from "./CustomCloseButton";

interface Props {
  open: boolean;
  handleClose: () => void;
  styleComponent?: any;
  children?: JSX.Element;
  classNameContent?: string;
  modalType?: any;
  innerStack?: any;
  hideSaveButton?: boolean;
  setActionDetailTechStack?: any;
  isHasPreview?: boolean;
  handleShowPreview?: () => void;
}

function ModalComponent(props: Props) {
  const {
    open,
    handleClose,
    styleComponent,
    children,
    classNameContent,
    modalType = "add",
    innerStack = "close",
    setActionDetailTechStack,
    hideSaveButton = "false",
    isHasPreview,
    handleShowPreview
  } = props;

  const handleOpenInnerStack = () => {
    setActionDetailTechStack(true);
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleComponent} className={classNameContent}>
        <CustomCloseButton onClick={handleClose} />
        <Box display={"flex"} flexDirection={"column"}>
          {children}
          <div className="m-dialog-footer">
            {isHasPreview && <Button
              className="m-button m-button-primary text-white text-bold mr-10"
              onClick={handleShowPreview}
            >
              Xem trước
            </Button>}

            {innerStack === "open" ? (
              <Button
                className={
                  "m-button text-white text-bold mr-10 " +
                  (hideSaveButton === true ? "hidden-button " : "") +
                  (modalType.includes("add")
                    ? "m-button-success"
                    : "m-button-warning")
                }
                onClick={handleOpenInnerStack}
                form="content-form-a"
              >
                {modalType.includes("add") ? "Thêm mới" : "Cập nhật"}
              </Button>
            ) : (
              <Button
                className={
                  "m-button text-white text-bold mr-10 " +
                  (hideSaveButton === true ? "hidden-button " : "") +
                  (modalType.includes("add")
                    ? "m-button-success"
                    : "m-button-warning")
                }
                type="submit"
                form="content-form-a"
              >
                {modalType.includes("add") ? "Thêm mới" : "Cập nhật"}
              </Button>
            )}

            <Button
              className="m-button m-button-danger text-white text-bold ml-atuo"
              onClick={handleClose}
            >
              Hủy bỏ
            </Button>
          </div>
        </Box>
      </Box>
    </Modal>
  );
}

export default ModalComponent;
