import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

interface Props {
  value: string;
  onChange: (e: SelectChangeEvent) => void;
}

function TemplateVariableSelect(props: Props) {
  const { value, onChange } = props;

  return (
    <>
      <FormControl className="w-full" style={{ marginBottom: 30 }}>
        <InputLabel id="demo-simple-select-helper-label" className="w-full">
          Giá trị
        </InputLabel>
        <Select
          {...props}
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={value}
          label="Giá trị"
          onChange={onChange}
          size="small"
        >
          <MenuItem value={"name"}>Name</MenuItem>
          <br/>
          <MenuItem value={"date"}>Date</MenuItem>
          <br/>
          <MenuItem value={"month"}>Month</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}

export default TemplateVariableSelect;
