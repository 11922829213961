/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import dashboardAxios from "Api/Interceptors";
import _ from "lodash";

export const postApi = {
  getAllPost: (params: any) => {
    const { page, title, publishedDateFrom, publishedDateTo, status } = params;
    return dashboardAxios.get(
      `/recruitments/all?page=${page ? page : 1}&pageSize=10&title=${
        title ? title : ""
      }&publishedDateFrom=${
        publishedDateFrom ? publishedDateFrom : ""
      }&publishedDateTo=${publishedDateTo ? publishedDateTo : ""}&status=${
        status ? status : ""
      }&orderBy=${!_.isEmpty(params.orderBy) ? params.orderBy: 'desc'}&sortBy=${!_.isEmpty(params.sortBy) ? params.sortBy : '_id'}`
    );
  },
  changeStatusPost: (params: any) => {
    const { id } = params;
    return dashboardAxios.patch(`/recruitments/${id}`, params);
  },
  restorePost: (id: string) => dashboardAxios.post(`/recruitments/restore/${id}`),
  deletePost: (id: string) => dashboardAxios.delete(`/recruitments/${id}`),
  createPost: (param: any) => dashboardAxios.post("/recruitments", param),
  editPost: (params: any, _id: string) => dashboardAxios.patch(`/recruitments/${_id}`, params)
};
