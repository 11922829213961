import App from "App";
import { useAppSelector } from "App/Store";
import Layout from "components/Layouts/Layout";
import LoginPage from "pages/Auth/Login";
import Register from "pages/Auth/Register";
import BudgetManagementPage from "pages/Budget/BudgetManagement";
import Categories from "pages/Budget/Categories";
import MonthlyBudgetPage from "pages/Budget/MonthlyBudget";
import Clients from "pages/Contents/Clients";
import HomePage from "pages/Contents/Homepage";
import OurStory from "pages/Contents/OurStory";
import Projects from "pages/Contents/Projects";
import RolesPage from "pages/Contents/Roles";
import TechnicalCapability from "pages/Contents/TechnicalCapability";
import WhoWeAre from "pages/Contents/WhoWeAre";
import DraftNews from "pages/News/DraftNews";
import PublicedNews from "pages/News/PublicedNews";
import Page404 from "pages/NotFound/Page404";
import DetailFolder from "pages/Photos/DetailFolder";
import FoldersManagement from "pages/Photos/FoldersManagement";
import PlanRevenueExpenses from "pages/PlanRevenueExpenses";
import PublishPost from "pages/Recruitment/PublishPost";
import RevenueExpenses from "pages/RevenueExpenses";
import RevenueExpensesV2 from "pages/RevenueExpensesV2";
import Submission from "pages/Sharing/Submission";
import SupportPage from "pages/support";
import TemplateManagement from "pages/Template";
import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import UserPage from "TS/App/Users";
import ImageTemplateManagement from "./pages/ImageTemplate";

interface PrivateRouteProps {
    component: any;
    authed: boolean;
    [key: string]: any;
}

const PrivateRoute = ({
    component: Component,
    authed,
    ...rest
}: PrivateRouteProps) => {
    return (
        <Route
            {...rest}
            render={() => (authed ? <Component /> : <Redirect to="/login" />)}
        />
    );
};

function Routing(): JSX.Element {
    const {
        auth: { accessToken },
    } = useAppSelector((state) => {
        return state;
    });

    // const accessToken = true;
    return (
        <Switch>
            <Route exact strict path="/login" component={LoginPage} />
            <Route exact strict path="/register" component={Register} />
            {/* <Route exact strict path="/forgot-password" component={ForgotPassword} />
      <Route exact strict path="/reset-password/:code" component={ResetPassword} /> */}
            <Layout>
                <Switch>
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/"
                        component={App}
                    />
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/users"
                        component={UserPage}
                    />
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/roles"
                        component={RolesPage}
                    />
                    {/* <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/home-page"
                        component={HomePage}
                    /> */}
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/who-we-are"
                        component={WhoWeAre}
                    />
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/our-story"
                        component={OurStory}
                    />
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/support"
                        component={SupportPage}
                    />
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/projects"
                        component={Projects}
                    />
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/clients"
                        component={Clients}
                    />
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/technical-capability"
                        component={TechnicalCapability}
                    />
                    {/* News */}
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/published-news"
                        component={PublicedNews}
                    />
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/draft-news"
                        component={DraftNews}
                    />
                    {/* Photos */}
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/folders-management"
                        component={FoldersManagement}
                    />
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/folders-management/:id"
                        component={DetailFolder}
                    />
                    {/* RECRUITMENT */}
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/publish-post"
                        component={PublishPost}
                    />
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/submission"
                        component={Submission}
                    />
                    {/* TODO: Thu chi */}
                  <PrivateRoute
                      authed={!!accessToken}
                      exact
                      strict
                      path="/thu-chi"
                      // component={RevenueExpenses}
                      component={RevenueExpensesV2}
                  />
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/plan-revenue-expenses"
                        component={PlanRevenueExpenses}
                    />
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/budget-management"
                        component={BudgetManagementPage}
                    />
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/budget-categories"
                        component={Categories}
                    />
                    <PrivateRoute
                        authed={!!accessToken}
                        exact
                        strict
                        path="/monthly-budget"
                        component={MonthlyBudgetPage}
                    />
                    <PrivateRoute
                        authed={!!accessToken}
                        exacct
                        strict
                        path="/template-management"
                        component={TemplateManagement}
                    />
                    <PrivateRoute
                        authed={!!accessToken}
                        exacct
                        strict
                        path="/image-template-management"
                        component={ImageTemplateManagement}
                    />
                </Switch>
            </Layout>
            <Route component={Page404} />
        </Switch>
    );
}

export default Routing;
