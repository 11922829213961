/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import dashboardAxios from "Api/Interceptors";

export const imageTemplateApi = {
  getAllTemplates: (params: any) => {
    const { page, type } = params;
    return dashboardAxios.get(
      `/image-templates/paginate?page=${page ? page : 1}&pageSize=10&type=${
        type ? type : ""}`
    );
  },
  createTemplate: (params: any) => {
    const response = dashboardAxios.postFormData("image-templates/new", params);
    return response;
  },
  editTemplate: (params: any, id: string) => {
    const response = dashboardAxios.patchFormData(
      `image-templates/${id}`,
      params
    );
    return response;
  },
  deleteTemplate: (id: string) =>
    dashboardAxios.delete(`/image-templates/${id}`),
};
