import React, { useEffect, useMemo, useState } from "react";
import ReactECharts from "echarts-for-react";
import { Box } from "@mui/system";
import { contentApi } from "Datasource/content";
import { useAppDispatch } from "App/Store";
import { changeLoading } from "App/Features/Animation";
import useMessage from "Hooks/useMessage";
import formatMoney from "utilities/formatMoney";
import { AxiosError } from "axios";
import dayjs from "dayjs";
import SelectComponent from "components/common/Select/defeault";
import {Typography} from "@material-ui/core";

interface BudgetChartsProps {}

const chartColors = [
  "#4285F4",
  "#EA4335",
  "#FBBC04",
  "#ee6666",
  "#73c0de",
  "#3ba272",
  "#fc8452",
  "#9a60b4",
  "#ea7ccc",
];

const listOfYear = Array.from(
  { length: dayjs().year() - 2019 },
  (value: any, index: any) => {
    return {
      label: dayjs().year() - index,
      value: dayjs().year() - index,
    };
  }
);

const BudgetCharts = (props: BudgetChartsProps) => {
  const [monthChartResponseData, setMonthChartResponseData] = useState<any>();
  const [yearChartResponseData, setYearChartResponseData] = useState<any>();
  const [monthChartData, setMonthChartData] = useState<any>({
    remainingChartData: {},
    categoryChartData: [],
  });
  const [yearChartData, setYearChartData] = useState<any>({
    remainingChartData: {},
    categoryChartData: [],
  });
  const [selectedMonth, setSelectedMonth] = useState<number>();
  const [selectedYear, setSelectedYear] = useState<number>(dayjs().year());

  const listOfMonth = useMemo(() => {
    return Array.from(
      { length: selectedYear === dayjs().year() ? dayjs().month() + 1 : 12 },
      (value: any, index: any) => {
        return { label: `Tháng ${index + 1}`, value: index + 1 };
      }
    );
  }, [selectedYear]);

  const { openMessage } = useMessage();
  const dispatch = useAppDispatch();

  const getYearChartData = async (year: number) => {
    try {
      const { data } = await contentApi.getYearChartData(year);

      setYearChartResponseData(data);
      const remapChartData: any[] = [];

      Object.keys(data).map((categoryName: any) => {
        if (categoryName !== "remaining") {
          const categoryList = data[categoryName].map((category: any) => {
            return `${category.category?.name}`;
          });

          remapChartData.push({
            pieChartData: {
              title: {
                text: `Khoản ${categoryName.toLowerCase()} thực tế`,
                left: "left",
                top: "top",
              },
              legend: {
                orient: "horizontal",
                bottom: "bottom",
                data: categoryList,
              },
              series: [
                {
                  type: "pie",
                  itemStyle: {
                    normal: {
                      label: {
                        show: true,
                        position: "inner",
                        formatter: function (params: any) {
                          return formatMoney(params.value / 1000);
                        },
                      },
                    },
                  },
                  color: chartColors,
                  data: data[categoryName].map((category: any) => ({
                    name: category.category?.name,
                    value: category.totalActualCategory,
                  })),
                },
              ],
            },
            barChartData: {
              title: {
                text: `Các khoản ${categoryName.toLowerCase()}`,
                left: "left",
                top: "top",
              },
              color: chartColors,
              legend: {
                orient: "horizontal",
                top: "bottom",
              },
              dataset: {
                source: [
                  ["product", "Kế hoạch", "Thực tế"],
                  ...data[categoryName].map((category: any) => [
                    category.category?.name,
                    category.totalPlanCategory / 1000,
                    category.totalActualCategory / 1000,
                  ]),
                ],
              },
              xAxis: { type: "category" },
              yAxis: {},
              series: [
                {
                  type: "bar",
                  label: {
                    normal: {
                      show: true,
                      position: "top",
                    },
                  },
                },
                {
                  type: "bar",
                  label: {
                    normal: {
                      show: true,
                      position: "top",
                    },
                  },
                },
              ],
            },
          });
        }
      });

      const remapRemainingYearChartData = {
        title: {
          text: `Tồn quỹ`,
          left: "left",
          top: "top",
        },
        color: chartColors,
        legend: {
          show: true,
          orient: "horizontal",
          top: "bottom",
        },
        xAxis: {
          type: "category",
          data: data?.remaining.map(
            (month: any) => `Tháng ${month.startingMonth.split("-")[1]}`
          ).sort(),
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: 'Thực tế còn lại',
            data: data?.remaining.map(
              (month: any) => month.actualRemaining / 1000
            ),
            label: {
              normal: {
                show: true,
                position: "top",
              },
            },
            type: "line",
          },
          {
            name: "Tồn quỹ hiện tại",
            data: data?.remaining.map(
              (month: any) => month.actualNetValue / 1000
            ),
            label: {
              normal: {
                show: true,
                position: "top",
              },
            },
            type: "line",
          },
        ],
      };
      setYearChartData({
        remainingChartData: remapRemainingYearChartData,
        categoryChartData: remapChartData,
      });

      dispatch(changeLoading(false));
    } catch (error) {
      dispatch(changeLoading(false));

      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const getMonthChartData = async (year: number, month: number | undefined) => {
    if (month) {
      try {
        const { data } = await contentApi.getMonthChartData(year, month);
        setMonthChartResponseData(data);
        const remapChartData: any[] = [];

        const remapRemainingChartData = {
          title: {
            text: `Tồn quỹ`,
            left: "left",
            top: "top",
          },
          color: chartColors,
          legend: {
            orient: "horizontal",
            top: "bottom",
          },
          dataset: {
            source: [
              ["product", "Plan", "Actual"],
              [
                "Remaining",
                data?.planRemaining / 1000,
                data?.planNetValue / 1000,
              ],
              [
                "Net Value",
                data?.actualRemaining / 1000,
                data?.actualNetValue / 1000,
              ],
            ],
          },
          xAxis: { type: "category" },
          yAxis: {},
          series: [
            {
              type: "bar",
              label: {
                normal: {
                  show: true,
                  position: "top",
                },
              },
            },
            {
              type: "bar",
              label: {
                normal: {
                  show: true,
                  position: "top",
                },
              },
            },
          ],
        };

        if (selectedMonth) {
          data.details.map((dataByMonth: any) => {
            const categoryList = dataByMonth.transactions.map(
              (category: any) => {
                return `${category.category?.name}`;
              }
            );

            remapChartData.push({
              pieChartData: {
                title: {
                  text: `Khoản ${dataByMonth.type.toLowerCase()} thực tế`,
                  left: "left",
                  top: "top",
                },
                legend: {
                  orient: "horizontal",
                  bottom: "bottom",
                  data: categoryList,
                },
                series: [
                  {
                    type: "pie",
                    itemStyle: {
                      normal: {
                        label: {
                          show: true,
                          position: "inner",
                          formatter: function (params: any) {
                            return formatMoney(params.value / 1000);
                          },
                        },
                      },
                    },
                    color: chartColors,
                    data: dataByMonth.transactions.map((category: any) => ({
                      name: category.category?.name,
                      value: category.totalActualCategory,
                    })),
                  },
                ],
              },
              barChartData: {
                title: {
                  text: `Các khoản ${dataByMonth.type.toLowerCase()}`,
                  left: "left",
                  top: "top",
                },
                color: chartColors,
                legend: {
                  orient: "horizontal",
                  top: "bottom",
                },
                dataset: {
                  source: [
                    ["product", "Planned", "Actual", "Plan next month"],
                    ...dataByMonth.transactions.map((category: any) => [
                      category.category?.name,
                      category.totalPlanCategory / 1000,
                      category.totalActualCategory / 1000,
                      category.planNextMonth / 1000,
                    ]),
                  ],
                },
                xAxis: { type: "category" },
                yAxis: {},
                series: [
                  {
                    type: "bar",
                    label: {
                      normal: {
                        show: true,
                        position: "top",
                      },
                    },
                  },
                  {
                    type: "bar",
                    label: {
                      normal: {
                        show: true,
                        position: "top",
                      },
                    },
                  },
                  {
                    type: "bar",
                    label: {
                      normal: {
                        show: true,
                        position: "top",
                      },
                    },
                  },
                ],
              },
            });
          });
        }
        setMonthChartData({
          remainingChartData: remapRemainingChartData,
          categoryChartData: remapChartData,
        });
        dispatch(changeLoading(false));
      } catch (error) {
        dispatch(changeLoading(false));

        if (error instanceof Error) {
          const { response } = error as AxiosError;
          const { ...errorObject }: any = response;
          openMessage({
            variant: "error",
            message: errorObject?.data.message,
          });
        }
      }
    }
  };

  useEffect(() => {
    dispatch(changeLoading(true));
    getYearChartData(selectedYear);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear]);

  useEffect(() => {
    dispatch(changeLoading(true));
    getMonthChartData(selectedYear, selectedMonth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedYear, selectedMonth]);

  return (
    <Box display="flex" flexDirection="column">
      <Typography>
        {"Báo cáo thu chi"}
      </Typography>
      <Box display="flex" flexDirection="row" sx={{marginTop: 2}}>
        <div className="w-1/2 pr-3">
          <SelectComponent
            value={listOfYear.filter((option) => {
              return option.value === selectedYear;
            })}
            onChange={(value: any) => setSelectedYear(value.value)}
            data={listOfYear}
            className="mb-4"
            placeholder="Năm"
            customStyle={{ height: "40px" }}
            isSearchable={false}
            isClearable={false}
          />
        </div>
        <div className="w-1/2 pl-3">
          <SelectComponent
            value={listOfMonth.filter((option) => {
              return option.value === selectedMonth;
            })}
            onChange={(value: any) => setSelectedMonth(value.value)}
            data={listOfMonth}
            className="mb-4"
            placeholder="Tháng"
            customStyle={{ height: "40px" }}
            isSearchable={false}
          />
        </div>
      </Box>
      <Box display="flex" flexDirection="row">
        {selectedMonth
          ? monthChartResponseData &&
            monthChartData?.categoryChartData.map((year: any) => (
              <Box display="flex" flexDirection="column" width={"50%"}>
                <ReactECharts
                  option={year.pieChartData}
                  style={{ width: "100%", marginBottom: "20px" }}
                />
                <ReactECharts
                  option={year.barChartData}
                  style={{ width: "100%", marginBottom: "20px" }}
                />
              </Box>
            ))
          : yearChartResponseData?.Thu?.length > 0 &&
            yearChartData?.categoryChartData.map((month: any) => (
              <Box display="flex" flexDirection="column" width={"50%"}>
                <ReactECharts
                  option={month.pieChartData}
                  style={{ width: "100%", marginBottom: "20px" }}
                />
                <ReactECharts
                  option={month.barChartData}
                  style={{ width: "100%", marginBottom: "20px" }}
                />
              </Box>
            ))}
      </Box>
      {selectedMonth && yearChartResponseData?.Thu?.length > 0 && (
        <ReactECharts
          option={monthChartData.remainingChartData!}
          style={{ width: "50%", marginBottom: "20px" }}
        />
      )}
      {!selectedMonth && yearChartResponseData?.Thu?.length > 0 && (
        <Box className="mb-5">
          <ReactECharts
            option={yearChartData.remainingChartData!}
            style={{ width: "70%", marginBottom: "20px" }}
          />
        </Box>
      )}
      {(selectedMonth && yearChartResponseData?.Thu?.length === 0) ||
        (!selectedMonth && yearChartResponseData?.Thu?.length === 0 && (
          <Box
            width="100%"
            height="100px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            lineHeight="100%"
          >
            Không có dữ liệu
          </Box>
        ))}
    </Box>
  );
};

export default BudgetCharts;
