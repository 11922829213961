import { addMonths } from "date-fns";
import React from "react";
import DatePicker from "react-datepicker";

interface Props {
  startDate?: Date;
  minDate?: Date;
  onChangeDate: (date: Date) => void;
  placeHolder?: string;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  endDate?: Date;
  className?: string;
  startDateDefault?: Date;
  disabled?: boolean;
}

function DatePickerComponent(props: Props) {
  const {
    startDate,
    minDate,
    onChangeDate,
    placeHolder,
    selectsStart = false,
    selectsEnd = false,
    endDate,
    className,
    startDateDefault,
    disabled = false,
  } = props;

  return (
    <>
      <DatePicker
        locale="vi"
        selected={startDate}
        onChange={(date: any) => onChangeDate(date)}
        minDate={minDate}
        maxDate={addMonths(new Date(), 5)}
        showDisabledMonthNavigation
        placeholderText={placeHolder ? placeHolder : "Vui lòng chọn ngày"}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        startDate={startDate}
        endDate={endDate ? endDate : undefined}
        className={className}
        disabled={disabled}
        dateFormat="dd/MM/yyyy"
      />
    </>
  );
}
export default DatePickerComponent;
