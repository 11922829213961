/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  Pagination,
  Tooltip,
  Typography
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Box } from "@mui/system";
import BoxContent from "components/common/BoxContent";
import BoxLightBlue from "components/common/BoxLightBule";
import TextFieldComponent from "components/common/Input/TextField";
import CardTable from "components/common/ListTable/CardTable";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import ModalComponent from "components/common/Modal";
import { headerTablePublicNewList } from "Datasource/dataCommonTable";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useMessage from "Hooks/useMessage";
import { AxiosError } from "axios";
import { useAppDispatch } from "App/Store";
import { changeLoading } from "App/Features/Animation";
import { newsApi } from "Datasource/news";
import AvaDefault from "common/assets/images/1053244.png";
import dayjs from "dayjs";
import DatePickerComponent from "components/common/DatePicker";
import WYSIWYGEditor from "components/common/Edittor";
import { useDropzone } from "react-dropzone";
import { format } from "date-fns";
import StatusSelect from "components/common/Select/status";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ModalDelete from "components/common/Modal/ModalDelete";
import ModalPreview from "components/common/Modal/ModalPreview";
import { getValue } from "@testing-library/user-event/dist/utils";
interface Props {}

function PublicedNews(props: Props) {
  const {} = props;
  const { openMessage } = useMessage();
  const dispatch = useAppDispatch();
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [isOpenModalNewDelete, setModalNewDelete] = useState<boolean>(false);
  const [isOpenModalPreview, setIsOpenModalPreview] = useState<boolean>(false);
  const [listNew, setListNew] = useState<any>();
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [startDate, setStartDate] = useState<Date>();
  const [mainPhoto, setMainPhoto] = useState<any>();
  const [urlMainImage, setUrlMainImage] = useState<string>("");
  const [smallPhoto, setSmallPhoto] = useState<any>();
  const [urlSmallImage, setUrlSmallImage] = useState<string>("");
  const [nameAction, setNameAction] = useState<string>("add");
  const [fieldChoose, setFieldChoose] = useState<any>();
  const [previewItem, setPreviewItem] = useState<any>();
  useEffect(() => {
    dispatch(changeLoading(true));
    const params = {
      page: currentPage,
      sortBy: "status",
      orderBy: "desc"
    };
    getListNews(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const {
    register,
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors }
  } = useForm<any>();

  const {
    register: register2,
    setValue: setValue2,
    getValues: getValues,
    control: control2,
    reset: reset2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2
  } = useForm<any>();

  const onSubmit = async (data: any) => {
    try {
      dispatch(changeLoading(true));
      const params = {
        title: data.title && data?.title.trim(),
        datePickerFrom: data?.datePickerFrom,
        datePickerTo: data?.datePickerTo,
        page: currentPage,
        sortBy: "status",
        orderBy: "desc"
      };
      const {
        data: { META_DATA }
      } = await newsApi.getAllNew(params);
      setListNew(META_DATA);
      setMainPhoto(null);
      dispatch(changeLoading(false));
    } catch (error) {
      dispatch(changeLoading(false));
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message
        });
      }
    }
  };
  const setValueEditFormEditNew = (item: any) => {
    setValue2("title", item.title);
    setValue2("status", item.status);
    setValue2("homeDisplay", item.homeDisplay);
    setValue2("content", item.content);
    setValue2("id", item._id);
    setValue2("type", item.type);
    setUrlMainImage(item?.mainImg.link);
    setUrlSmallImage(item?.img.link);
  };

  const getListNews = async (params: any) => {
    try {
      const {
        data: { META_DATA }
      } = await newsApi.getAllNew(params);
      setListNew(META_DATA);
      dispatch(changeLoading(false));
    } catch (error) {
      dispatch(changeLoading(false));
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message
        });
      }
    }
  };

  // const onSubmitFormAdd = async (data: any) => {
  //     try {
  //         const newParams = {
  //             title: data?.title.trim(),
  //             content: data?.content.trim(),
  //             mainImage: mainPhoto ? mainPhoto : null,
  //             img: smallPhoto ? smallPhoto : null,
  //             publishedDate: format(new Date(), "yyyy/MM/dd"),
  //             homeDisplay: data?.homeDisplay ? data?.homeDisplay : false,
  //             status: data?.status,
  //         };

  //         const res = await newsApi.createNew(newParams).then((res: any) => {
  //             handlerResetAll();
  //             getListNews("");
  //             openMessage({
  //                 variant: `${
  //                     res.data.STATUS_CODE === 201 ? "success" : "error"
  //                 }`,
  //                 message: `${
  //                     res.data.STATUS_CODE === 201
  //                         ? "Thêm mới thành công"
  //                         : res.data.MESSAGE_CLIENT
  //                 }`,
  //             });
  //             return;
  //         });
  //     } catch (error) {
  //         if (error instanceof Error) {
  //             const { response } = error as AxiosError;
  //             const { ...errorObject }: any = response;
  //             openMessage({
  //                 variant: "error",
  //                 message: errorObject?.data.message,
  //             });
  //         }
  //     }
  // };
  const onSubmitFormAdd = async (data: any) => {
    const params = {
      page: currentPage,
      sortBy: "status",
      orderBy: "desc"
    };
    try {
      const newParams = {
        title: data?.title.trim(),
        content: data?.content.trim(),
        mainImage: mainPhoto ? mainPhoto : null,
        img: smallPhoto ? smallPhoto : null,
        publishedDate: format(new Date(), "yyyy/MM/dd"),
        homeDisplay: data?.homeDisplay ? data?.homeDisplay : false,
        status: data?.status ? data?.status : "published",
        type: data?.type
      };
      if (nameAction === "editNew") {
        console.log(newParams);
        const res = await newsApi
          .editNew(newParams, data.id)
          .then((res: any) => {
            handlerResetAll();
            getListNews(params);
            openMessage({
              variant: `${res.data.STATUS_CODE === 204 ? "success" : "error"}`,
              message: `${
                res.data.STATUS_CODE === 204
                  ? "Cập nhật thành công"
                  : res.data.MESSAGE_CLIENT
              }`
            });
          });
        return res;
      } else {
        const res = await newsApi.createNew(newParams).then(() => {
          handlerResetAll();
          getListNews(params);
          openMessage({
            variant: "success",
            message: "Thêm mới thành công"
          });
        });
        return;
      }
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message
        });
      }
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (fs: File[]) => {
      setMainPhoto(fs[0]);
      // hanlderChangeFile(fs[0]);
      setUrlMainImage(URL.createObjectURL(fs[0]));
    }
  });
  const {
    getRootProps: getRootProps2,
    getInputProps: getInputProps2,
    isDragActive: isDragActive2
  } = useDropzone({
    onDrop: (fs: File[]) => {
      setSmallPhoto(fs[0]);
      // setUrlMainImage
      // hanlderChangeFile(fs[0]);
      setUrlSmallImage(URL.createObjectURL(fs[0]));
    }
  });

  const handlerResetAll = () => {
    reset();
    reset2();
    setMainPhoto("");
    setSmallPhoto("");
    setOpenModal(false);
  };

  const onChangeStatusPublish = (id: string) => {
    try {
      const newValue = {
        id,
        value: "draft"
      };
      const params = {
        page: currentPage,
        sortBy: "status",
        orderBy: "desc"
      };
      newsApi.changeStatusNews(newValue).then((res: any) => {
        getListNews(params);
        dispatch(changeLoading(false));
        openMessage({
          variant: `${res.data.STATUS_CODE === 204 ? "success" : "error"}`,
          message: `${
            res.data.STATUS_CODE === 204
              ? "Thay đổi trạng thái tin thành công"
              : res.data.MESSAGE_CLIENT
          }`
        });
      });
    } catch (error) {
      dispatch(changeLoading(false));
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message
        });
      }
    }
  };
  const handlerDeleteNew = async (id: string) => {
    const params = {
      page: currentPage,
      status: "archived",
      sortBy: "status",
      orderBy: "desc"
    };
    try {
      const res = await newsApi.deleteNew(id).then((res: any) => {
        openMessage({
          variant: `${res.data.STATUS_CODE === 200 ? "success" : "error"}`,
          message: `${
            res.data.STATUS_CODE === 200
              ? "Xóa thành công"
              : res.data.MESSAGE_CLIENT
          }`
        });
        if (res.data.STATUS_CODE === 200) {
          getListNews(params);
        }
        window.location.reload();
      });
      return res;
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message
        });
      }
    }
  };
  const handleShowPreview = () => {
    if (nameAction === "add") {
      setPreviewItem({...getValues(), mainImg: {link: urlMainImage}, img: {link: urlSmallImage}});
    }
    setIsOpenModalPreview(!isOpenModalPreview);
  };

  return (
    <>
      <BoxLightBlue />
      <BoxContent>
        <Box className="box-search">
          <Accordion>
            <AccordionSummary
              className="filter-div"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-bold">Bộ lọc</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <form
                onSubmit={handleSubmit(onSubmit)}
                id="content-form"
                className="mt-5"
              >
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextFieldComponent
                      className="w-full bg-white"
                      label="Tiêu đề"
                      value={value}
                      onChangeValue={onChange}
                      warningText={errors.name && "Đây là 1 trường bắt buộc"}
                    />
                  )}
                />
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography>Ngày phát hành</Typography>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    marginTop={1}
                  >
                    <Controller
                      name="datePickerFrom"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Box className="w-full mr-5">
                          <Typography className="text-blueGray-400">
                            Từ
                          </Typography>
                          <DatePickerComponent
                            onChangeDate={(value) => {
                              onChange(value);
                              setStartDate(value);
                            }}
                            startDate={value}
                            selectsStart={true}
                            className="w-full m-date-picker"
                          />
                        </Box>
                      )}
                    />
                    <Controller
                      name="datePickerTo"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Box className="w-full ml-5">
                          <Typography className="text-blueGray-400">
                            Đến
                          </Typography>
                          <DatePickerComponent
                            onChangeDate={onChange}
                            startDate={value}
                            startDateDefault={startDate}
                            selectsEnd={true}
                            className="w-full m-date-picker"
                            disabled={startDate ? false : true}
                          />
                        </Box>
                      )}
                    />
                  </Box>
                </Box>
                <Box className="w-full" display={"flex"}>
                  <Button
                    className="m-button m-button-warning m-button-middle-div"
                    type="submit"
                  >
                    <SearchIcon />
                    Tìm kiếm
                  </Button>
                </Box>
              </form>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className="box-content mt-5">
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            className="my-4"
          >
            <Typography>Danh sách tin tức</Typography>
            <Button
              className="m-button m-button-success"
              type="button"
              onClick={() => {
                setOpenModal(true);
                setNameAction("add");
              }}
            >
              <AddIcon /> Thêm mới
            </Button>
          </Box>
          <CardTable
            dataHeaderTable={headerTablePublicNewList}
            className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white overflow-x-auto"
            dataBodyTable={listNew?.items}
          >
            <>
              {listNew &&
                listNew.items?.map((item: any, index: any) => (
                  <>
                    {item.status !== "archived" && (
                      <tr key={index}>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                          <Typography
                            className={"ml-3 font-bold text-blueGray-600"}
                          >
                            {listNew.currentPage <= 1
                              ? index + 1
                              : (listNew.currentPage - 1) * 10 + index + 1}
                          </Typography>
                        </th>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                          <Typography>{item?.title}</Typography>
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                          <Typography>
                            {item?.type === "news" ? (
                              <Chip
                                label={"Tin tức"}
                                color="primary"
                                variant="outlined"
                              />
                            ) : (
                              <Chip
                                label={"Chia sẻ"}
                                color="secondary"
                                variant="outlined"
                              />
                            )}
                          </Typography>
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4 text-center">
                          {item?.status === "published" ? (
                            <Chip
                              label={"Đã xuất bản"}
                              color="primary"
                              variant="outlined"
                            />
                          ) : item?.status === "draft" ? (
                            <Chip
                              label={"Bản nháp"}
                              color="secondary"
                              variant="outlined"
                            />
                          ) : (
                            <Chip
                              label={"Đã lưu trữ"}
                              color="warning"
                              variant="outlined"
                            />
                          )}
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4 text-center">
                          <Typography>
                            {dayjs(`${item?.publishedDate}`).format(
                              "DD-MM-YYYY"
                            )}
                          </Typography>
                        </td>

                        <td className="border-t-0 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap">
                          <>
                            {item.img ? (
                              <img
                                src={item.img.link}
                                alt={item?.img._id}
                                style={{
                                  maxHeight: 50,
                                  width: "60px",
                                  padding: 2
                                }}
                                className="m-auto"
                              />
                            ) : (
                              <img
                                src={AvaDefault}
                                width={50}
                                height={50}
                                alt="default image"
                                className="m-auto"
                              />
                            )}
                          </>
                        </td>
                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                          <Box display={"flex"} justifyContent={"center"}>
                            {item.status !== "published" && (
                              <Tooltip
                                title={"Edit"}
                                arrow
                                placement={"top-end"}
                              >
                                <IconButton
                                  aria-label="delete"
                                  className="m-button-rounded m-button-warning mr-5"
                                  onClick={() => {
                                    setNameAction("editNew");
                                    setOpenModal(true);
                                    setValueEditFormEditNew(item);
                                    setPreviewItem(item);
                                    // setFieldChoose(item);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                            <Tooltip
                              title="Chuyển xuống tin nháp"
                              arrow
                              placement="top-end"
                            >
                              <IconButton
                                aria-label="delete"
                                className="m-button-rounded m-button-danger"
                                onClick={() => {
                                  dispatch(changeLoading(true));
                                  onChangeStatusPublish(item._id);
                                }}
                              >
                                <RemoveIcon />
                              </IconButton>
                            </Tooltip>
                            {item.status !== "published" && (
                              <Tooltip
                                title="Permament Delete"
                                arrow
                                placement={"top-end"}
                              >
                                <IconButton
                                  className="m-button-rounded m-button-warning ml-5"
                                  onClick={() => {
                                    setFieldChoose(item);
                                    setModalNewDelete(true);
                                  }}
                                >
                                  <DoDisturbAltIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                          </Box>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
            </>
          </CardTable>
          <Pagination
            style={{ justifyContent: "center", display: "flex" }}
            count={listNew?.lastPage}
            onChange={(e, numberPage: number) => {
              setCurrentPage(numberPage);
            }}
            color="primary"
          />
        </Box>
        <ModalComponent
          classNameContent="bg-white"
          open={isOpenModal}
          handleClose={() => {
            handlerResetAll();
          }}
          modalType={nameAction}
          styleComponent={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4
          }}
          isHasPreview={true}
          handleShowPreview={handleShowPreview}
        >
          <>
            <Typography variant="h6">
              {nameAction === "editNew" ? "Sửa tin tức" : "Thêm mới tin tức"}
            </Typography>
            <form
              onSubmit={handleSubmit2(onSubmitFormAdd)}
              id="content-form-a"
              className="mt-5"
            >
              <Box overflow={"auto"} maxHeight={`calc(100vh - 300px)`}>
                {/* {renderModal} */}
                <Controller
                  name="title"
                  control={control2}
                  rules={{ required: false }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextFieldComponent
                      label="Tiêu đề"
                      value={value}
                      onChangeValue={onChange}
                      warningText={errors.name && "Đây là 1 trường bắt buộc"}
                    />
                  )}
                />
                <div className="bg-white shadow-lg">
                  <Typography className="mb-2">Nội dung bài viết</Typography>
                  <Controller
                    name="content"
                    control={control2}
                    rules={{ required: false }}
                    render={({ field }) => <WYSIWYGEditor {...field} />}
                  />
                </div>
                <Box mt={2}>
                  <Typography>
                    Ảnh trên (Ưu tiên tỉ lệ ảnh ngang 21:9)
                  </Typography>
                  <Controller
                    control={control2}
                    name={"img"}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <>
                        <div
                          {...getRootProps({
                            className:
                              "dropzone w-100 fs-20 d-flex align-items-center"
                          })}
                          {...field}
                        >
                          <input {...getInputProps()} />
                          {isDragActive ? (
                            <span className="fs-16">
                              <span className="box-blue">Chọn ảnh</span> hoặc
                              kéo & thả nó ở đây ...{" "}
                            </span>
                          ) : (
                            <Button className="m-button m-button-success">
                              Chọn ảnh
                            </Button>
                          )}
                        </div>
                        {urlMainImage && (
                          <Box
                            className="w-fit relative"
                            width={"fit-content"}
                            margin={"auto"}
                          >
                            <Button
                              variant="text"
                              className="absolute"
                              onClick={() => {
                                setMainPhoto("");
                                setUrlMainImage("");
                              }}
                              style={{
                                right: 0,
                                top: 0,
                                backgroundColor: "#fff",
                                padding: "3px 6px",
                                minWidth: "fit-content"
                              }}
                            >
                              X
                            </Button>

                            <img
                              loading="lazy"
                              src={urlMainImage}
                              className="rounded"
                              alt="image"
                              style={{
                                width: 300,
                                margin: "20px auto",
                                objectFit: "contain"
                              }}
                            />
                          </Box>
                        )}
                      </>
                    )}
                  />
                </Box>
                <Box mt={2}>
                  <Typography>Ảnh dưới</Typography>
                  <Controller
                    control={control2}
                    name={"mainImg"}
                    rules={{ required: false }}
                    render={({ field }) => (
                      <>
                        <div
                          {...getRootProps2({
                            className:
                              "dropzone w-100 fs-20 d-flex align-items-center"
                          })}
                          {...field}
                        >
                          <input {...getInputProps2()} />
                          {isDragActive2 ? (
                            <span className="fs-16">
                              <span className="box-blue">Chọn ảnh</span> hoặc
                              kéo & thả nó ở đây ...{" "}
                            </span>
                          ) : (
                            <Button className="m-button m-button-success">
                              Chọn ảnh
                            </Button>
                          )}
                        </div>
                        {urlSmallImage && (
                          <Box
                            className="w-fit relative"
                            width={"fit-content"}
                            margin={"auto"}
                          >
                            <Button
                              variant="text"
                              className="absolute"
                              onClick={() => {
                                setUrlSmallImage("");
                                setSmallPhoto("");
                              }}
                              style={{
                                right: 0,
                                top: 0,
                                backgroundColor: "#fff",
                                padding: "3px 6px",
                                minWidth: "fit-content"
                              }}
                            >
                              X
                            </Button>

                            <img
                              loading="lazy"
                              src={urlSmallImage}
                              className="rounded"
                              alt="image"
                              style={{
                                width: 300,
                                margin: "20px auto",
                                objectFit: "contain"
                              }}
                            />
                          </Box>
                        )}
                      </>
                    )}
                  />
                </Box>
                <Box mt={2}>
                  <Controller
                    name="status"
                    control={control2}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      // <StatusSelect value="published" onChange={onChange} disabled={true} />
                      <StatusSelect value={value} onChange={onChange} />
                    )}
                  />
                </Box>
                <Box mt={2}>
                  <Controller
                    name="type"
                    control={control2}
                    rules={{ required: true }}
                    render={({ field: { onChange, value } }) => (
                      <StatusSelect
                        value={value}
                        onChange={onChange}
                        type="typeNews"
                      />
                    )}
                  />
                </Box>
                <Box mt={0}>
                  <Controller
                    name="homeDisplay"
                    control={control2}
                    rules={{ required: false }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Box display={"flex"} alignItems={"center"}>
                        <Checkbox
                          value={value}
                          onChange={(e: any, value: boolean) => {
                            onChange(value);
                          }}
                        />
                        <Typography>Hiển thị trên Trang chủ</Typography>
                      </Box>
                    )}
                  />
                </Box>
              </Box>
            </form>
          </>
        </ModalComponent>
        <ModalDelete
          isOpenModal={isOpenModalNewDelete}
          onCloseModal={() => {
            setModalNewDelete(false);
            handlerResetAll();
          }}
          onClickConfirm={() => handlerDeleteNew(fieldChoose._id)}
        />
        <ModalPreview
          isOpenModal={isOpenModalPreview}
          onCloseModal={() => {
            setIsOpenModalPreview(false);
          }}
          item={previewItem}
        />
      </BoxContent>
    </>
  );
}

export default PublicedNews;
