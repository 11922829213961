import React from "react";
import HeaderComponent from "./Header";
import FooterComponent from "./Footer";
import { ReactNode } from "components/Layouts/common";
import Sidebar from "./Sidebar";
import "./Layout.scss";
import IndicatorComponent from "components/common/Indicator";
import { Box } from "@mui/system";
import { useAppSelector } from "App/Store";

function Layout({ children }: ReactNode): JSX.Element {
  const {
    animation: { isLoading },
  } = useAppSelector((state) => {
    return state;
  });

  return (
    <>
      {isLoading && (
        <Box className="box-indicator">
          <IndicatorComponent />
        </Box>
      )}

      <div className={`layout__main`}>
        <div className="box-left__main">
          <Sidebar />
        </div>
        <div className="col-right__main relative md:ml-64 bg-blueGray-100">
          <div className={`box-header__main`}>
            <HeaderComponent />
          </div>
          <div className={`box-body__main`}>{children}</div>
          <div className="footer-wrap__main" style={{ marginTop: "auto" }}>
            <FooterComponent />
          </div>
        </div>
      </div>
    </>
  );
}

export default Layout;
