import {
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  IconButton,
  Button,
} from '@mui/material';
import { Box } from '@mui/system';
import BoxContent from 'components/common/BoxContent';
import BoxLightBlue from 'components/common/BoxLightBule';
import React, { useEffect, useState } from 'react';
import formatMoney from 'utilities/formatMoney';
import PlanRevenueExpensesNavbar from './PlanRevenueExpensesNavbar';
import './index.scss';
import TextFieldComponent from '../../components/common/Input/TextField';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import EditIcon from '@mui/icons-material/Edit';
import DateRangeIcon from '@material-ui/icons/DateRange';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import { changeLoading } from 'App/Features/Animation';
import { useAppDispatch } from '../../App/Store';
import useMessage from '../../Hooks/useMessage';
import { contentApi } from 'Datasource/content';
import ModalDelete from '../../components/common/Modal/ModalDelete';
import ModalAdd from '../../components/common/Modal/ModalAdd';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import _ from 'lodash';
interface Props {}

function PlanRevenueExpenses(props: Props) {
  const {} = props;
  const [plan, setPlan] = useState([{}]);
  const [editing, setEditing] = useState(false);
  const [editingId, setEditingId] = useState<string | number>('');
  const [name, setName] = useState('');
  const [revenue, setRevenue] = useState<string>('');
  const [fixedExpense, setFixedExpense] = useState<string>('');
  const [incurredExpense, setIncurredExpense] = useState<string>('');
  const [savings, setSavings] = useState<string>('');
  const [addingNewItem, setAddingNewItem] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [months, setMonths] = useState<any[]>([]);
  const [startDate, setStartDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState<number>(0);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [isLocked, setIsLocked] = useState<boolean>(false);
  const [isOpenLockModal, setIsOpenLockModal] = useState<boolean>(false);
  const [currentMonthIndex, setCurrentMonthIndex] = useState<number>(0);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [deletingItem, setDeletingItem] = useState<any>({});
  const [previousMonthTitle, setPreviousMonthTitle] = useState<string>('');
  const [currentMonthTitle, setCurrentMonthTitle] = useState<string>('');
  const dispatch = useAppDispatch();
  const { openMessage } = useMessage();

  const [summary, setSummary] = useState({
    curBalance: 0,
    sumRevenue: 0,
    sumFixedExpense: 0,
    sumIncurredExpense: 0,
    sumExpenses: 0,
    sumSavings: 0,
    curRemaining: 0,
  });

  const LOCK_MONTH_TITLE = 'Bạn chắc chắn muốn chốt dự kiến tháng này?';
  const DELETE_PLAN_TITLE = 'Bạn chắc chắn muốn xóa dữ liệu này?';

  const getDataForMonth = async (date: string, monthParam: number) => {
    const res = await contentApi.getMonthData(date, monthParam);
    let plan = res.data.plans;
    setPlan(plan.length === 0 ? [] : plan);
    setIsLocked(res.data.isLocked);
    setPreviousMonthTitle(res.data.previousMonthTitle);
    setCurrentMonthTitle(
      `${monthParam < 10 ? '0' + monthParam : monthParam}/${date}`
    );
    setSummary({
      curBalance: res.data.curBalance,
      sumRevenue: res.data.sumRevenue,
      sumFixedExpense: res.data.sumFixedExpense,
      sumIncurredExpense: res.data.sumIncurredExpense,
      sumExpenses: res.data.sumExpenses,
      sumSavings: res.data.sumSavings,
      curRemaining: res.data.curRemaining,
    });
  };

  const handleSelectMonth = async (selectedMonth: any) => {
    let date = dayjs(`${startDate}`).format('YYYY');
    let matches = selectedMonth.month.match(/(\d+)/);
    let monthParam = matches !== null ? Number(matches[0]) : undefined;
    if (monthParam !== undefined) {
      await getDataForMonth(date, monthParam);
      setCurrentMonth(monthParam - 1);
      setCurrentMonthIndex(months.indexOf(selectedMonth));
    }
  };

  async function getMonthsOfYear(date: string) {
    const res = await contentApi.getMonthsOfYear(date);
    let monthParam: undefined | number = undefined;
    if (res.data.length !== 0) {
      res.data.forEach((data: any) => {
        let date = new Date(data.month);
        let monthInfo = {
          month: `Tháng ${date.getMonth() + 1}`,
          isLocked: data.isLocked,
        };
        if (!months.some((monthData) => monthData.month === monthInfo.month)) {
          months.push(monthInfo);
        }
      });
      setMonths([...months]);
      let matches = months[0].month.match(/(\d+)/);
      monthParam = matches !== null ? Number(matches[0]) : monthParam;
      if (monthParam !== undefined) {
        await getDataForMonth(date, monthParam);
        setCurrentMonth(monthParam - 1);
        setCurrentMonthIndex(0);
      }
    } else {
      resetData();
    }
  }

  const resetData = () => {
    let year = dayjs(`${startDate}`).format('YYYY');
    setMonths([]);
    setPlan([]);
    setCurrentMonthTitle('...');
    setPreviousMonthTitle('...');
    setSummary({
      curBalance: 0,
      sumRevenue: 0,
      sumFixedExpense: 0,
      sumIncurredExpense: 0,
      sumExpenses: 0,
      sumSavings: 0,
      curRemaining: 0,
    });
  };

  useEffect(() => {
    let date = dayjs(`${startDate}`).format('YYYY');
    getMonthsOfYear(date);
  }, []);

  // const handleOnClick = () => {
  //   setOpenModal(true);
  // }

  const onSubmit = async (item: any) => {
    dispatch(changeLoading(true));
    const updatedRecord = {
      ...item,
      name,
      revenue: _.isEmpty(revenue) ? null : Number(revenue.split('.').join('')),
      fixedExpense: _.isEmpty(fixedExpense)
        ? null
        : Number(fixedExpense.split('.').join('')),
      incurredExpense: _.isEmpty(incurredExpense)
        ? null
        : Number(incurredExpense.split('.').join('')),
      savings: _.isEmpty(savings) ? null : Number(savings.split('.').join('')),
    };
    const year = dayjs(`${startDate}`).format('YYYY');

    if (name === null || name === '') {
      openMessage({
        variant: 'error',
        message: 'Nội dung không được để trống!',
      });
      dispatch(changeLoading(false));
      return;
    } else if (
      revenue === '0' ||
      fixedExpense === '0' ||
      incurredExpense === '0' ||
      savings === '0'
    ) {
      openMessage({
        variant: 'error',
        message: 'Các khoản thu chi không được là 0!',
      });
      dispatch(changeLoading(false));
      return;
    }

    if (item._id) {
      const res = await contentApi.updatePlan(updatedRecord);
      if (res.data.STATUS_CODE === 200) {
        item.name = name;
        item.revenue = revenue;
        item.fixedExpense = fixedExpense;
        item.incurredExpense = incurredExpense;
        item.savings = savings;
        setEditing(false);
        openMessage({
          variant: 'success',
          message: 'Cập nhật thành công!',
        });
      } else {
        openMessage({
          variant: 'error',
          message: 'Cập nhật thất bại!',
        });
      }
    } else {
      const newItem = {
        name,
        revenue: _.isEmpty(revenue)
          ? null
          : Number(revenue.split('.').join('')),
        fixedExpense: _.isEmpty(fixedExpense)
          ? null
          : Number(fixedExpense.split('.').join('')),
        incurredExpense: _.isEmpty(incurredExpense)
          ? null
          : Number(incurredExpense.split('.').join('')),
        savings: _.isEmpty(savings)
          ? null
          : Number(savings.split('.').join('')),
        month: new Date(Number(year), currentMonth, 2),
      };

      console.log('new item', newItem);
      const res = await contentApi.createPlan(newItem);
      if (res.data.STATUS_CODE === 201) {
        openMessage({
          variant: 'success',
          message: 'Thêm mới thành công!',
        });
        plan[plan.length - 1] = res.data.META_DATA;
        setPlan([...plan]);
        setEditing(false);
        setAddingNewItem(false);
      }
    }
    await getDataForMonth(year, currentMonth + 1);
    dispatch(changeLoading(false));
  };

  const inputProps = {
    step: 100000,
    min: 1000,
  };

  const handleAddRow = () => {
    plan.push({
      isAddNew: true,
    });
    setPlan([...plan]);
    setEditing(true);
    setAddingNewItem(true);
    setName('');
    setRevenue('');
    setFixedExpense('');
    setIncurredExpense('');
    setSavings('');
    setEditingId(plan.length - 1);
  };

  // const handleAddMonth = async () => {
  //   dispatch(changeLoading(true));
  //   const nowMonth = new Date().getMonth() + 1;
  //   let month = 0;
  //   let year = dayjs(`${startDate}`).format("YYYY");
  //
  //   if (months.length !== 0) {
  //     let matches = months[months.length - 1].month.match(/(\d+)/);
  //     let latestMonthInList = matches !== null ? Number(matches[0]) : 0;
  //     month = latestMonthInList < nowMonth ? nowMonth : latestMonthInList + 1;
  //   }
  //   const request = {
  //     year,
  //     month
  //   }
  //
  //   if(month > 12) {
  //     openMessage({
  //       variant: "error",
  //       message: "Không thể thêm tháng!",
  //     });
  //     setOpenModal(false);
  //     dispatch(changeLoading(false));
  //
  //     return;
  //   }
  //   const res = await contentApi.createMonth(request);
  //   if(res.data.STATUS_CODE === 201) {
  //     setOpenModal(false);
  //     dispatch(changeLoading(false));
  //     let date = new Date(res.data.META_DATA.month);
  //     let monthInfo = {
  //       month: `Tháng ${date.getMonth() + 1}`,
  //       isLocked: res.data.META_DATA.isLocked
  //     }
  //     months.push(monthInfo);
  //     setMonths([...months]);
  //   }
  // }

  const handleConfirm = () => {
    dispatch(changeLoading(true));

    switch (modalTitle) {
      case LOCK_MONTH_TITLE:
        handleLockPlan();
        break;

      case DELETE_PLAN_TITLE:
        handleDeletePlan(deletingItem);
        break;
    }

    dispatch(changeLoading(false));
  };

  const removeItem = (index: number) => {
    plan.splice(index, 1);
    setPlan([...plan]);
    setAddingNewItem(false);
  };

  const handleChange = (date: Date) => {
    setIsOpen(!isOpen);
    setStartDate(date);
    console.log('date', date);
    let currentYear = dayjs(`${date}`).format('YYYY');
    getMonthsOfYear(currentYear);
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleLockPlan = async () => {
    let year = dayjs(`${startDate}`).format('YYYY');
    let month = currentMonth + 1;

    const request = {
      year,
      month,
    };

    const res = await contentApi.lockMonth(request);
    let isSuccess = res.data.STATUS_CODE === 200;
    openMessage({
      variant: `${isSuccess ? 'success' : 'error'}`,
      message: `${
        isSuccess ? 'Chốt dự kiến thành công!' : 'Chốt dự kiến thất bại!'
      }`,
    });
    if (isSuccess) {
      months[currentMonthIndex].isLocked = true;
      setMonths([...months]);
      setIsOpenLockModal(false);
    }
  };

  const handleDeletePlan = async (item: any) => {
    dispatch(changeLoading(true));
    const year = dayjs(`${startDate}`).format('YYYY');
    const res = await contentApi.deletePlan(item._id);
    const isSuccess = res.data.STATUS_CODE === 204;
    openMessage({
      variant: `${isSuccess ? 'success' : 'error'}`,
      message: `${
        isSuccess ? 'Xóa dữ liệu thành công!' : 'Xóa dữ liệu không thành công!'
      }`,
    });
    if (res.data.STATUS_CODE === 204) {
      setIsOpenLockModal(false);
      await getDataForMonth(year, currentMonth + 1);
    }
    dispatch(changeLoading(false));
  };

  const formatValue = (value: any, position: string) => {
    if (value === undefined) {
      return;
    }
    let newValue = value.toString().split('.').join('');
    switch (position) {
      case 'revenue':
        setRevenue(newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
        break;
      case 'fixedExpense':
        setFixedExpense(
          newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        );
        break;
      case 'incurredExpense':
        setIncurredExpense(
          newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
        );
        break;
      case 'savings':
        setSavings(newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'));
        break;
    }
    return newValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  };

  return (
    <>
      <BoxLightBlue />
      <Box display={'flex'} justifyContent={'right'} className="my-4 md:px-10">
        <Box className="md:px-4">
          <Button
            className="m-button mr-4"
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              handleClick();
            }}
          >
            <DateRangeIcon />
            &nbsp;{dayjs(`${startDate}`).format('YYYY')}
          </Button>

          {isOpen && (
            <Box className={'date-picker'}>
              <DatePicker
                startDate={new Date(2020, 1, 1)}
                selected={startDate}
                onChange={(date: Date) => handleChange(date)}
                minDate={new Date(2020, 1, 1)}
                maxDate={new Date()}
                dateFormat="yyyy"
                yearItemNumber={
                  new Date().getFullYear() -
                  new Date(2020, 1, 1).getFullYear() +
                  1
                }
                showYearPicker
                selectsStart
                inline
              />
            </Box>
          )}
          {/*<Button*/}
          {/*    className="m-button m-button-success mr-4"*/}
          {/*    type="button"*/}
          {/*    onClick={() => handleOnClick()}*/}
          {/*> Thêm tháng*/}
          {/*</Button>*/}
          <Button
            className="m-button m-button-light fit-content"
            type="button"
            onClick={() => {
              setIsOpenLockModal(true);
              setModalTitle(LOCK_MONTH_TITLE);
            }}
          >
            {' '}
            Chốt dự kiến
          </Button>
        </Box>
      </Box>

      <PlanRevenueExpensesNavbar
        monthList={months}
        handleSelectMonth={handleSelectMonth}
      />
      <BoxContent>
        <TableContainer component={Paper}>
          <Table
            aria-label="collapsible table"
            style={{ tableLayout: 'fixed' }}
          >
            <TableHead sx={{}}>
              <TableRow sx={{ border: 'black 1px solid' }}>
                <TableCell
                  variant="head"
                  align="center"
                  className="stick"
                  size="small"
                  width={200}
                  rowSpan={3}
                  style={{ backgroundColor: '#CFE2F3' }}
                >
                  <div className="font-bold text-lg">Nội dung</div>
                </TableCell>
                <TableCell
                  width={100}
                  align="center"
                  className="col-item"
                  style={{ backgroundColor: '#F9CB9C' }}
                >
                  <div className="font-bold text-base">
                    {previousMonthTitle}
                  </div>
                </TableCell>
                <TableCell
                  width={700}
                  align="center"
                  className="col-item"
                  colSpan={6}
                  style={{ backgroundColor: '#93C47D' }}
                >
                  <div className="font-bold text-base">{currentMonthTitle}</div>
                </TableCell>
                <TableCell
                  width={100}
                  align="center"
                  className="col-item"
                  rowSpan={4}
                  style={{ backgroundColor: '#CFE2F3' }}
                >
                  <div className="font-bold">Chức năng</div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width={100}
                  align="center"
                  className="col-item"
                  rowSpan={2}
                  style={{ backgroundColor: '#FCE5CD' }}
                >
                  <div className="font-bold">
                    Tồn quỹ
                    <br />
                    (thực tế)
                  </div>
                </TableCell>
                <TableCell
                  width={100}
                  align="center"
                  className="col-item"
                  rowSpan={2}
                  style={{ backgroundColor: '#B6D7A8' }}
                >
                  <div className="font-bold">
                    Thu
                    <br />
                    (dự kiến)
                  </div>
                </TableCell>
                <TableCell
                  width={300}
                  align="center"
                  className="col-item"
                  colSpan={3}
                  style={{ backgroundColor: '#B6D7A8' }}
                >
                  <div className="font-bold">Chi (dự kiến)</div>
                </TableCell>
                <TableCell
                  width={100}
                  align="center"
                  className="col-item"
                  rowSpan={2}
                  style={{ backgroundColor: '#B6D7A8' }}
                >
                  <div className="font-bold">
                    Tiết kiệm <br />
                    (Dự kiến)
                  </div>
                </TableCell>
                <TableCell
                  width={100}
                  align="center"
                  className="col-item"
                  rowSpan={2}
                  style={{ backgroundColor: '#B6D7A8' }}
                >
                  <div className="font-bold">
                    Còn lại
                    <br />
                    (dự kiến)
                  </div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width={100}
                  align="center"
                  className="col-item"
                  style={{ backgroundColor: '#D9EAD3' }}
                >
                  <div className="font-bold">Chi cố định</div>
                </TableCell>
                <TableCell
                  width={100}
                  align="center"
                  className="col-item"
                  style={{ backgroundColor: '#D9EAD3' }}
                >
                  <div className="font-bold">Chi phát sinh</div>
                </TableCell>
                <TableCell
                  width={100}
                  align="center"
                  className="col-item"
                  style={{ backgroundColor: '#D9EAD3' }}
                >
                  <div className="font-bold">Tổng chi</div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  width={300}
                  align="right"
                  className="col-item"
                  style={{ backgroundColor: '#FFF2CC' }}
                >
                  <div className="font-bold">Tổng</div>
                </TableCell>
                <TableCell
                  width={100}
                  align="right"
                  className="col-item"
                  style={{ backgroundColor: '#FFF2CC' }}
                >
                  <div className="font-bold">
                    {formatMoney(summary.curBalance)}
                  </div>
                </TableCell>
                <TableCell
                  width={100}
                  align="right"
                  className="col-item"
                  style={{ backgroundColor: '#FFF2CC' }}
                >
                  <div className="font-bold">
                    {formatMoney(summary.sumRevenue)}
                  </div>
                </TableCell>
                <TableCell
                  width={100}
                  align="right"
                  className="col-item"
                  style={{ backgroundColor: '#FFF2CC' }}
                >
                  <div className="font-bold">
                    {formatMoney(summary.sumFixedExpense)}
                  </div>
                </TableCell>
                <TableCell
                  width={100}
                  align="right"
                  className="col-item"
                  style={{ backgroundColor: '#FFF2CC' }}
                >
                  <div className="font-bold">
                    {formatMoney(summary.sumIncurredExpense)}
                  </div>
                </TableCell>
                <TableCell
                  width={100}
                  align="right"
                  className="col-item"
                  style={{ backgroundColor: '#FFF2CC' }}
                >
                  <div className="font-bold">
                    {formatMoney(summary.sumExpenses)}
                  </div>
                </TableCell>
                <TableCell
                  width={100}
                  align="right"
                  className="col-item"
                  style={{ backgroundColor: '#FFF2CC' }}
                >
                  <div className="font-bold">
                    {formatMoney(summary.sumSavings)}
                  </div>
                </TableCell>
                <TableCell
                  width={100}
                  align="right"
                  className="col-item"
                  style={{ backgroundColor: '#FFF2CC' }}
                >
                  <div className="font-bold">
                    {formatMoney(summary.curRemaining)}
                  </div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ border: 'black 1px solid' }}>
              {plan.map((item: any, idx) => (
                <TableRow
                  sx={{ border: 'black 1px solid' }}
                  className="table-child"
                  key={idx}
                >
                  {editing && editingId === idx ? (
                    <>
                      <TableCell>
                        <TextFieldComponent
                          value={name}
                          onChangeValue={setName}
                          type="text"
                          className=""
                          size="small"
                        />
                      </TableCell>
                      <TableCell align="right">
                        {item.balance && formatMoney(item.balance)}
                      </TableCell>
                      <TableCell>
                        <TextFieldComponent
                          value={revenue}
                          onChangeValue={(value: any) =>
                            formatValue(value, 'revenue')
                          }
                          type="text"
                          inputProps={inputProps}
                          className=""
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <TextFieldComponent
                          value={fixedExpense}
                          onChangeValue={(value: any) =>
                            formatValue(value, 'fixedExpense')
                          }
                          type="text"
                          inputProps={inputProps}
                          className=""
                          size="small"
                        />
                      </TableCell>
                      <TableCell>
                        <TextFieldComponent
                          value={incurredExpense}
                          onChangeValue={(value: any) =>
                            formatValue(value, 'incurredExpense')
                          }
                          type="text"
                          inputProps={inputProps}
                          className=""
                          size="small"
                        />
                      </TableCell>
                      <TableCell align="right">
                        {item.totalExpense && formatMoney(item.totalExpense)}
                      </TableCell>
                      <TableCell>
                        <TextFieldComponent
                          value={savings}
                          onChangeValue={(value: any) =>
                            formatValue(value, 'savings')
                          }
                          type="text"
                          inputProps={inputProps}
                          className=""
                          size="small"
                        />
                      </TableCell>
                      <TableCell align="right">
                        {item.remaining && formatMoney(item.remaining)}
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell align="left">{item.name}</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">
                        {item.revenue && formatMoney(item.revenue)}
                      </TableCell>
                      <TableCell align="right">
                        {item.fixedExpense && formatMoney(item.fixedExpense)}
                      </TableCell>
                      <TableCell align="right">
                        {item.incurredExpense &&
                          formatMoney(item.incurredExpense)}
                      </TableCell>
                      <TableCell align="right">
                        {item.totalExpense && formatMoney(item.totalExpense)}
                      </TableCell>
                      <TableCell align="right">
                        {item.savings && formatMoney(item.savings)}
                      </TableCell>
                      <TableCell align="right">
                        {item.remaining && formatMoney(item.remaining)}
                      </TableCell>
                    </>
                  )}
                  <TableCell>
                    {editing && editingId === idx ? (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <div className="pl-2">
                          <IconButton
                            size="small"
                            onClick={() => onSubmit(item)}
                          >
                            <CheckBoxIcon color="success" />
                          </IconButton>
                          {item.isAddNew ? (
                            <IconButton
                              size="small"
                              onClick={() => removeItem(idx)}
                            >
                              <DisabledByDefaultIcon color="error" />
                            </IconButton>
                          ) : (
                            <IconButton
                              size="small"
                              onClick={() => setEditing(false)}
                            >
                              <DisabledByDefaultIcon color="error" />
                            </IconButton>
                          )}
                        </div>
                      </Box>
                    ) : (
                      <div className="flex justify-center items-center p-2">
                        <div className="pl-2">
                          {!isLocked && (
                            <>
                              <EditIcon
                                className="cursor-pointer mr-2"
                                fontSize="small"
                                onClick={() => {
                                  setEditing(true);
                                  setEditingId(idx);
                                  setName(item.name);
                                  setRevenue(
                                    formatValue(item.revenue, 'revenue')
                                  );
                                  setFixedExpense(
                                    formatValue(
                                      item.fixedExpense,
                                      'fixedExpense'
                                    )
                                  );
                                  setIncurredExpense(
                                    formatValue(
                                      item.incurredExpense,
                                      'incurredExpense'
                                    )
                                  );
                                  setSavings(
                                    formatValue(item.savings, 'savings')
                                  );
                                }}
                              />
                              <DeleteForeverIcon
                                className="cursor-pointer mr-2"
                                fontSize="small"
                                onClick={() => {
                                  setIsOpenLockModal(true);
                                  setModalTitle(DELETE_PLAN_TITLE);
                                  setDeletingItem(item);
                                }}
                              />
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {!addingNewItem && months.length !== 0 && !isLocked && (
          <Button
            className="m-button m-button-light fit-content mt-6"
            type="button"
            onClick={handleAddRow}
          >
            {' '}
            Thêm dữ liệu
          </Button>
        )}
      </BoxContent>
      {/*<ModalAdd*/}
      {/*    isOpenModal={openModal}*/}
      {/*    onCloseModal={() => {*/}
      {/*      setOpenModal(false);*/}
      {/*    }}*/}
      {/*    styleComponent={{*/}
      {/*      position: "absolute" as "absolute",*/}
      {/*      top: "50%",*/}
      {/*      left: "50%",*/}
      {/*      transform: "translate(-50%, -50%)",*/}
      {/*      width: 600,*/}
      {/*      bgcolor: "background.paper",*/}
      {/*      boxShadow: 24,*/}
      {/*      p: 4,*/}
      {/*    }}*/}
      {/*    onClickConfirm={() => {*/}
      {/*      handleAddMonth();*/}
      {/*    }}*/}
      {/*/>*/}
      <ModalDelete
        classNameContent="bg-white"
        styleComponent={{
          position: 'absolute' as 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 600,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}
        title={modalTitle}
        isOpenModal={isOpenLockModal}
        onCloseModal={() => {
          setIsOpenLockModal(false);
        }}
        onClickConfirm={() => handleConfirm()}
      />
    </>
  );
}

export default PlanRevenueExpenses;
