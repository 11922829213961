/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import dashboardAxios from "Api/Interceptors";

export const sharingApi = {
  getAllSharing: (params: any) => {
    const { page } = params;
    return dashboardAxios.get(
      `/sharings/all?page=${page ? page : 1}&pageSize=10`
    );
  },
  deleteSharing: (id: string) => dashboardAxios.delete(`/sharings/${id}`),
  
};
