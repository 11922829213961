import * as React from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

const filter = createFilterOptions<FilmOptionType>();

interface Props {
  keyValue?: string;
  value?: any;
  setValue?: any;
  option?: any[];
  setSearchValue?: any;
  disabled?: boolean;
  isMatchedResult?: boolean;
  submitCreateNewModal?: any;
}

function SearchableDropDown(props: Props) {
  const {
    keyValue,
    value,
    setValue,
    option,
    setSearchValue,
    disabled,
    isMatchedResult,
    submitCreateNewModal,
  } = props;
  const [open, toggleOpen] = React.useState(false);

  const handleClose = () => {
    setDialogValue({
      title: "",
      value: "",
    });
    toggleOpen(false);
  };

  const [dialogValue, setDialogValue] = React.useState({
    title: "",
    value: "",
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    submitCreateNewModal();
    handleClose();
  };

  return (
    <React.Fragment>
      <div className="z-0">
        <Autocomplete
          size="small"
          fullWidth
          value={value}
          onInputChange={(event: any) => {
            setSearchValue(event?.target.value);
          }}
          className="z-0"
          onChange={(event, newValue, type) => {
            if (typeof newValue === "string") {
              // timeout to avoid instant validation of the dialog's form.
              setTimeout(() => {
                toggleOpen(true);
                setDialogValue({
                  title: newValue,
                  value: "",
                });
              });
            } else if (newValue && newValue.inputValue) {
              toggleOpen(true);
              setDialogValue({
                title: newValue.value,
                value: "",
              });
            } else {
              setValue(keyValue, newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            if (params.inputValue !== "" && !isMatchedResult) {
              filtered.push({
                inputValue: params.inputValue,
                value: params.inputValue,
                title: `Thêm mới "${params.inputValue}"`,
              });
            }

            return filtered;
          }}
          id="free-solo-dialog-demo"
          options={option ? option : []}
          getOptionLabel={(option) => {
            // e.g value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.title;
          }}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          renderOption={(props, option) => <li {...props}>{option.title}</li>}
          freeSolo
          disabled={disabled}
          renderInput={(params) => (
            <TextField className="z-0" {...params} label="Vui lòng nhập miêu tả" />
          )}
        />
      </div>
      <Dialog open={open} onClose={handleClose} fullWidth={false} maxWidth={"sm"}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Thêm mới miêu tả</DialogTitle>
          <DialogContent>
            <TextField
              size="small"
              autoFocus
              margin="dense"
              id="name"
              value={dialogValue.title}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  title: event.target.value,
                })
              }
              label="Miêu tả"
              type="text"
              variant="standard"
            />
          </DialogContent>
          <DialogActions>
            <Button className="m-button text-white text-bold mr-10 m-button-success" type="submit">Thêm mới</Button>
            <Button className="m-button m-button-danger text-white text-bold ml-atuo" onClick={handleClose}>Hủy bỏ</Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

interface FilmOptionType {
  inputValue?: string;
  title: string;
  value: string;
}

export default SearchableDropDown;
