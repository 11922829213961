import React, { useEffect, useState } from 'react';
import { Pagination, Typography } from '@mui/material';
import { Box } from '@mui/system';
import BoxContent from 'components/common/BoxContent';
import BoxLightBlue from 'components/common/BoxLightBule';
import CardTable from 'components/common/ListTable/CardTable';
import { headerTableSubmitssion } from 'Datasource/dataCommonTable';
import dayjs from 'dayjs';
import { useAppDispatch } from 'App/Store';
import { sharingApi } from 'Datasource/sharing';
import { changeLoading } from 'App/Features/Animation';
import { AxiosError } from 'axios';
import useMessage from 'Hooks/useMessage';
import ModalDelete from 'components/common/Modal/ModalDelete';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
interface Props {}

function Submission(props: Props) {
  const {} = props;
  const { openMessage } = useMessage();
  const dispatch = useAppDispatch();
  const [listSubmitssion, setListSubmitssion] = useState<any>();
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [isOpenModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [itemChosse, setItemChosse] = useState<any>();

  useEffect(() => {
    if (listSubmitssion?.items.length === 0 && currentPage > 1) {
      getListSubmitssion({ page: 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listSubmitssion, currentPage]);

  useEffect(() => {
    dispatch(changeLoading(true));
    const params = {
      page: currentPage,
    };
    getListSubmitssion(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const getListSubmitssion = async (params: any) => {
    try {
      const {
        data: { META_DATA },
      } = await sharingApi.getAllSharing(params);
      setListSubmitssion(META_DATA);
      dispatch(changeLoading(false));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(changeLoading(false));
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: 'error',
          message: errorObject?.data.message,
        });
      }
    }
  };

  const deleteSubmission = async () => {
    try {
      dispatch(changeLoading(true));
      const res = await sharingApi
        .deleteSharing(itemChosse)
        .then((res: any) => {
          openMessage({
            variant: `${res.data.STATUS_CODE === 204 ? 'success' : 'error'}`,
            message: `${
              res.data.STATUS_CODE === 204
                ? 'Xóa thành công'
                : res.data.MESSAGE_CLIENT
            }`,
          });
          if (res.data.STATUS_CODE === 204) {
            getListSubmitssion({ page: currentPage });
            setOpenModalDelete(false);
          }
        });
      return res;
    } catch (error) {
      dispatch(changeLoading(false));
      setOpenModalDelete(false);
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: 'error',
          message: errorObject?.data.message,
        });
      }
    }
  };

  return (
    <>
      <BoxLightBlue />
      <BoxContent>
        <Box className="box-content mt-5">
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            className="my-4"
          >
            <Typography>Submission</Typography>
          </Box>
          <CardTable
            dataHeaderTable={headerTableSubmitssion}
            className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white overflow-x-auto"
            dataBodyTable={listSubmitssion?.items}
          >
            <>
              {listSubmitssion &&
                listSubmitssion.items?.map((item: any, index: any) => (
                  <tr key={index}>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left ">
                      <Typography
                        className={'ml-3 font-bold text-blueGray-600'}
                      >
                        {listSubmitssion.currentPage <= 1
                          ? index + 1
                          : (listSubmitssion.currentPage - 1) * 10 + index + 1}
                      </Typography>
                    </th>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center less-with">
                      <Typography className="text-sm">{item?.name}</Typography>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4 text-center">
                      <Typography className="text-sm">
                        {item?.employeeId}
                      </Typography>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4 text-center">
                      <Typography className="text-sm">{item?.topic}</Typography>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4 text-center">
                      <Typography>
                        {dayjs(`${item?.timeStart}`).format('DD-MM-YYYY hh:mm')}
                      </Typography>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4 text-center">
                      <Typography>
                        {dayjs(`${item?.timeEnd}`).format('DD-MM-YYYY hh:mm')}
                      </Typography>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4 text-center">
                      <Typography className="text-sm">
                        {item?.attendee}
                      </Typography>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4 text-center">
                      <Typography className="text-sm">
                        {item?.format}
                      </Typography>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4 text-center">
                      <Typography className="text-sm">{item?.note}</Typography>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                      <IconButton
                        aria-label="delete"
                        className="m-button-rounded m-button-danger"
                        onClick={() => {
                          setOpenModalDelete(true);
                          setItemChosse(item._id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
            </>
          </CardTable>
          <Pagination
            style={{ justifyContent: 'center', display: 'flex' }}
            count={listSubmitssion?.lastPage}
            onChange={(e, numberPage: number) => {
              setCurrentPage(numberPage);
            }}
            color="primary"
          />
          <ModalDelete
            classNameContent="bg-white"
            styleComponent={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
            isOpenModal={isOpenModalDelete}
            onCloseModal={() => setOpenModalDelete(false)}
            onClickConfirm={() => deleteSubmission()}
          />
        </Box>
      </BoxContent>
    </>
  );
}

export default Submission;
