const formatMoney = (amount: number) => {
  const result = new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  }).format(amount);
  
  return result;
};

export default formatMoney;
