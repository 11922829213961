export const headerTableDuLeader = [
  {
    id: 1,
    name: "Name",
  },
  {
    id: 2,
    name: "Photo",
  },
  {
    id: 3,
    name: "Chức năng",
  },
];

export const headerTableTeams = [
  {
    id: 1,
    name: "#",
  },
  {
    id: 2,
    name: "Tên team",
  },
  {
    id: 3,
    name: "Chức năng",
  },
];

export const headerTableRoles = [
  {
    id: 1,
    name: "#",
  },
  {
    id: 2,
    name: "Tên chức vụ",
  },
  {
    id: 3,
    name: "Miêu tả",
  },
  {
    id: 4,
    name: "Chức năng",
  },
];

export const headerTableMembersPage = [
  {
    id: 0,
    name: "",
  },
  {
    id: 1,
    name: "ID",
  },
  {
    id: 2,
    name: "Họ và tên",
  },
  {
    id: 3,
    name: "Ảnh",
  },
  {
    id: 4,
    name: "VMO Email",
  },
  {
    id: 4,
    name: "Ngày sinh",
  },
  {
    id: 5,
    name: "Ngày Onboard",
  },
  {
    id: 6,
    name: "Chức vụ",
  },
  {
    id: 7,
    name: "Newbie",
  },
  {
    id: 8,
    name: "Trạng thái",
  },
  {
    id: 9,
    name: "Chức năng",
  },
];

export const headerTableBudgetManagement = [
  {
    id: 1,
    name: "Ngày",
  },
  {
    id: 2,
    name: "Loại thu/chi",
  },
  {
    id: 3,
    name: "Loại",
  },
  {
    id: 4,
    name: "Mô tả",
  },
  {
    id: 5,
    name: "Tổng tiền",
  },
  {
    id: 6,
    name: "Chức năng",
  },
];

export const headerTableBudgetCategories = [
  {
    id: 1,
    name: "#",
  },
  {
    id: 2,
    name: "Loại thu chi",
  },
  {
    id: 3,
    name: "Tên",
  },
  {
    id: 4,
    name: "Miêu tả",
  },
  {
    id: 5,
    name: "Chức năng",
  },
];

export const headerTableMember = [
  {
    id: 1,
    name: "#",
  },
  {
    id: 2,
    name: "Họ và tên",
  },
  {
    id: 3,
    name: "Chức vụ",
  },
  {
    id: 4,
    name: "Team",
  },
  {
    id: 5,
    name: "Ảnh",
  },
  {
    id: 6,
    name: "Chức năng",
  },
];

export const headerTableMonthlyBudgetPage = [
  {
    id: 1,
    name: "#",
  },
  {
    id: 2,
    name: "ID",
  },
  {
    id: 3,
    name: "Tên",
  },
  {
    id: 4,
    name: "Giá tiền",
  },
  {
    id: 5,
    name: "Miêu tả",
  },
  {
    id: 6,
    name: "Trạng thái",
  },
];

export const headerTableTechstackGroup = [
  {
    id: 1,
    name: "#",
  },
  {
    id: 2,
    name: "Tên nhóm Techstack",
  },
  {
    id: 3,
    name: "Chức năng",
  },
];

export const headerTableTechstackPhotos = [
  {
    id: 1,
    name: "#",
  },
  {
    id: 2,
    name: "Tên",
  },
  {
    id: 3,
    name: "Group",
  },
  {
    id: 4,
    name: "Ảnh",
  },
  {
    id: 5,
    name: "Chức năng",
  },
];

export const headerTableProject = [
  {
    id: 1,
    name: "#",
  },
  {
    id: 2,
    name: "Tên dự án",
  },
  {
    id: 3,
    name: "Miêu tả",
  },
  {
    id: 4,
    name: "Ảnh",
  },
  {
    id: 5,
    name: "",
  },
];

export const headerTableClient = [
  {
    id: 1,
    name: "#",
  },
  {
    id: 2,
    name: "Tên khách hàng",
  },
  {
    id: 3,
    name: "Ảnh",
  },
  {
    id: 4,
    name: "Chức năng",
  },
];

export const headerTablePublicNewList = [
  {
    id: 1,
    name: "#",
  },
  {
    id: 2,
    name: "Tiêu đề",
  },
  {
    id: 3,
    name: "Thể loại",
  },
  {
    id: 4,
    name: "Trạng thái",
  },
  {
    id: 5,
    name: "Ngày phát hành",
  },
  {
    id: 6,
    name: "Ảnh",
  },
  {
    id: 7,
    name: "Chức năng",
  },
];

export const headerTablePublicPost = [
  {
    id: 1,
    name: "#",
  },
  {
    id: 2,
    name: "Chức danh công việc",
  },
  {
    id: 3,
    name: "Ngày",
  },
  {
    id: 4,
    name: "Trạng thái",
  },
  {
    id: 5,
    name: "Chức năng",
  },
];

export const headerTableSubmitssion = [
  {
    id: 1,
    name: "#",
  },
  {
    id: 2,
    name: "Tên",
  },
  {
    id: 3,
    name: "Mã",
  },
  {
    id: 4,
    name: "Chủ đề",
  },
  {
    id: 5,
    name: "Thời gian bắt đầu",
  },
  {
    id: 6,
    name: "Thời gian kết thúc",
  },
  {
    id: 7,
    name: "Người tham gia",
  },
  {
    id: 8,
    name: "Hình thức tham gia",
  },
  {
    id: 9,
    name: "Ghi chú",
  },
  {
    id: 10,
    name: "Chức năng",
  },
];

export const headerTableTemplate = [
  {
    id: 1,
    name: "#"
  },
  {
    id: 2,
    name: "Loại"
  },
  {
    id: 3,
    name: "Mô tả"
  },
  {
    id: 4,
    name: "Chức năng"
  }
]
