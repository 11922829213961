import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  title?: string;
  dataHeaderTable?: any[];
  dataBodyTable?: any[];
  className?: string;
  handleAction?: any;
  hanlderDetail?: any;
  children?: JSX.Element;
  currentPage?: number;
}

function CardTable(props: Props) {
  const {
    title,
    dataHeaderTable,
    dataBodyTable,
    className,
    handleAction,
    hanlderDetail,
    children,
    currentPage,
  } = props;
  const [color, setColor] = useState<string>("light");
  const [data, setData] = useState<any>();
  useEffect(() => {
    setData(dataBodyTable);
  }, [dataBodyTable]);

  return (
    <div className={className}>
      <table className="items-center w-full border-collapse border-slate-400">
        <thead>
          <tr>
            {dataHeaderTable?.map((item, index) => {
              return (
                <th
                  key={index}
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-center" +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  {item.name}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {!data ? (
            <th className='text-center'>
              <span
                className={
                  "ml-5 font-normal"
                }
              >
                Không có bản ghi nào được tìm thấy
              </span>
            </th>
          ) : children ? (
            <>{children}</>
          ) : (
            data.map((item: any, index: any) => {
              return (
                <tr key={index}>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                    <Typography
                      className={
                        "ml-3 font-bold " +
                        +(color === "light"
                          ? "text-blueGray-600"
                          : "text-white")
                      }
                    >
                      {currentPage && currentPage > 1
                        ? (currentPage - 1) * 10 + index + 1
                        : index + 1}
                    </Typography>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                    {hanlderDetail ? (
                      <Button
                        variant="text"
                        className="btn-none-focus"
                        style={{ padding: 0 }}
                        onClick={() => hanlderDetail(item)}
                      >
                        {item.name}
                      </Button>
                    ) : (
                      <Typography>{item.name}</Typography>
                    )}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center w-100">
                    <Box display={"flex"} justifyContent={'center'}>
                      <IconButton aria-label="delete" className='m-button-rounded m-button-warning mr-5' onClick={() => {
                        handleAction("edit", item)
                      }}>
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="delete" className='m-button-rounded m-button-danger' onClick={() => {
                        handleAction("deleted", item)
                      }}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </td>
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
}

export default CardTable;
