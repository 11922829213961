import React from "react";
import { FormHelperText, TextField } from "@mui/material";
import { Box } from "@mui/system";
interface Props {
  value?: string;
  onChange: any;
  maxRows?: number;
  helperText?: string;
  className?: string;
  label?: string;
  id?: string;
  warningText?: string;
  multiline?: boolean;
  minRows?: number;
  type?: string;
  margin?: string;
  inputProps?: any;
  customStyle?: any;
}

function TextFieldComponent(props: any) {
  const {
    value,
    onChangeValue,
    maxRows = 1,
    helperText,
    className = "w-full",
    label,
    id,
    warningText,
    multiline = false,
    minRows = 1,
    type,
    margin = "10px 0",
    size = "small",
    inputProps,
    customStyle,
  } = props;

  const handlerChange = (e: any) => {
    onChangeValue(e.target.value);
  };

  return (
    <>
      <TextField
        {...props}
        id={id}
        label={label}
        maxRows={maxRows}
        value={value}
        helperText={helperText ? helperText : ""}
        className={`${className}`}
        onChange={handlerChange}
        multiline={multiline}
        minRows={minRows}
        type={`${type ? type : "string"}`}
        size={size}
        inputProps={inputProps}
        style={{
          margin: margin,
          height: "100%",
          zIndex: 0,
          ...customStyle,
        }}
      />
      {warningText && (
        <Box sx={{ marginBottom: 1, minHeight: 10 }}>
          <FormHelperText id="component-error-text" className="text-red-500">
            {warningText}
          </FormHelperText>
        </Box>
      )}
    </>
  );
}

export default TextFieldComponent;
