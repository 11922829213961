/* eslint-disable jsx-a11y/img-redundant-alt */
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import CardTable from "components/common/ListTable/CardTable";
import React, { memo } from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  headerTable?: any[];
  dataTable?: any;
  handerChosseItem: any;
  handlerActionDelete?: any;
  children?: JSX.Element;
  handlerActionEdit: any;
}

function TechStackDetailComponent(props: any) {
  const {
    headerTable,
    dataTable,
    handerChosseItem,
    handlerActionDelete,
    children,
    handlerActionEdit,
  } = props;

  return (
    <Box>
      {dataTable && (
        <CardTable
          dataHeaderTable={headerTable}
          className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white overflow-x-auto"
          dataBodyTable={dataTable}
        >
          <>
            {dataTable &&
              dataTable.items?.map((item: any, index: any) => (
                <tr key={index}>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                    <Typography className={"ml-3 font-bold text-blueGray-600"}>
                      {dataTable.currentPage <= 1
                        ? index + 1
                        : (dataTable.currentPage - 1) * 10 + index + 1}
                    </Typography>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                    <Typography className="text-sm">{item?.name}</Typography>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4">
                    <Typography className="text-sm">
                      {item?.group.name}
                    </Typography>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4">
                    <img
                      src={item?.photo?.link}
                      alt="image"
                      width={50}
                      height={50}
                    />
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                    <Box display={"flex"}>
                      <IconButton aria-label="delete" className='m-button-rounded m-button-warning mr-5' onClick={() => {
                        handerChosseItem(item.group._id);
                        handlerActionEdit(item);
                      }}>
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="delete" className='m-button-rounded m-button-danger' onClick={() => {
                        handerChosseItem(item);
                        handlerActionDelete();
                      }}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </td>
                </tr>
              ))}
          </>
        </CardTable>
      )}
    </Box>
  );
}

export default memo(TechStackDetailComponent);
