import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

interface Props {
  value: string;
  onChange: (e: SelectChangeEvent) => void;
  showBudgetType?: boolean;
}

function TemplateTypeSelect(props: Props) {
  const { value, onChange, showBudgetType } = props;

  return (
    <>
      <FormControl className="w-full" style={{ marginBottom: 30 }}>

        {showBudgetType ?
            <>
              <InputLabel id="demo-simple-select-helper-label" className="w-full">
                Kiểu
              </InputLabel>
              <Select
                  {...props}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={value}
                  label="Kiểu"
                  onChange={onChange}
                  size="small"
              >
                <MenuItem value={"new_month"}>New Month</MenuItem>
                <MenuItem value={"remind"}>Remind</MenuItem>
              </Select>
            </>
             :
            <>
              <InputLabel id="demo-simple-select-helper-label" className="w-full">
                Loại template
              </InputLabel>
              <Select
                  {...props}
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={value}
                  label="Loại template"
                  onChange={onChange}
                  size="small"
              >
                <MenuItem value={"birthday"}>Birthday</MenuItem>
                <MenuItem value={"newbie"}>Welcome newbie</MenuItem>
                <MenuItem value={"budget"}>Remind Budget</MenuItem>
              </Select>
            </>
            }

      </FormControl>
    </>
  );
}

export default TemplateTypeSelect;
