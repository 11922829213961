import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AxiosError } from "axios";
import BoxContent from "components/common/BoxContent";
import BoxLightBlue from "components/common/BoxLightBule";
import { photosApi } from "Datasource/photos";
import useMessage from "Hooks/useMessage";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

interface Props {}

function DetailFolder(props: Props) {
  const {} = props;
  const history = useHistory();
  const { id }: any = useParams();
  const { openMessage } = useMessage();
  const [dataFolder, setDataFolder] = useState<any>();

  useEffect(() => {
    getFolderById();
  }, []);

  const getFolderById = async () => {
    try {
      const { data } = await photosApi.getFolderById(id);
      setDataFolder(data?.data.META_DATA);
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  return (
    <>
      <BoxLightBlue />
      <BoxContent>
        <Button onClick={() => history.goBack()}>Back</Button>
        <Box display={"flex"} flexWrap={"wrap"}>
          {dataFolder?.photos.length === 0 ? (
            <Typography>There are no file to show</Typography>
          ) : (
            dataFolder?.photos.map((item: any, index: any) => {
              return !item.isDeleted && (
                <img
                  key={index}
                  src={`${item.link}`}
                  alt={`${item._id}`}
                  width={200}
                  className="p-3"
                  // height={120}
                />
              );
            })
          )}
        </Box>
      </BoxContent>
    </>
  );
}

export default DetailFolder;
