import React from "react";
import {Button, FormHelperText} from "@mui/material";
import {Controller, useForm} from "react-hook-form";
import TextFieldComponent from "../../Input/TextField";
import {Box} from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import SelectComponent from "../../Select/defeault";

interface Props {
    color?: string;
    title?: string;
    listUser: Array<any>;
    searched: string;
    requestSearch: (value: any) => void;
    cancelSearch: () => void;
}

const listOption = [
    {label: "None", value: null},
    {label: "True", value: true},
    {label: "False", value: false}
];

export default function CustomSearch(props: Props) {
    const {color, title, listUser, searched, requestSearch, cancelSearch} =
        props;

    const {
        handleSubmit,
        control,
        formState: {errors},
    } = useForm<any>();

    const onSubmit = async (data: any) => {
        const name = data.name;
        const id = data.id;
        const newbie = data.newbie;
        requestSearch({name, id, newbie});
    }

    return (
        <div>
            <form
                onSubmit={handleSubmit(onSubmit)}
                id="content-form"
                className="mt-5"
            >
                <Controller
                    name="name"
                    control={control}
                    rules={{required: false}}
                    render={({field: {onChange, onBlur, value}}) => (
                        <TextFieldComponent
                            label="Tên"
                            value={value}
                            onChangeValue={onChange}
                        />
                    )}
                />
                <Controller
                    name="id"
                    control={control}
                    rules={{required: false}}
                    render={({field: {onChange, onBlur, value}}) => (
                        <TextFieldComponent
                            label="ID"
                            value={value}
                            onChangeValue={onChange}
                        />
                    )}
                />
                <Controller
                    name="newbie"
                    control={control}
                    defaultValue={null}
                    rules={{ required: false }}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <SelectComponent
                                value={value}
                                onChange={onChange}
                                data={listOption}
                                className=""
                                placeholder="Newbie"
                            />
                        </>
                    )}
                />
                <Box className="w-full" display={"flex"}>
                    <Button
                        className="m-button m-button-warning m-button-middle-div"
                        type="submit"
                    >
                        <SearchIcon/>
                        Tìm kiếm
                    </Button>
                </Box>
            </form>
        </div>
    );
}

CustomSearch.defaultProps = {
    color: "light",
};

// <div className="block w-full overflow-x-auto">
//     <Paper>
//         <SearchBar
//             value={searched}
//             onChange={requestSearch}
//             onCancelSearch={cancelSearch}
//         />
//     </Paper>
// </div>
