import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AxiosError } from "axios";
import BoxContent from "components/common/BoxContent";
import BoxLightBlue from "components/common/BoxLightBule";
import { photosApi } from "Datasource/photos";
import useMessage from "Hooks/useMessage";
import React, { useEffect, useState } from "react";
import IconFolder from "common/assets/images/folder_icon.png";
import { useHistory } from "react-router-dom";
// folder_icon.png
interface Props {}

function FoldersManagement(props: Props) {
  const {} = props;
  const { openMessage } = useMessage();
  const history = useHistory();
  const [dataFolder, setDataFolder] = useState<any[]>([]);

  useEffect(() => {
    getAllFolder();
  }, []);

  const getAllFolder = async () => {
    try {
      const { data } = await photosApi.getAllFolder();
      setDataFolder(data?.data.META_DATA);
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  return (
    <>
      <BoxLightBlue />
      <BoxContent>
        <Typography>Name</Typography>
        <Box display={"flex"} flexWrap={"wrap"}>
          {dataFolder.length === 0 ? (
            <Typography>There are no folders to show</Typography>
          ) : (
            dataFolder.map((item: any, index: number) => {
              return (
                !item.isDeleted && (
                  <Button onClick={() => history.push(`/folders-management/${item._id}`)}>
                    <Box
                      className="box-folder"
                      flexDirection={"column"}
                      alignItems={"center"}
                    >
                      <img
                        src={IconFolder}
                        alt="folder"
                        width={90}
                        height={90}
                        className="m-auto"
                      />
                      <Typography textAlign={"center"}>{item?.name}</Typography>
                    </Box>
                  </Button>
                )
              );
            })
          )}
        </Box>
      </BoxContent>
    </>
  );
}

export default FoldersManagement;
