/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import dashboardAxios from "Api/Interceptors";
interface UserLogin {
  email: string;
  password: string;
}

interface IEmail {
  email: string;
}

interface IResetPassword {
  email: string;
  code: string;
  password: string;
}

export const AuthApi = {
  loginApi: (params: UserLogin) => dashboardAxios.post("/auth/sign-in", params),
  forgotPassword: (params: IEmail) =>
    dashboardAxios.post("/forgot-password", params),
  resetPassword: (params: IResetPassword) =>
    dashboardAxios.post("/reset-password", params),
};
