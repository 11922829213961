import { Box } from "@mui/system";
import React from "react";
import "./index.scss";
interface Props {}

function IndicatorComponent(props: Props) {
  const {} = props;

  return (
    <Box className="loadingSpinCT">
      <Box className="box-content-spin"></Box>
    </Box>
  );
}

export default IndicatorComponent;
