import React, { useState } from "react";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import UserDropdown from "components/common/Dropdowns/UserDropdown";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import KeyIcon from "@mui/icons-material/Key";
import HomeIcon from "@mui/icons-material/Home";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

interface Props {}

function Sidebar(props: Props) {
    const {} = props;
    const [collapseShow, setCollapseShow] = useState("hidden");
    return (
        <>
            <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
                <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
                    {/* Toggler */}
                    <button
                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                        type="button"
                        onClick={() =>
                            setCollapseShow("bg-white m-2 py-3 px-6")
                        }
                    >
                        <i className="fas fa-bars"></i>
                    </button>
                    {/* Brand */}
                    <Link
                        className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                        to="/"
                    >
                        Trang quản trị DU5
                    </Link>
                    {/* User */}
                    <ul className="md:hidden items-center flex flex-wrap list-none">
                        <li className="inline-block relative">
                            <UserDropdown />
                        </li>
                    </ul>
                    {/* Collapse */}
                    <div
                        className={
                            "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
                            collapseShow
                        }
                    >
                        {/* Collapse header */}
                        <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
                            <div className="flex flex-wrap">
                                <div className="w-6/12">
                                    <Link
                                        className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                                        to="/"
                                    >
                                        Trang quản trị DU5
                                    </Link>
                                </div>
                                <div className="w-6/12 flex justify-end">
                                    <button
                                        type="button"
                                        className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                                        onClick={() =>
                                            setCollapseShow("hidden")
                                        }
                                    >
                                        <i className="fas fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Form */}
                        <form className="mt-6 mb-4 md:hidden">
                            <div className="mb-3 pt-0">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
                                />
                            </div>
                        </form>

                        {/* Divider */}
                        <hr className="my-4 md:min-w-full" />
                        {/* Heading */}
                        <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                            Quản lý nhân sự
                        </h6>
                        {/* Navigation */}

                        <ul className="md:flex-col md:min-w-full flex flex-col list-none">
                            <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf(
                                            "/users"
                                        ) !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="/users"
                                >
                                    <PersonIcon
                                        className={
                                            "fas fa-tools mr-2 text-sm " +
                                            (window.location.href.indexOf(
                                                "/users"
                                            ) !== -1
                                                ? "opacity-75"
                                                : "text-blueGray-300")
                                        }
                                    />
                                    Nhân viên
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf(
                                            "/roles"
                                        ) !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="/roles"
                                >
                                    <AdminPanelSettingsIcon
                                        className={
                                            "fas fa-tools mr-2 text-sm " +
                                            (window.location.href.indexOf(
                                                "/roles"
                                            ) !== -1
                                                ? "opacity-75"
                                                : "text-blueGray-300")
                                        }
                                    />
                                    Chức vụ
                                </Link>
                            </li>

                            {/*<li className="items-center">*/}
                            {/*  <Link*/}
                            {/*    className={*/}
                            {/*      "text-xs uppercase py-3 font-bold block " +*/}
                            {/*      (window.location.href.indexOf("/permission") !== -1*/}
                            {/*        ? "text-lightGreen-700 hover:text-lightBlue-600"*/}
                            {/*        : "text-blueGray-700 hover:text-blueGray-500")*/}
                            {/*    }*/}
                            {/*    to="/permission"*/}
                            {/*  >*/}
                            {/*    <KeyIcon*/}
                            {/*      className={*/}
                            {/*        "fas fa-table mr-2 text-sm " +*/}
                            {/*        (window.location.href.indexOf("/permission") !== -1*/}
                            {/*          ? "opacity-75"*/}
                            {/*          : "text-blueGray-300")*/}
                            {/*      }*/}
                            {/*    />*/}
                            {/*    Phân quyền*/}
                            {/*  </Link>*/}
                            {/*</li>*/}
                        </ul>

                        {/* Divider */}
                        <hr className="my-4 md:min-w-full" />
                        {/* Heading */}
                        <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                            Quản lý nội dung
                        </h6>
                        {/* Navigation */}

                        <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                            {/* <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf(
                                            "/home-page"
                                        ) !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="/home-page"
                                >
                                    <HomeIcon
                                        className={
                                            "fas fa-table mr-2 text-sm " +
                                            (window.location.href.indexOf(
                                                "/home-page"
                                            ) !== -1
                                                ? "opacity-75"
                                                : "text-blueGray-300")
                                        }
                                    />
                                    Trang chủ
                                </Link>
                            </li> */}

                            <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf(
                                            "/who-we-are"
                                        ) !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="/who-we-are"
                                >
                                    <AccessibilityIcon className="fas fa-clipboard-list text-blueGray-300 mr-2 text-sm" />
                                    Về chúng tôi
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf(
                                            "/technical-capability"
                                        ) !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="/technical-capability"
                                >
                                    <MilitaryTechIcon className="fas fa-clipboard-list text-blueGray-300 mr-2 text-sm" />
                                    Công nghệ
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf(
                                            "/our-story"
                                        ) !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="/our-story"
                                >
                                    <AutoStoriesIcon className="fas fa-clipboard-list text-blueGray-300 mr-2 text-sm" />
                                    Câu chuyện của chúng tôi
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf(
                                            "/projects"
                                        ) !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="/projects"
                                >
                                    <AccountTreeIcon className="fas fa-clipboard-list text-blueGray-300 mr-2 text-sm" />
                                    Dự án
                                </Link>
                            </li>

                            <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf(
                                            "/clients"
                                        ) !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="clients"
                                >
                                    <AssignmentIndIcon className="fas fa-clipboard-list text-blueGray-300 mr-2 text-sm" />
                                    Khách hàng
                                </Link>
                            </li>
                        </ul>

                        {/* Divider */}
                        <hr className="my-4 md:min-w-full" />
                        {/* Heading */}
                        <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                            Tin tức
                        </h6>
                        {/* Navigation */}

                        <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                            <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf(
                                            "/published-news"
                                        ) !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="/published-news"
                                >
                                    <i className="fas fa-newspaper text-blueGray-400 mr-2 text-sm"></i>{" "}
                                    Tin tức đã xuất bản
                                </Link>
                            </li>

                            {/* <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf(
                                            "/draft-news"
                                        ) !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="/draft-news"
                                >
                                    <i className="fas fa-user-circle text-blueGray-400 mr-2 text-sm"></i>{" "}
                                    Tin nháp
                                </Link>
                            </li> */}
                        </ul>

                        {/*/!* Divider *!/*/}
                        {/*<hr className="my-4 md:min-w-full" />*/}
                        {/*/!* Heading *!/*/}
                        {/*<h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">*/}
                        {/*  Ảnh*/}
                        {/*</h6>*/}
                        {/*/!* Navigation *!/*/}

                        {/*<ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">*/}
                        {/*  <li className="items-center">*/}
                        {/*    <Link*/}
                        {/*      className={*/}
                        {/*        "text-xs uppercase py-3 font-bold block " +*/}
                        {/*        (window.location.href.indexOf("/folders-management") !== -1*/}
                        {/*          ? "text-lightGreen-700 hover:text-lightBlue-600"*/}
                        {/*          : "text-blueGray-700 hover:text-blueGray-500")*/}
                        {/*      }*/}
                        {/*      to="/folders-management"*/}
                        {/*    >*/}
                        {/*      <i className="fas fa-newspaper text-blueGray-400 mr-2 text-sm"></i>{" "}*/}
                        {/*      Quản trị thư mục*/}
                        {/*    </Link>*/}
                        {/*  </li>*/}
                        {/*</ul>*/}

                        <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                            Tuyển dụng
                        </h6>
                        {/* Navigation */}

                        <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                            <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf(
                                            "/publish-post"
                                        ) !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="/publish-post"
                                >
                                    <i className="fas fa-newspaper text-blueGray-400 mr-2 text-sm"></i>{" "}
                                    Bài đăng đã xuất bản
                                </Link>
                            </li>
                        </ul>

                        <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                            Chia sẻ
                        </h6>
                        {/* Navigation */}
                        <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                            <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf(
                                            "/submission"
                                        ) !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="/submission"
                                >
                                    <i className="fas fa-newspaper text-blueGray-400 mr-2 text-sm"></i>{" "}
                                    Phản hồi
                                </Link>
                            </li>
                        </ul>

                        <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                            Quỹ
                        </h6>
                        <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                            {/* <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf(
                                            "/budget-management"
                                        ) !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="/budget-management"
                                >
                                    <i className="fas fa-newspaper text-blueGray-400 mr-2 text-sm"></i>{" "}
                                    Quản lý thu chi
                                </Link>
                            </li>
                            <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf(
                                            "/budget-categories"
                                        ) !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="/budget-categories"
                                >
                                    <i className="fas fa-newspaper text-blueGray-400 mr-2 text-sm"></i>{" "}
                                    Định nghĩa loại thu chi
                                </Link>
                            </li> */}
                            <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf(
                                            "/monthly-budget"
                                        ) !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="/monthly-budget"
                                >
                                    <i className="fas fa-newspaper text-blueGray-400 mr-2 text-sm"></i>{" "}
                                    Quỹ thành viên tháng
                                </Link>
                            </li>
                            <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf("/plan-revenue-expenses") !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="/plan-revenue-expenses"
                                >
                                    <i className="fas fa-newspaper text-blueGray-400 mr-2 text-sm"></i>{" "}
                                    Bảng dự kiến thu chi
                                </Link>
                            </li>
                            <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf(
                                            "/thu-chi"
                                        ) !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="/thu-chi"
                                >
                                    <i className="fas fa-newspaper text-blueGray-400 mr-2 text-sm"></i>{" "}
                                    Bảng tổng hợp thu chi
                                </Link>
                            </li>
                        </ul>

                        {/* <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
              Thu Chi
            </h6> */}
                        {/* Thu chi */}
                        {/* <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
              <li className="items-center">
                <Link
                  className={
                    "text-xs uppercase py-3 font-bold block " +
                    (window.location.href.indexOf("/thu-chi") !== -1
                      ? "text-lightGreen-700 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/thu-chi"
                >
                  <i className="fas fa-newspaper text-blueGray-400 mr-2 text-sm"></i>{" "}
                  Bảng tổng hợp thu chi
                </Link>
              </li>
            </ul> */}

                        <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
                            Template
                        </h6>
                        {/* Template */}
                        <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                            <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf(
                                            "/template-management"
                                        ) !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="/template-management"
                                >
                                    <i className="fas fa-newspaper text-blueGray-400 mr-2 text-sm"></i>{" "}
                                    Chat Template
                                </Link>
                            </li>
                        </ul>
                        <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
                            <li className="items-center">
                                <Link
                                    className={
                                        "text-xs uppercase py-3 font-bold block " +
                                        (window.location.href.indexOf(
                                            "/image-template-management"
                                        ) !== -1
                                            ? "text-lightGreen-700 hover:text-lightBlue-600"
                                            : "text-blueGray-700 hover:text-blueGray-500")
                                    }
                                    to="/image-template-management"
                                >
                                    <i className="fas fa-newspaper text-blueGray-400 mr-2 text-sm"></i>{" "}
                                    Image Template
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default Sidebar;
