import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "relative",
        top: theme.spacing(1),
        right: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

function CustomCloseButton(props: any) {
    const classes = useStyles();

    return (
        <IconButton className={classes.closeButton} onClick={props.onClick}>
            <CloseIcon />
        </IconButton>
    );
}

export default CustomCloseButton;
