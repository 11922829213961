/* eslint-disable jsx-a11y/img-redundant-alt */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button, Checkbox,
  FormHelperText,
  Pagination,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/Close";
import { changeLoading } from "App/Features/Animation";
import { useAppDispatch } from "App/Store";
import { AxiosError } from "axios";
import BoxContent from "components/common/BoxContent";
import BoxLightBlue from "components/common/BoxLightBule";
import TextFieldComponent from "components/common/Input/TextField";
import CardTable from "components/common/ListTable/CardTable";
import SelectComponent from "components/common/Select/defeault";
import { contentApi } from "Datasource/content";
import { headerTableMembersPage } from "Datasource/dataCommonTable";
import { UserApi } from "Datasource/users";
import dayjs from "dayjs";
import useMessage from "Hooks/useMessage";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import DatePickerComponent from "components/common/DatePicker";
import ModalComponent from "components/common/Modal";
import ModalDelete from "components/common/Modal/ModalDelete";
import useDebounce from "Hooks/useDebounce";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomSearch from "../../../components/common/Tables/CustomSearchInput/CustomSearch";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";
import SendIcon from '@material-ui/icons/Send';
import {eventListeners} from "@popperjs/core";

interface Props {}

interface FormSubmit {
  name?: string;
  email?: string;
  photo?: any;
  phoneNumber?: string;
  dateOfBirth?: Date;
  onBoardDate?: Date;
  displayName?: string;
  team?: string;
  position?: string;
  employeeId?: string;
  parentId?: string;
  order?: string | number;
  project?: any;
  status?: any;
  newbie?: boolean;
}

const listStatus = [
  { label: "Đang hoạt động", value: "published" },
  { label: "Ngừng hoạt động", value: "unpublish" },
];

const listOption = [
  {label: "True", value: true},
  {label: "False", value: false}
];

function UserPage(props: Props) {
  const {} = props;
  const dispatch = useAppDispatch();
  const { openMessage } = useMessage();
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("add");
  const [isOpenDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [listUser, setListUser] = useState([]);
  const [searched, setSearched] = useState<string>("");
  const [dataMemberList, setDataMemberList] = useState<any>();
  const [pageNumberMemberList, setPageNumberMemberList] = useState<number>(1);
  const [listMember, setListMember] = useState<any[]>([]);
  const [listTeam, setListTeam] = useState<any[]>([]);
  const [imageFile, setImageFile] = useState<any>();
  const [nameImage, setNameImage] = useState<string>("");
  const [imageUpload, setImageUpload] = useState<any>(null);
  const [selectedUserID, setSelectedUserID] = useState<string>("");
  const [listNewbie, setListNewbie] = useState<any[]>([]);
  const [showNewbieButton, setShowNewbieButton] = useState<boolean>(false);
  const debouncedSearch = useDebounce(searched);

  useEffect(() => {
    getListUserByApi();
    getUsersList(pageNumberMemberList, debouncedSearch, "", "");
  }, [pageNumberMemberList, debouncedSearch]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (fs: File[]) => {
      setImageFile(fs[0]);
      setNameImage(fs[0].name);
      setImageUpload(URL.createObjectURL(fs[0]));
    },
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
    },
  });

  const handleResetAll = () => {
    setImageFile("");
    setNameImage("");
    setSelectedUserID("");
    setSearched("");
    setModalType("addUser");
    setOpenModal(false);
    setOpenDeleteModal(false);
    setImageUpload(null);
    setShowNewbieButton(false);
    dispatch(changeLoading(false));
    reset();
  };

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormSubmit>();

  const renderModal = useCallback(() => {
    return (
      <>
        <Box
          className="flex justify-start flex-row w-full items-start
        "
        >
          <Box className="flex items-start flex-col justify-start h-1/2 w-1/3">
            {imageUpload && (
              <div className="w-full relative">
                <Button
                  style={{ right: 0, top: 0, position: "absolute" }}
                  onClick={() => {
                    setImageUpload("");
                    setNameImage("");
                  }}
                >
                  <CloseIcon style={{ color: "black" }} />
                </Button>
                <img
                  loading="lazy"
                  src={imageUpload}
                  className="rounded"
                  alt="image"
                />
              </div>
            )}
            <Box className="boxUpload">
              <div className="w-full relative" style={{ padding: "0px 10px" }}>
                <div
                  className={`box-border p-2 mb-4`}
                  style={{
                    position: "relative",
                    border: "1px dotted rgba(59, 130, 246, 0.5)",
                  }}
                  {...getRootProps({
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  })}
                >
                  {isDragActive ? (
                    <div>
                      <Typography className="text-drop-file fw-600">
                        DRAG <span className="tractor">&</span> DROP
                      </Typography>
                    </div>
                  ) : (
                    <>
                      <div
                        className="box-content-drop"
                        {...getRootProps({
                          onClick: (e) => {
                            e.stopPropagation();
                          },
                        })}
                      >
                        <label
                          htmlFor="select_file"
                          className="text-f20 text-bold"
                        >
                          <span className="box-blue">Chọn ảnh</span> hoặc kéo &
                          thả ảnh ở đây
                        </label>
                        <input
                          {...getInputProps()}
                          type="file"
                          name="file"
                          id="select_file"
                          className="inputFile"
                          placeholder="Chọn ảnh"
                          style={{ display: "none" }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Box>
            <div
              className=""
              style={{ width: "100%", padding: "0 17px 0 10px", margin: "0px 0px 20px" }}
            >
              <Controller
                name="status"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <SelectComponent
                      value={value}
                      onChange={onChange}
                      data={listStatus}
                      className=""
                      placeholder="Status"
                    />
                    {errors.status && (
                      <FormHelperText
                        id="component-error-text"
                        className="text-red-500"
                      >
                        Đây là 1 trường bắt buộc
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </div>
            <div
                className=""
                style={{ width: "100%", padding: "0 17px 0 10px", margin: "0px 0px 20px" }}
            >
              <Controller
                  name="newbie"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                      <>
                        <SelectComponent
                            value={value}
                            onChange={onChange}
                            data={listOption}
                            className=""
                            placeholder="Newbie"
                        />
                        {errors.status && (
                            <FormHelperText
                                id="component-error-text"
                                className="text-red-500"
                            >
                              Đây là 1 trường bắt buộc
                            </FormHelperText>
                        )}
                      </>
                  )}
              />
            </div>
          </Box>
          <Box className="w-1/3 flex justify-start flex-col px-3">
            <Controller
              name="name"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextFieldComponent
                  label="Họ và tên"
                  value={value}
                  onChangeValue={onChange}
                  margin={"0 0 10px 0"}
                  warningText={errors.name && "Đây là 1 trường bắt buộc"}
                />
              )}
            />
            <Controller
              name="displayName"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextFieldComponent
                  label="Tên hiển thị"
                  value={value}
                  onChangeValue={onChange}
                  warningText={errors.displayName && "Đây là 1 trường bắt buộc"}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextFieldComponent
                  label="VMO Email"
                  value={value}
                  onChangeValue={onChange}
                  warningText={errors.email && "Đây là 1 trường bắt buộc"}
                />
              )}
            />
            <Controller
              name="phoneNumber"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextFieldComponent
                  label="Số điện thoại"
                  value={value}
                  onChangeValue={onChange}
                  warningText={errors.phoneNumber && "Đây là 1 trường bắt buộc"}
                />
              )}
            />
            <Controller
              name="dateOfBirth"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Box className="w-full mb-3">
                  <Typography className="text-blueGray-400">
                    Ngày sinh
                  </Typography>
                  <DatePickerComponent
                    onChangeDate={onChange}
                    startDate={value}
                    className="w-full"
                  />
                  {errors.dateOfBirth && (
                    <FormHelperText
                      id="component-error-text"
                      className="text-red-500"
                    >
                      Đây là 1 trường bắt buộc
                    </FormHelperText>
                  )}
                </Box>
              )}
            />
            <Controller
              name="onBoardDate"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <Box className="w-full">
                  <Typography className="text-blueGray-400">
                    Onboard Date
                  </Typography>
                  <DatePickerComponent
                    onChangeDate={onChange}
                    startDate={value}
                    className="w-full"
                  />
                  {errors.onBoardDate && (
                    <FormHelperText
                      id="component-error-text"
                      className="text-red-500"
                    >
                      Đây là 1 trường bắt buộc
                    </FormHelperText>
                  )}
                </Box>
              )}
            />
          </Box>
          <Box className="w-1/3 flex justify-start flex-col">
            <Controller
              name="position"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextFieldComponent
                  label="Vị trí"
                  value={value}
                  onChangeValue={onChange}
                  className="mb-5"
                  margin={"0 0 10px 0"}
                  warningText={errors.position && "Đây là 1 trường bắt buộc"}
                />
              )}
            />
            <div className="">
              <Controller
                name="team"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <SelectComponent
                      value={value}
                      onChange={onChange}
                      data={listTeam}
                      className="mb-3"
                      placeholder="Team"
                    />
                    {errors.team && (
                      <FormHelperText
                        id="component-error-text"
                        className="text-red-500"
                      >
                        Đây là 1 trường bắt buộc
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </div>
            <Controller
              name="employeeId"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextFieldComponent
                  label="Mã nhân viên"
                  className="mb-5"
                  value={value}
                  onChangeValue={onChange}
                  warningText={errors.employeeId && "Đây là 1 trường bắt buộc"}
                />
              )}
            />
            <div className="">
              <Controller
                name="parentId"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <SelectComponent
                      value={value}
                      onChange={onChange}
                      data={listMember}
                      className="mb-6"
                      placeholder="Manager"
                    />
                    {errors.parentId && (
                      <FormHelperText
                        id="component-error-text"
                        className="text-red-500"
                      >
                        Đây là 1 trường bắt buộc
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </div>
            <Controller
              name="order"
              control={control}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextFieldComponent
                  label="Order"
                  value={value}
                  onChangeValue={onChange}
                  type="number"
                  warningText={errors.order && "Đây là 1 trường bắt buộc"}
                />
              )}
            />

            <div className="">
              <Controller
                name="project"
                control={control}
                rules={{ required: false }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <CreatableSelect
                      isMulti
                      onChange={onChange}
                      value={value}
                      className="mt-8"
                      placeholder="Project"
                    />
                    {errors.project && (
                      <FormHelperText
                        id="component-error-text"
                        className="text-red-500"
                      >
                        Đây là 1 trường bắt buộc
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </div>
          </Box>
        </Box>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listUser, imageUpload, listMember, listTeam, errors]);

  const getListUserByApi = async () => {
    try {
      const { data } = await UserApi.getMember();
      setListUser(data.data?.META_DATA);
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const getUsersList = async (numberPage: number, keyword: any, orderBy: string, sortBy: string) => {
    try {
      dispatch(changeLoading(true));
      const param: any = {
        page: numberPage,
        orderBy,
        sortBy
      };
      const {
        data: { META_DATA },
      } = await contentApi.getMembers(param, keyword);
      setPageNumberMemberList(META_DATA?.currentPage);
      setDataMemberList(META_DATA);
      dispatch(changeLoading(false));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(changeLoading(false));
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const getListTeam = async () => {
    try {
      const { data } = await contentApi.getAllTeam();
      let newParams: any = [];
      data.META_DATA.map((item: any, index: any) => {
        const param = {
          value: item._id,
          label: item.name,
        };
        newParams.push(param);
      });

      setListTeam(newParams);
      dispatch(changeLoading(false));
    } catch (error) {
      dispatch(changeLoading(false));
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const getMembersList = async () => {
    try {
      const {
        data: { META_DATA },
      } = await contentApi.getAllMember();
      let newData: any = [];
      META_DATA.map((item: any, index: any) => {
        let newItem = {
          value: item._id,
          label: item.name,
          position: item.position,
        };
        newData.push(newItem);
      });
      setListMember(newData);
      dispatch(changeLoading(false));
      return;
    } catch (error) {
      dispatch(changeLoading(false));
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const cancelSearch = () => {
    setSearched("");
  };

  useEffect(() => {
    dispatch(changeLoading(true));
    // getListRoles();
    getMembersList();
    getListTeam();
  }, []);

  const setValueEditingUser = useCallback(
    (item: any) => {
      console.log(item)
      const newStatus = listStatus.map((itemStatus: any) =>
        itemStatus.value === item.status ? itemStatus : null
      );

      const newParentId = listMember.filter((member: any) => {
        if (member.value === item?.parentId?._id) {
          return {
            ...member,
          };
        }
      });

      const newTeam = listTeam.filter((team: any) => {
        if (team?.value === item?.team[0]?._id) {
          return {
            ...team,
          };
        }
      });
      const newDateOfBirth: any = new Date(item.dateOfBirth || new Date());
      const newOnBoardDate: any = new Date(item.onBoardDate || new Date());

      setValue("name", item.name);
      setValue("email", item.email);
      setValue("phoneNumber", item.phoneNumber);
      setImageUpload(!_.isNil(item.photo) ? item.photo.link : "");
      setNameImage(!_.isNil(item?.photo) ? item?.photo.id : null);
      setValue("position", item.position.trim());
      setValue("order", parseInt(item.order));
      setValue("displayName", item.displayName);
      setValue("parentId", newParentId[0]);
      setValue("team", item?.team ? newTeam[0] : null);
      setValue("employeeId", item.employeeId);
      setValue("status", newStatus);
      setValue("dateOfBirth", newDateOfBirth);
      setValue("onBoardDate", newOnBoardDate);
      setValue("newbie", item.newbie);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listMember, listTeam]
  );

  const handlerDeleteUser = async (id: string) => {
    try {
      const res = await contentApi.hanlderDeleteMember(id).then((res: any) => {
        openMessage({
          variant: `${res.data.STATUS_CODE === 204 ? "success" : "error"}`,
          message: `${
            res.data.STATUS_CODE === 204
              ? "Xóa thành công"
              : res.data.MESSAGE_CLIENT
          }`,
        });
        if (res.data.STATUS_CODE === 204) {
          setOpenDeleteModal(false);
          setSelectedUserID("");
          getUsersList(1, "", "", "");
        }
      });
      setPageNumberMemberList(1);
      return res;
    } catch (error) {
      const { response } = error as AxiosError;
      const { ...errorObject }: any = response;
      openMessage({
        variant: "error",
        message: errorObject?.data.message,
      });
    }
  };

  const onSubmit = async (data: any) => {
    try {
      if (modalType === "add") {
        dispatch(changeLoading(true));
        let newListProject: any = [];
        let newTeam: any[] = [];
        newTeam.push(data?.team.value);

        if (!_.isNil(data.project)) {
          data.project.map((item: any, index: number) => {
            newListProject.push(item.value);
          });
        }

        const newParam = {
          name: data?.name.trim(),
          email: data?.email.trim(),
          phoneNumber: data?.phoneNumber.trim(),
          onBoardDate: data?.onBoardDate,
          dateOfBirth: data?.dateOfBirth,
          photo: imageFile?.size > 0 ? imageFile : null,
          employeeId: data?.employeeId.trim(),
          displayName: data?.displayName.trim(),
          team: newTeam,
          parentId: data?.parentId.value.trim(),
          position: data?.position.trim(),
          order: parseInt(data?.order),
          projects: newListProject,
          status: data.status.value,
          newbie: data.newbie.value
        };

        const res = await contentApi
          .handlerCreateUser(newParam)
          .then((res: any) => {
            openMessage({
              variant: `${res.data.STATUS_CODE === 201 ? "success" : "error"}`,
              message: `${
                res.data.STATUS_CODE === 201
                  ? "Thêm mới dữ liệu thành công"
                  : res.data.MESSAGE_CLIENT
              }`,
            });
            if (res.data.STATUS_CODE === 201) {
              getUsersList(1, "", 'desc', 'createdAt');
              handleResetAll();
            }
          });
        handleResetAll();
        return res;
      } else if (modalType === "edit") {
        dispatch(changeLoading(true));
        let newParam;
        if (imageFile) {
          newParam = {
            name: data?.name,
            email: data?.email,
            phoneNumber: data?.phoneNumber,
            onBoardDate: data?.onBoardDate,
            dateOfBirth: data?.dateOfBirth,
            photo: imageFile.size > 0 ? imageFile : null,
            employeeId: data?.employeeId,
            displayName: data?.displayName,
            team: data?.team.value,
            parentId: data?.parentId.value,
            position: data?.position,
            order: data?.order,
            newbie: data?.newbie
          };
        } else {
          newParam = {
            name: data?.name,
            email: data?.email,
            phoneNumber: data?.phoneNumber,
            onBoardDate: data?.onBoardDate,
            dateOfBirth: data?.dateOfBirth,
            employeeId: data?.employeeId,
            displayName: data?.displayName,
            team: data?.team.value,
            parentId: data?.parentId.value,
            position: data?.position,
            order: data?.order,
            newbie: data?.newbie
          };
        }
        const res = await contentApi
          .handlerEditUser(newParam, selectedUserID)
          .then((res: any) => {
            openMessage({
              variant: `${res.data.STATUS_CODE === 200 ? "success" : "error"}`,
              message: `${
                res.data.STATUS_CODE === 200
                  ? "Sửa thành viên thành công"
                  : res.data.MESSAGE_CLIENT
              }`,
            });
            if (res.data.STATUS_CODE === 200) {
              getUsersList(1, "", "", "");
              handleResetAll();
            }
          });
        return res;
      }
    } catch (error) {
      dispatch(changeLoading(false));
      if (error instanceof Error) {

        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const handleChangeRow = () => {
    setOpenModal(true);
  };

  const handleCheckNewbie = (data: any, checked: boolean) => {
    if (data.newbie && checked) {
      if (!listNewbie.includes(data._id)) {
        listNewbie.push(data._id);
        setListNewbie([...listNewbie]);
      }
    } else {
      var index = listNewbie.indexOf(data._id);
      if (index > -1) {
        listNewbie.splice(index, 1);
        setListNewbie([...listNewbie]);
      }
    }
  };

  useEffect(() => {
    if (listNewbie.length > 0) {
      setShowNewbieButton(true);
    } else {
      setShowNewbieButton(false);
    }
  }, [listNewbie]);

  const handleSendChatbot = async () => {
    try {
      const params = {
        memberIds: listNewbie,
      };
      const res = await contentApi.postNewbie(params).then((res: any) => {
        /*openMessage({
          variant: `${res.data.STATUS_CODE === 201 ? "success" : "error"}`,
          message: `${
              res.data.STATUS_CODE === 201
                  ? "Gửi thông báo chatbot thành công"
                  : res.data.MESSAGE_CLIENT
          }`,
        });*/
        console.log("res", res);
        if (res.data.STATUS_CODE === 201) {
          /*getTeamList(1, "createdAt", "desc");
          handlerResetAll();*/
        }
      });
      return res;
    } catch (error) {
    dispatch(changeLoading(false));
    if (error instanceof Error) {
      const { response } = error as AxiosError;
      const { ...errorObject }: any = response;
      openMessage({
        variant: "error",
        message: errorObject?.data.message,
      });
    }
  }
  };

  return (
    <>
      <BoxLightBlue />
      <BoxContent>
        <Accordion>
          <AccordionSummary
            className="filter-div"
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className="font-bold">Bộ lọc</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CustomSearch
              listUser={listUser}
              searched={searched}
              requestSearch={setSearched}
              cancelSearch={cancelSearch}
            />
          </AccordionDetails>
        </Accordion>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className="my-4"
        >
          <Typography>Danh sách nhân viên</Typography>
          <Button
              variant="contained"
              startIcon={<SendIcon />}
              onClick={handleSendChatbot}
              style={{ marginLeft: 'auto', visibility: showNewbieButton ? 'visible' : 'hidden' }}
          >
            Gửi thông báo
          </Button>
          <Button
            className="m-button m-button-success"
            type="button"
            onClick={() => {
              setOpenModal(true);
              setModalType("add");
            }}
          >
            <AddIcon /> Thêm mới
          </Button>
        </Box>
        <CardTable
          dataHeaderTable={headerTableMembersPage}
          className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white overflow-x-auto"
          dataBodyTable={dataMemberList?.items}
          handleAction={handleChangeRow}
          currentPage={dataMemberList?.currentPage}
        >
          <>
            {dataMemberList &&
              dataMemberList.items?.map((item: any, index: any) => (
                <tr key={index}>
                  <td>
                    <Checkbox onChange={(e) => handleCheckNewbie(item, e.target.checked)}/>
                  </td>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left ">
                    <Typography className={"ml-3 font-bold text-blueGray-600"}>
                      {item?.employeeId}
                    </Typography>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left ">
                    <Typography>{item?.name}</Typography>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left ">
                    {item.photo ? (
                      <Avatar alt={item?.photo._id} src={item.photo.link} />
                    ) : (
                      <img
                        src={item?.photo?.link}
                        width={50}
                        height={50}
                        alt="avatar.png"
                      />
                    )}
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4">
                    <Typography>{item?.email}</Typography>
                  </td>
                  {/*<td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4">
                    <Typography>{item?.phoneNumber}</Typography>
                  </td>*/}
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4">
                    <Typography>
                      {dayjs(`${item?.dateOfBirth}`).format("DD-MM-YYYY")}
                    </Typography>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4">
                    <Typography>
                      {dayjs(`${item?.onBoardDate}`).format("DD-MM-YYYY")}
                    </Typography>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4">
                    <Typography>{item?.position}</Typography>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4">
                    <Typography>{item.newbie ? "True" : "False"}</Typography>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4">
                    <Typography>
                      {item?.status === "active" ? (
                        <div className="flex items-center">
                          <div
                            className="rounded-full bg-emerald-400"
                            style={{ width: 10, height: 10 }}
                          ></div>{" "}
                          <span className="ml-1" style={{ fontSize: "13px" }}>
                            Đang hoạt động
                          </span>
                        </div>
                      ) : (
                        <div className="flex items-center">
                          <div
                            className="rounded-full bg-slate-300"
                            style={{ width: 10, height: 10 }}
                          ></div>{" "}
                          <span className="ml-1" style={{ fontSize: "13px" }}>
                            Ngừng hoạt động
                          </span>
                        </div>
                      )}
                    </Typography>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left ">
                    <Box display={"flex"}>
                      <IconButton
                        aria-label="delete"
                        className="m-button-rounded m-button-warning mr-5"
                        onClick={() => {
                          setModalType("edit");
                          setValueEditingUser(item);
                          setOpenModal(true);
                          setSelectedUserID(item._id);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        className="m-button-rounded m-button-danger"
                        onClick={() => {
                          setOpenDeleteModal(true);
                          setSelectedUserID(item._id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </td>
                </tr>
              ))}
          </>
        </CardTable>
        <Pagination
          style={{ justifyContent: "center", display: "flex" }}
          count={dataMemberList?.lastPage}
          onChange={(e, numberPage: number) => {
            setPageNumberMemberList(numberPage);
          }}
          color="primary"
        />
      </BoxContent>
      <ModalComponent
        classNameContent="bg-white"
        open={isOpenModal}
        handleClose={() => {
          handleResetAll();
        }}
        modalType={modalType}
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 1000,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <>
          <Typography>
            {modalType === "add" ? "Thêm mới nhân viên" : "Sửa nhân viên"}
          </Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id="content-form-a"
            className="mt-5"
          >
            <Box overflow={"visible"} maxHeight={600} className="p-3">
              {renderModal()}
            </Box>
          </form>
        </>
      </ModalComponent>
      <ModalDelete
        classNameContent="bg-white"
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        isOpenModal={isOpenDeleteModal}
        onCloseModal={() => {
          handleResetAll();
        }}
        onClickConfirm={() => {
          handlerDeleteUser(selectedUserID);
          setSelectedUserID("");
        }}
      />
    </>
  );
}

export default UserPage;
