import { Box } from "@mui/system";
import React from "react";
import Select from "react-select";
interface Props {
  data: any[];
  onChange?: any;
  value?: any;
  className?: string;
  placeholder?: string;
  isMulti?: boolean;
  customStyle?: any;
  disabled?: boolean;
  onInputChange?: any;
  // label?: string;
  isSearchable?: boolean;
  isClearable?: boolean;
}

function SelectComponent(props: Props) {
  const {
    data,
    value,
    onChange,
    className,
    placeholder,
    isMulti = false,
    customStyle,
    disabled,
    onInputChange,
    // label,
    isSearchable,
    isClearable = true
  } = props;

  return (
    <Box zIndex={9999}>
      <Select
        className={className}
        options={data}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        isMulti={isMulti}
        isDisabled={disabled}
        onInputChange={onInputChange}
        isSearchable={isSearchable}
        isClearable={isClearable}
        styles={{
          control: (styles) => ({
            ...styles,
            ...customStyle,
            backgroundColor: "#fff",
            zIndex: 0,
          }),
          dropdownIndicator: (styles) => ({ ...styles, zIndex: 99999 }),
        }}
      />
    </Box>
  );
}

export default SelectComponent;
