/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import JSCookie from "js-cookie";

const dashboardAxios = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10000,
});


const requestHandler = (request: any) => {
  const authToken = JSCookie.get("token");
  request.headers = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "X-Requested-With",
    Authorization: `Bearer ${authToken}`,
  };
  return request;
};

const redirectToLogin = (response: any) => {
  if (response.status === 401 || response.status === 403) {
    JSCookie.remove("token");
    window.location = <any>"/login";
  }
};

const responseHandler = (response: any) => {
  return response.data;
};

const errorHandler = (error: any) => {
  const { response } = error;
  redirectToLogin(response);
  return Promise.reject(error);
};

dashboardAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

dashboardAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);
const postFormData = (url: string, params: any) => {
  return dashboardAxios.post(`/${url}`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const putFormData = (url: string, params: any) => {
  return dashboardAxios.put(`/${url}`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const patchFormData = (url: string, params: any) => {
  return dashboardAxios.patch(`/${url}`, params, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};


// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: dashboardAxios.get,
  post: dashboardAxios.post,
  put: dashboardAxios.put,
  patch: dashboardAxios.patch,
  delete: dashboardAxios.delete,
  patchFormData: patchFormData,
  postFormData: postFormData,
  putFormData: putFormData,
  editFormData: dashboardAxios.patch,
};
