/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import dashboardAxios from "Api/Interceptors";
import _ from "lodash";

interface Teams {
  page: number;
  pageSize: number;
  sortBy: string;
  orderBy: string;
}

interface newBudgetDescriptionParams {
  description: string;
  type: any;
  category: string;
}

interface newTransactionParams {
  date: Date;
  type: string;
  category: string;
  description: string;
  plan: number;
  actual: number;
}

interface updateTransactionParams {
  date: Date;
  description: string;
  plan: number;
  actual: number;
}

interface updateRevenueTransactionParams {
  month: string;
  balance: number | null;
  companyFund: number | null;
  totalExpense: number | null;
}

interface createRevenueTransactionParams {
  month: string;
  companyFund: number | null;
  totalExpense: number | null;
}

const formData = (params: any) => {
  const {
    name,
    photo,
    position,
    team,
    parentId,
    order,
    status,
    email,
    onBoardDate,
    dateOfBirth,
    employeeId,
    displayName,
  } = params;
  const dataArray = new FormData();
  dataArray.append("name", name);
  dataArray.append("position", position);
  dataArray.append("team", team);
  dataArray.append("parentId", parentId);
  dataArray.append("order", order);
  dataArray.append("status", status);
  dataArray.append("email", email);
  dataArray.append("onBoardDate", onBoardDate);
  dataArray.append("dateOfBirth", dateOfBirth);
  dataArray.append("employeeId", employeeId);
  dataArray.append("displayName", displayName);
  if (photo && photo.size > 0) {
    dataArray.append("photo", photo);
  }
  return dataArray;
};

const formDataUser = (params: any) => {
  const {
    name,
    email,
    phoneNumber,
    onBoardDate,
    dateOfBirth,
    photo,
    projects,
    team,
    displayName,
    order,
    position,
    employeeId,
    parentId,
    status,
  } = params;
  const dataArray = new FormData();
  if (name) {
    dataArray.append("name", name);
  }
  if (status) {
    dataArray.append("status", status);
  }
  if (email) {
    dataArray.append("email", email);
  }
  if (phoneNumber) {
    dataArray.append("phoneNumber", phoneNumber);
  }
  if (onBoardDate) {
    dataArray.append("onBoardDate", onBoardDate);
  }
  if (dateOfBirth) {
    dataArray.append("dateOfBirth", dateOfBirth);
  }
  if (projects) {
    dataArray.append("projects", projects);
  }
  if (team) {
    dataArray.append("team", team);
  }
  if (displayName) {
    dataArray.append("displayName", displayName);
  }
  if (order) {
    dataArray.append("order", order);
  }
  if (position) {
    dataArray.append("position", position);
  }
  if (employeeId) {
    dataArray.append("employeeId", employeeId);
  }
  if (parentId) {
    dataArray.append("parentId", parentId);
  }
  if (photo && photo.size > 0) {
    dataArray.append("photo", photo);
  }

  return dataArray;
};

const formDataAboutUs = (params: any) => {
  const { content, duPhoto } = params;
  const dataArray = new FormData();
  dataArray.append("content", content);
  if (duPhoto.size > 0) {
    dataArray.append("duPhoto", duPhoto);
  }
  return dataArray;
};

const formDataProject = (params: any) => {
  const { name, description, order, photo, memberList, status } = params;
  const dataArray = new FormData();
  if (photo && photo?.size > 0) {
    dataArray.append("photo", photo);
  }
  dataArray.append("name", name);
  dataArray.append("description", description);
  dataArray.append("order", order);
  dataArray.append("memberList", memberList);
  dataArray.append("status", status);
  return dataArray;
};

const formDataClients = (params: any) => {
  const { name, order, photo, status, group } = params;
  const dataArray = new FormData();
  if (photo && photo?.size > 0) {
    dataArray.append("photo", photo);
  }
  if (group) {
    dataArray.append("group", group);
  }
  dataArray.append("name", name);
  dataArray.append("order", order);
  dataArray.append("status", status);
  return dataArray;
};

const formDataRole = (params: any) => {
  const { name, description, permissions } = params;
  const dataArray = new FormData();
  dataArray.append("name", name);
  dataArray.append("description", description);
  dataArray.append("permissions", permissions);
  return dataArray;
};

export const contentApi = {
  getContent: () => dashboardAxios.get("/about-us"),
  postContent: (params: any) => dashboardAxios.post("/about-us", params),
  patchContent: (params: any) =>
    dashboardAxios.patchFormData("/about-us", params),
  restoreContent: (id: any) =>
    dashboardAxios.delete(`/v1/about-us/restore/${id}`),
  deleteContent: (id: any) => dashboardAxios.delete(`/v1/about-us`),

  // Todo: Who we are:
  getTeams: (param: Teams) => {
    return dashboardAxios.get(
      `/public/teams/paginate?page=${param.page ? param.page : "1"}&pageSize=${
        param.pageSize ? param.pageSize : 10
      }&orderBy=${!_.isEmpty(param.orderBy) ? param.orderBy: 'asc'}&sortBy=${!_.isEmpty(param.sortBy) ? param.sortBy : '_id'}`
    );
  },
  getMembers: (param: Teams, keyword?: any) =>{
    const newbie = keyword.newbie && keyword.newbie.value != null ? '&newbie=' + keyword.newbie.value : "";
    return dashboardAxios.get(
        `/members/paginate?page=${param.page ? param.page : "1"}&pageSize=${
            param.pageSize ? param.pageSize : 10
        }&name=${keyword.name ? keyword.name : ""}&employeeId=${keyword.id ? keyword.id : ""}` + newbie +`&orderBy=${!_.isEmpty(param.orderBy) ? param.orderBy: 'asc'}&sortBy=${!_.isEmpty(param.sortBy) ? param.sortBy : '_id'}`
    );
  },
  postNewbie: (params: any) => dashboardAxios.post("/chat-bot/newbies", params),
  // TODO: Teams
  postNewTeam: (params: any) => dashboardAxios.post("/teams", params),
  getAllTeam: () => dashboardAxios.get("/teams"),
  deleteTeam: (id: string) => dashboardAxios.delete(`/teams/${id}`),
  handlerEditTeam: (params: any, id: string) =>
    dashboardAxios.editFormData(`/teams/${id}`, params),

  // TODO: Members
  getAllMember: () => dashboardAxios.get("/members"),
  handlerCreateMember: (params: any) => {
    const formDataField = formData(params);
    const response = dashboardAxios.postFormData(`members`, formDataField);
    return response;
  },
  handlerEditMember: (params: any, id: string) => {
    const formDataField = formData(params);
    const response = dashboardAxios.patchFormData(
      `members/${id}`,
      formDataField
    );
    return response;
  },
  hanlderDeleteMember: (id: string) => dashboardAxios.delete(`/members/${id}`),

  // TODO: Budget Category

  getCategoryByType: (type: string) =>
    dashboardAxios.get(`revenues-expenses/categories-by-type?type=${type}`),

  getBudgetCategory: (param: any) =>
    dashboardAxios.get(
      `revenues-expenses/all-categories?currentPage=${
        param.page ? param.page : "1"
      }&name=${param.name ? param.name : ""}&description=${
        param.description ? param.description : ""
      }`
    ),

  postNewBudgetCategory: (params: any) =>
    dashboardAxios.post("revenues-expenses/new-category", params),

  handleEditBudgetCategory: (params: any, id: string) => {
    const response = dashboardAxios.patch(
      `revenues-expenses/categories/${id}`,
      params
    );
    return response;
  },

  handleDeleteBudgetCategory: (id: string) =>
    dashboardAxios.delete(`revenues-expenses/categories/${id}`),

  getAllCategoryDescription: () =>
    dashboardAxios.get(`revenues-expenses/descriptions/all`),

  getAllCategoryDescriptionIdByYear: (year: number) =>
    dashboardAxios.get(`revenues-expenses/year/descriptions?year=${year}`),

  // TODO: Users
  handlerCreateUser: (params: any) => {
    const formDataField = formDataUser(params);
    const response = dashboardAxios.postFormData(`members`, formDataField);
    return response;
  },

  handlerEditUser: (params: any, id: string) => {
    const formDataField = formDataUser(params);
    const response = dashboardAxios.patchFormData(
      `members/${id}`,
      formDataField
    );
    return response;
  },

  // TODO: Budget Management

  getAllDescription: (description: string, type: string, category: string) =>
    dashboardAxios.get(
      `revenues-expenses/descriptions/suggestion?description=${description}&type=${type}&category=${category}`
    ),

  createNewDescription: (params: newBudgetDescriptionParams) =>
    dashboardAxios.post(`revenues-expenses/descriptions/new`, params),

  createNewTransaction: (params: newTransactionParams) =>
    dashboardAxios.post(`revenues-expenses/new-transaction`, params),

  handleEditBudgetTransaction: (id: string, params: updateTransactionParams) =>
    dashboardAxios.patch(`revenues-expenses/update-transaction/${id}`, params),

  getAllBudgetTransaction: (startDate: string, endDate?: string) =>
    dashboardAxios.get(
      `revenues-expenses/month?startDate=${startDate}${
        endDate && `&endDate=${endDate}`
      }`
    ),

  handleDeleteBudgetTransaction: (id: string) =>
    dashboardAxios.delete(`revenues-expenses/transaction/${id}`),

  getMonthChartData: (year: number, month?: number) =>
    dashboardAxios.get(
      `revenues-expenses/charts/month?year=${year}&month=${month}`
    ),
  getAllRevenueData: (year: number) =>
      dashboardAxios.get(
          `revenues-expenses?year=${year}`
      ),

  handleLockRevenueTransaction: (transactionId: string) =>
      dashboardAxios.patch(`revenues-expenses/lock/${transactionId}`),

  handleUpdateRevenueTransaction: (transactionId: string, params: updateRevenueTransactionParams) =>
      dashboardAxios.patch(`revenues-expenses/${transactionId}`, params),

  handleCreateRevenueTransaction: (params: createRevenueTransactionParams) =>
      dashboardAxios.post(`revenues-expenses`, params),

  getYearChartData: (year: number) =>
    dashboardAxios.get(`revenues-expenses/charts/year?year=${year}`),

  // TODO: Roles
  getRoles: (param: Teams) =>
    dashboardAxios.get(
      `admin/roles/paginate?page=${param.page ? param.page : "1"}&pageSize=${
        param.pageSize ? param.pageSize : 10
      }&orderBy=${!_.isEmpty(param.orderBy) ? param.orderBy: 'asc'}&sortBy=${!_.isEmpty(param.sortBy) ? param.sortBy : '_id'}`
    ),
  getAllRoles: () => dashboardAxios.get("admin/roles"),
  handlerDeleteRole: (id: string) => dashboardAxios.delete(`admin/roles/${id}`),
  postNewRole: (params: any) => dashboardAxios.post("admin/roles", params),
  handlerEditRole: (id: string, params: any) => {
    const formDataField = formDataRole(params);
    const response = dashboardAxios.patchFormData(
      `admin/roles/${id}`,
      formDataField
    );
    return response;
  },

  // TODO: Monthly Budget
  getAllMonthlyBudget: (
    page: number,
    month: string,
    employeeName?: string,
    employeeId?: string,
    status?: string
  ) =>
    dashboardAxios.get(
      `/budget-management/list-members?page=${
        page ? page : 1
      }&pageSize=10&title=${month}${
        employeeName ? `&employeeName=${employeeName}` : ""
      }${employeeId ? `&employeeId=${employeeId}` : ""}${
        status ? `&status=${status}` : ""
      }`
    ),

  getAllRecordedMonth: () => dashboardAxios.get(`/budget-management/titles`),

  handleChangeMonthlyBudgetStatus: (params: any, id: string) => {
    const response = dashboardAxios.patch(
      `budget-management/status/${id}`,
      params
    );
    return response;
  },

  handleChangeMonthlyBudgetAmount: (params: any, id: string) => {
    const response = dashboardAxios.patch(
      `budget-management/transaction/${id}`,
      params
    );
    return response;
  },

  handleSyncTotalMonthlyBudget: () => {
    const response = dashboardAxios.patch(`budget-management/sync/total`);
    return response;
  },

  handleLockMonthlyBudget: (params: any) =>
    dashboardAxios.post(`budget-management/lock`, params),

  // TODO: Permissions
  getAllPermissions: () => dashboardAxios.get("admin/permissions"),

  //TODO: Tech Stack
  handlerCreateTechStackDetail: (params: any) => {
    const formDataField = formDataClients(params);
    const response = dashboardAxios.postFormData(
      `admin/tech-stack`,
      formDataField
    );
    return response;
  },
  handlerEditTechStackDetail: (id: string, params: any) => {
    const formDataField = formDataClients(params);
    const response = dashboardAxios.patchFormData(
      `admin/tech-stack/${id}`,
      formDataField
    );
    return response;
  },
  hanlderDeleteTechStack: (id: string) =>
    dashboardAxios.delete(`/admin/tech-stack/${id}`),

  // TODO: Public Tech Stack Group
  getAllTechStack: (param: any) =>
    dashboardAxios.get(
      `/admin/tech-stack-group/paginate?page=${param.page ? param.page : 1}&pageSize=10&orderBy=${!_.isEmpty(param.orderBy) ? param.orderBy: 'asc'}&sortBy=${!_.isEmpty(param.sortBy) ? param.sortBy : '_id'}`
    ),
  handlerCreateTechStack: (param: any) =>
    dashboardAxios.post("/admin/tech-stack-group", param),
  dedetedTechStack: (id: string) =>
    dashboardAxios.delete(`/admin/tech-stack-group/${id}`),
  deletedDetailTechStack: (id: string) =>
    dashboardAxios.delete(`/admin/tech-stack/${id}`),
  hanlderEditTechStack: (params: any, id: string) =>
    dashboardAxios.editFormData(`/admin/tech-stack-group/${id}`, params),
  getDetailTechStack: (id: string) =>
    dashboardAxios.get(`/admin/tech-stack-group/${id}/tech-stacks`),

  // TODO: About Us
  createOurStory: (param: any) => {
    const formDataField = formDataAboutUs(param);
    const response = dashboardAxios.postFormData(`about-us`, formDataField);
    return response;
  },

  //TODO: Projects
  getAllProject: (param: any) =>
    dashboardAxios.get(
      `/projects/paginate?page=${param.page ? param.page : 1}&pageSize=${param.pageSize ? param.pageSize : 10}&orderBy=${!_.isEmpty(param.orderBy) ? param.orderBy: 'asc'}&sortBy=${!_.isEmpty(param.sortBy) ? param.sortBy : '_id'}`
    ),
  handlerCreateProject: (params: any) => {
    const formDataField = formDataProject(params);
    const response = dashboardAxios.postFormData(`projects`, formDataField);
    return response;
  },
  hanlderUpdateProject: (params: any, id: string) => {
    const formDataField = formDataProject(params);
    const response = dashboardAxios.patchFormData(
      `projects/${id}`,
      formDataField
    );
    return response;
  },
  dedetedProject: (id: string) => dashboardAxios.delete(`/projects/${id}`),

  // TODO: Clients
  getAllClients: (param: any) =>
    dashboardAxios.get(`/clients/paginate?page=${param.page ? param.page : 1}&pageSize=10&orderBy=${!_.isEmpty(param.orderBy) ? param.orderBy: 'asc'}&sortBy=${!_.isEmpty(param.sortBy) ? param.sortBy : '_id'}`),
  dedetedClient: (id: string) => dashboardAxios.delete(`/clients/${id}`),
  handlerCreateClient: (params: any) => {
    const formDataField = formDataProject(params);
    const response = dashboardAxios.postFormData(`clients`, formDataField);
    return response;
  },
  hanlderUpdateClients: (params: any, id: string) => {
    const formDataField = formDataClients(params);
    const response = dashboardAxios.patchFormData(
      `clients/${id}`,
      formDataField
    );
    return response;
  },

  // TODO: Plan Revenue Expenses

  getMonthsOfYear: (date: string) => dashboardAxios.get(`/revenues-expenses/plan/titles?year=${date}`),
  getMonthData: (date: string, monthParam: number) => dashboardAxios.get(`/revenues-expenses/plan/?year=${date}&month=${monthParam}`),
  updatePlan: (updatedRecord: any) => dashboardAxios.patch(`/revenues-expenses/plan/${updatedRecord._id}`, updatedRecord),
  createPlan: (item: any) => dashboardAxios.post('/revenues-expenses/plan/transaction', item),
  deletePlan: (transactionId: string) => dashboardAxios.delete(`/revenues-expenses/plan/${transactionId}`),
  createMonth: (request: any) => dashboardAxios.post('/revenues-expenses/plan/month', request),
  lockMonth: (request: any) => dashboardAxios.patch('/revenues-expenses/plan/lock', request),
};
