import React from "react";

interface Props {
  children?: any;
}

function BoxContent(props: Props) {
  const { children } = props;

  return (
    <div className="px-4 md:px-10 mx-auto w-full  relative">
      <div className="flex flex-wrap mt-4">
        <div className="w-full mb-12 px-4">{children}</div>
      </div>
    </div>
  );
}

export default BoxContent;
