import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { LoginResponse } from "TS/App/Auth";
import jscookie from 'js-cookie';
interface InitalStateTypes {
  accessToken?: string | Record<string, unknown> | null;
  refreshToken?: string | Record<string, unknown> | null;
}

const initialState: InitalStateTypes = {
  accessToken: null,
  refreshToken: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, data: PayloadAction<LoginResponse>) => {
      const payload: any = data.payload;
      state.accessToken = payload.accessToken;
      if (payload.accessToken) {
        jscookie.set("token", payload.accessToken);
      }
    },
    logout: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      jscookie.remove("token");
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
