/* eslint-disable jsx-a11y/img-redundant-alt */
import { Button, Pagination, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AxiosError } from "axios";
import BoxContent from "components/common/BoxContent";
import BoxLightBlue from "components/common/BoxLightBule";
import TextFieldComponent from "components/common/Input/TextField";
import CardTable from "components/common/ListTable/CardTable";
import ModalComponent from "components/common/Modal";
import ModalDelete from "components/common/Modal/ModalDelete";
import SelectComponent from "components/common/Select/defeault";
import StatusSelect from "components/common/Select/status";
import { contentApi } from "Datasource/content";
import { headerTableProject } from "Datasource/dataCommonTable";
import useMessage from "Hooks/useMessage";
import { parseInt } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {}

function Projects(props: Props) {
  const {} = props;
  const { openMessage } = useMessage();
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  // const [descriptionProject, setDescriptionProject] = useState<string>("");
  const [imageUpload, setImageUpload] = useState<any>(null);
  const [nameImage, setNameImage] = useState<string>("");
  const [listProject, setListProject] = useState<any>();
  const [pageCurrent, setPage] = useState<number>(1);
  const [isOpenModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [itemChosse, setItemChosse] = useState<any>();
  const [file, setFile] = useState<any>();
  const [listMember, setListMember] = useState<any>();
  const [modalType, setModalType] = useState<string>("add");

  useEffect(() => {
    getAllProjectByApi({page: pageCurrent});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCurrent]);

  useEffect(() => {
    getAllMemberByApi();
  }, []);

  const {
    register,
    reset,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<any>();

  const onSubmit = async (data: any) => {
    const newPramTeam: string[] = [];
    let newData: any;
    data.memberList.map((team: any) => {
      newPramTeam.push(team.value);
    });
    if (file) {
      newData = {
        ...data,
        memberList: newPramTeam,
        photo: file,
        order: parseInt(data.order),
      };
    } else {
      newData = {
        ...data,
        memberList: newPramTeam,
        order: parseInt(data.order),
      };
    }
    if (modalType === "edit") {
      handlerEditProject(newData, itemChosse._id);
    } else {
      handlerCreateProject(newData);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (fs: File[]) => {
      setNameImage(fs[0].name);
      setImageUpload(URL.createObjectURL(fs[0]));
      setFile(fs[0]);
    },
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
    },
  });

  const getAllMemberByApi = async () => {
    try {
      const {
        data: { META_DATA },
      }: any = await contentApi.getAllMember();
      const newList: any = [];
      META_DATA.map((item: any) => {
        let newItem = {
          value: item._id,
          label: item.name,
        };
        newList.push(newItem);
      });
      setListMember(newList);
      return;
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const getAllProjectByApi = async (param: any) => {
    try {
      const { data } = await contentApi.getAllProject(param);
      const { META_DATA } = data;
      setListProject(META_DATA);
      return;
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const handlerDeleteProject = async (id: string) => {
    try {
      const res = await contentApi.dedetedProject(id).then((res: any) => {
        openMessage({
          variant: `${res.data.STATUS_CODE === 204 ? "success" : "error"}`,
          message: `${
            res.data.STATUS_CODE === 204
              ? "Xóa thành công"
              : res.data.MESSAGE_CLIENT
          }`,
        });
        if (res.data.STATUS_CODE === 204) {
          getAllProjectByApi({
            page: 1
          });
          setOpenModalDelete(false);
          setItemChosse(null);
          // onReset();
        }
      });
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const handlerCreateProject = async (params: any) => {
    try {
      const res = await contentApi
        .handlerCreateProject(params)
        .then((res: any) => {
          openMessage({
            variant: `${res.data.STATUS_CODE === 201 ? "success" : "error"}`,
            message: `${
              res.data.STATUS_CODE === 201
                ? "Thêm mới thành công"
                : res.data.MESSAGE_CLIENT
            }`,
          });
          if (res.data.STATUS_CODE === 201) {
            getAllProjectByApi({
              page: 1,
              sortBy: "createdAt",
              orderBy: "desc"
            });
            onReset();
          }
        });
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const handlerEditProject = async (params: any, id: string) => {
    try {
      const res = await contentApi
        .hanlderUpdateProject(params, id)
        .then((res: any) => {
          openMessage({
            variant: `${res.data.STATUS_CODE === 200 ? "success" : "error"}`,
            message: `${
              res.data.STATUS_CODE === 200
                ? "Cập nhật thành công"
                : res.data.MESSAGE_CLIENT
            }`,
          });
          if (res.data.STATUS_CODE === 200) {
            getAllProjectByApi({
              page: 1
            });
            onReset();
          }
        });
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const onReset = () => {
    setOpenModal(false);
    reset();
    setFile(null);
    setNameImage("");
    setImageUpload(null);
  };

  const setValueEditForm = (item: any) => {
    const paramMemberList: any[] = [];
    item.memberList.map((member: any) => {
      let newPram = {
        value: member._id,
        label: member.name,
      };
      paramMemberList.push(newPram);
    });
    setValue("name", `${item.name}`);
    setValue("description", `${item.description}`);
    setValue("order", `${parseInt(item.order)}`);
    setValue("status", `${item.status}`);
    setValue("memberList", paramMemberList);
    setNameImage(item?.photo?._id);
    setImageUpload(item?.photo?.link);
  };
  return (
    <>
      <BoxLightBlue />
      <BoxContent>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className="my-4"
        >
          <Typography>Danh sách dự án</Typography>
          <Button
              className="m-button m-button-success"
              type="button"
              onClick={() => {
                setOpenModal(true);
                setModalType("add")
              }}
          >
            <AddIcon/> Thêm mới
          </Button>
        </Box>
        <CardTable
          dataHeaderTable={headerTableProject}
          className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white overflow-x-auto"
          dataBodyTable={listProject?.items}
        >
          <>
            {listProject &&
              listProject.items?.map((item: any, index: any) => (
                <tr key={index}>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                    <Typography className={"ml-3 font-bold text-blueGray-600"}>
                      {listProject.currentPage <= 1
                        ? index + 1
                        : (listProject.currentPage - 1) * 10 + index + 1}
                    </Typography>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                    <Typography>{item.name}</Typography>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4">
                    <Typography>{item.description}</Typography>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                    {/* <Typography>{item.photo.url}</Typography> */}
                    <img
                      src={item?.photo?.link}
                      alt={item?.photo?._id}
                      width={200}
                    />
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center">
                    <Box display={"flex"}>
                      <IconButton aria-label="delete" className='m-button-rounded m-button-warning mr-5' onClick={() => {
                        setModalType("edit");
                        setOpenModal(true);
                        setValueEditForm(item);
                        setItemChosse(item);
                      }}>
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="delete" className='m-button-rounded m-button-danger' onClick={() => {
                        setOpenModalDelete(true);
                        setItemChosse(item);
                      }}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </td>
                </tr>
              ))}
          </>
        </CardTable>
        <Pagination
          style={{ justifyContent: "center", display: "flex" }}
          count={listProject?.lastPage}
          onChange={(e, numberPage: number) => {
            setPage(numberPage);
          }}
          color="primary"
        />
      </BoxContent>
      <ModalComponent
        classNameContent="bg-white"
        open={isOpenModal}
        handleClose={() => {
          setOpenModal(false);
          setModalType("add");
          onReset();
        }}
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        modalType={modalType}
      >
        <>
          <Typography>{modalType === "add" ? "Thêm mới dự án" : "Cập nhật dự án"}</Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id="content-form-a"
            className="mt-5"
          >
            <Box overflow={"auto"} maxHeight={`calc(100vh - 300px)`}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextFieldComponent
                    label="Tên dự án"
                    value={value}
                    onChangeValue={onChange}
                    warningText={errors.name && "Đây là một trường bắt buộc"}
                  />
                )}
              />
              <Controller
                name="description"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextFieldComponent
                    label="Miêu tả dự án"
                    value={value}
                    onChangeValue={onChange}
                    warningText={errors.description && "Đây là một trường bắt buộc"}
                    multiline={true}
                    minRows={4}
                  />
                )}
              />

              <Controller
                name="order"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextFieldComponent
                    label="Order"
                    value={value}
                    onChangeValue={onChange}
                    warningText={errors.order && "Đây là một trường bắt buộc"}
                    type={"number"}
                  />
                )}
              />
              <Controller
                name="status"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <StatusSelect value={value} onChange={onChange} warningText={errors.status && "Đây là một trường bắt buộc"}/>
                )}
              />

              <Controller
                name="memberList"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <SelectComponent
                    value={value}
                    onChange={onChange}
                    data={listMember}
                    className="mb-4"
                    placeholder="Danh sách nhân viên dự án"
                    isMulti={true}
                  />
                )}
              />

              <TextFieldComponent
                label="Ảnh dự án"
                value={nameImage}
                // onChangeValue={(value: string) => setOrderNewTeam(value)}
                helperText="Vui lòng chỉ updload file dạng png, jpg, gif"
              />
              <Box className="boxUpload mb-4">
                <div
                  className="w-full relative"
                  style={{ padding: "0px 10px" }}
                >
                  <div
                    className={`box-border p-2`}
                    style={{
                      position: "relative",
                      border: "1px dotted rgba(59, 130, 246, 0.5)",
                    }}
                    {...getRootProps({
                      onClick: (e) => {
                        e.stopPropagation();
                      },
                    })}
                  >
                    {isDragActive ? (
                      <div>
                        <Typography className="text-drop-file fw-600">
                          DRAG <span className="tractor">&</span> DROP
                        </Typography>
                      </div>
                    ) : (
                      <>
                        <div
                          className="box-content-drop"
                          {...getRootProps({
                            onClick: (e) => {
                              e.stopPropagation();
                            },
                          })}
                        >
                          <label
                            htmlFor="select_file"
                            className="text-f20 text-bold"
                          >
                            <span className="box-blue">Chọn ảnh</span> hoặc kéo &
                            thả nó ở đây
                          </label>
                          <input
                            {...getInputProps()}
                            type="file"
                            name="file"
                            id="select_file"
                            className="inputFile"
                            placeholder="Chọn ảnh"
                            style={{ display: "none" }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {nameImage && (
                  <div className="w-full relative">
                    <img
                      loading="lazy"
                      src={imageUpload}
                      className="rounded"
                      alt="image"
                      style={{ width: 300, height: 300, margin: "20px auto" }}
                    />
                  </div>
                )}
              </Box>
            </Box>
          </form>
        </>
      </ModalComponent>
      <ModalDelete
          classNameContent="bg-white"
          styleComponent={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
          isOpenModal={isOpenModalDelete}
          onCloseModal={() => setOpenModalDelete(false)}
          onClickConfirm={() => handlerDeleteProject(itemChosse._id)}
      />
    </>
  );
}

export default Projects;
