import logo from "./logo.svg";
import "./App.css";
import BoxLightBlue from "components/common/BoxLightBule";

function App() {
  return (
    <div className="App">
     <BoxLightBlue></BoxLightBlue>
    </div>
  );
}

export default App;
