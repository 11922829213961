import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import { Box } from "@mui/system";
import "./index.scss";
interface Props {
  value: string;
  onChange?: (e: SelectChangeEvent) => void;
  disabled?: boolean;
  type?: string;
}

function StatusSelect(props: any) {
  const { value, onChange, disabled, warningText, type } = props;
  let selectComponent = <FormControl className="w-full">
    <InputLabel id="demo-simple-select-helper-label" className="w-full">
      Trạng thái
    </InputLabel>
    <Select
        disabled={disabled}
        {...props}
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={value}
        label="Trạng thái"
        onChange={onChange}
        size="small"
        className="select-custom"
    >
      <MenuItem value={"published"}>Đã xuất bản</MenuItem>
      <MenuItem value={"draft"}>Bản nháp</MenuItem>
      <MenuItem value={"archived"}>Đã lưu trữ</MenuItem>
    </Select>
  </FormControl>;

  switch (type) {
    case 'typeNews':
      selectComponent =
          <FormControl className="w-full">
            <InputLabel id="demo-simple-select-helper-label" className="w-full">
              Loại tin
            </InputLabel>
            <Select
                disabled={disabled}
                {...props}
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={value}
                label="Loại Tin"
                onChange={onChange}
                size="small"
                className="select-custom"
            >
              <MenuItem value={"news"}>Tin tức</MenuItem>
              <MenuItem value={"sharing"}>Chia sẻ</MenuItem>
            </Select>
          </FormControl>;
  }

  return (
    <>
      {selectComponent}
      <Box sx={{ marginBottom: 1, minHeight: 10 }}>
        {warningText && (
          <FormHelperText id="component-error-text" className="text-red-500">
            {warningText}
          </FormHelperText>
        )}
      </Box>
    </>
  );
}

export default StatusSelect;
