/* eslint-disable jsx-a11y/img-redundant-alt */
import { Button, Pagination, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { AxiosError } from "axios";
import BoxContent from "components/common/BoxContent";
import BoxLightBlue from "components/common/BoxLightBule";
import TextFieldComponent from "components/common/Input/TextField";
import CardTable from "components/common/ListTable/CardTable";
import ModalComponent from "components/common/Modal";
import ModalDelete from "components/common/Modal/ModalDelete";
import StatusSelect from "components/common/Select/status";
import { contentApi } from "Datasource/content";
import { headerTableClient } from "Datasource/dataCommonTable";
import useMessage from "Hooks/useMessage";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";

interface Props {}

function Clients(props: Props) {
  const {} = props;
  const { openMessage } = useMessage();
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [imageUpload, setImageUpload] = useState<any>(null);
  const [nameImage, setNameImage] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [listClient, setListClient] = useState<any>();
  const [file, setFile] = useState<any>();
  // const [nameAction, setNameAction] = useState<string>("");
  const [modalType, setModalType] = useState<string>("add");
  const [itemChosse, setItemChosse] = useState<any>();
  const [isOpenModalDelete, setOpenModalDelete] = useState<boolean>(false);

  useEffect(() => {
    getAllClients({
      page: pageNumber
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const {
    register,
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<any>();

  const onSubmit = async (data: any) => {
    const newPramTeam: string[] = [];
    let newData: any;
    if (file) {
      newData = {
        ...data,
        memberList: newPramTeam,
        photo: file,
        order: parseInt(data.order),
      };
    } else {
      newData = {
        ...data,
        memberList: newPramTeam,
        order: parseInt(data.order),
      };
    }
    if (modalType === "edit") {
      handlerEditClient(newData, itemChosse._id);
    } else {
      handlerCreateClient(newData);
    }
    reset();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (fs: File[]) => {
      setNameImage(fs[0].name);
      setImageUpload(URL.createObjectURL(fs[0]));
      setFile(fs[0]);
    },
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
    },
  });

  const handlerEditClient = async (params: any, id: string) => {
    try {
      const res = await contentApi
        .hanlderUpdateClients(params, id)
        .then((res: any) => {
          openMessage({
            variant: `${res.data.STATUS_CODE === 200 ? "success" : "error"}`,
            message: `${
              res.data.STATUS_CODE === 200
                ? "Cập nhật dữ liệu thành công"
                : res.data.MESSAGE_CLIENT
            }`,
          });
          if (res.data.STATUS_CODE === 200) {
            getAllClients({
              page: 1
            });
            onReset();
          }
        });
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const handlerCreateClient = async (params: any) => {
    try {
      const res = await contentApi
        .handlerCreateClient(params)
        .then((res: any) => {
          openMessage({
            variant: `${res.data.STATUS_CODE === 201 ? "success" : "error"}`,
            message: `${
              res.data.STATUS_CODE === 201
                ? "Thêm mới dữ liệu thành công"
                : res.data.MESSAGE_CLIENT
            }`,
          });
          if (res.data.STATUS_CODE === 201) {
            getAllClients({
              page : 1,
              sortBy: "createdAt",
              orderBy: "desc"
            });
            onReset();
          }
        });
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const getAllClients = async (param: any) => {
    try {
      const { data } = await contentApi.getAllClients(param);
      const { META_DATA } = data;
      setListClient(META_DATA);
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const setValueEditForm = (item: any) => {
    setValue("name", `${item.name}`);
    setValue("order", `${parseInt(item.order)}`);
    setValue("status", `${item.status}`);
    setNameImage(item.photo?._id);
    setImageUpload(item.photo?.link);
  };

  const handlerDeleteClient = async (id: string) => {
    try {
      const res = await contentApi.dedetedClient(id).then((res: any) => {
        openMessage({
          variant: `${res.data.STATUS_CODE === 204 ? "success" : "error"}`,
          message: `${
            res.data.STATUS_CODE === 204
              ? "Xóa thành công"
              : res.data.MESSAGE_CLIENT
          }`,
        });
        if (res.data.STATUS_CODE === 204) {
          getAllClients({
            page: 1
          });
          setOpenModalDelete(false);
          setItemChosse(null);
          // onReset();
        }
      });
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const onReset = () => {
    setOpenModal(false);
    reset();
    setFile(null);
    setNameImage("");
    setImageUpload(null);
  };

  return (
    <>
      <BoxLightBlue />
      <BoxContent>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className="my-4"
        >
          <Typography>Danh sách khách hàng</Typography>
          <Button
            className="m-button m-button-success"
            type="button"
            onClick={() => {
              setOpenModal(true);
              setModalType("add");
            }}
          >
            <AddIcon /> Thêm mới
          </Button>
        </Box>

        <CardTable
          dataHeaderTable={headerTableClient}
          className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white overflow-x-auto"
          dataBodyTable={listClient?.items}
        >
          {listClient &&
            listClient.items?.map((item: any, index: any) => (
              <tr key={index}>
                <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                  <Typography className={"ml-3 font-bold text-blueGray-600"}>
                    {index + 1}
                  </Typography>
                </th>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                  <Typography>{item.name}</Typography>
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                  <img
                    src={item.photo?.link}
                    alt={item.photo?._id}
                    width={100}
                    className="m-auto"
                  />
                </td>
                <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center w-100">
                  <Box display={"flex"}>
                    <IconButton
                      aria-label="delete"
                      className="m-button-rounded m-button-warning mr-5"
                      onClick={() => {
                        setModalType("edit");
                        setOpenModal(true);
                        setValueEditForm(item);
                        setItemChosse(item);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      className="m-button-rounded m-button-danger"
                      onClick={() => {
                        setOpenModalDelete(true);
                        setItemChosse(item);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </td>
              </tr>
            ))}
        </CardTable>
        <Pagination
          style={{ justifyContent: "center", display: "flex" }}
          count={listClient?.lastPage}
          onChange={(e, numberPage: number) => {
            setPageNumber(numberPage);
          }}
        />
      </BoxContent>
      <ModalComponent
        classNameContent="bg-white"
        open={isOpenModal}
        handleClose={() => {
          setOpenModal(false);
          reset();
          setNameImage("");
          setImageUpload("");
        }}
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        modalType={modalType}
      >
        <>
          <Typography>
            {modalType === "add"
              ? "Thêm mới khách hàng"
              : "Cập nhật khách hàng"}
          </Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id="content-form-a"
            className="mt-5"
          >
            <Box overflow={"auto"} maxHeight={`calc(100vh - 300px)`}>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextFieldComponent
                    label="Tên khách hàng"
                    value={value}
                    onChangeValue={onChange}
                    warningText={errors.name && "Đây là 1 trường bắt buộc"}
                  />
                )}
              />
              <Controller
                name="order"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextFieldComponent
                    label="Xếp hạng"
                    value={value}
                    onChangeValue={onChange}
                    warningText={errors.order && "Đây là 1 trường bắt buộc"}
                    type={"number"}
                  />
                )}
              />
              <Controller
                name="status"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <StatusSelect value={value} onChange={onChange} />
                )}
              />
              <TextFieldComponent
                label="Ảnh khách hàng"
                value={nameImage}
                // onChangeValue={(value: string) => setOrderNewTeam(value)}
                helperText="Vui lòng chỉ updload file dạng png, jpg, gif"
              />
              <Box className="boxUpload mb-4">
                <div
                  className="w-full relative"
                  style={{ padding: "0px 10px" }}
                >
                  <div
                    className={`box-border p-2`}
                    style={{
                      position: "relative",
                      border: "1px dotted rgba(59, 130, 246, 0.5)",
                    }}
                    {...getRootProps({
                      onClick: (e) => {
                        e.stopPropagation();
                      },
                    })}
                  >
                    {isDragActive ? (
                      <div>
                        <Typography className="text-drop-file fw-600">
                          DRAG <span className="tractor">&</span> DROP
                        </Typography>
                      </div>
                    ) : (
                      <>
                        <div
                          className="box-content-drop"
                          {...getRootProps({
                            onClick: (e) => {
                              e.stopPropagation();
                            },
                          })}
                        >
                          <label
                            htmlFor="select_file"
                            className="text-f20 text-bold"
                          >
                            <span className="box-blue">Chọn ảnh</span> hoặc kéo
                            & thả nó ở đây
                          </label>
                          <input
                            {...getInputProps()}
                            type="file"
                            name="file"
                            id="select_file"
                            className="inputFile"
                            placeholder="Chọn ảnh"
                            style={{ display: "none" }}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {nameImage && (
                  <div className="w-full relative">
                    <img
                      loading="lazy"
                      src={imageUpload}
                      className="rounded"
                      alt="image"
                      style={{ width: 300, height: 300, margin: "20px auto" }}
                    />
                  </div>
                )}
              </Box>
            </Box>
          </form>
        </>
      </ModalComponent>
      <ModalDelete
        classNameContent="bg-white"
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        isOpenModal={isOpenModalDelete}
        onCloseModal={() => setOpenModalDelete(false)}
        onClickConfirm={() => handlerDeleteClient(itemChosse._id)}
      />
    </>
  );
}

export default Clients;
