/*eslint no-unused-vars: 0*/
/*eslint @typescript-eslint/explicit-module-boundary-types: 0*/
import { AxiosError } from 'axios';
import { MessageProps } from 'components/common/Message';
import { useCallback } from 'react';
import { toast } from 'react-toastify';

export default function useMessage() {
  const openMessage = useCallback(
    (data: MessageProps) => toast(data.message, { type: data.variant }),
    [],
  );

  const openMessageError = useCallback((errors: any) => {
    const { response }: any = errors as AxiosError;
    toast(response?.data.message[0], { type: 'error' });
  }, []);

  return { openMessage, openMessageError };
}
