import React, { useCallback, useEffect, useState } from "react";
import { Box } from "@mui/system";
import {
    Button,
    FormHelperText,
    Pagination,
    Typography,
    IconButton,
    Accordion,
    AccordionSummary,
} from "@mui/material";
import { AxiosError } from "axios";
import { changeLoading } from "App/Features/Animation";
import { useAppDispatch } from "App/Store";
import BoxLightBlue from "components/common/BoxLightBule";
import BoxContent from "components/common/BoxContent";
import useMessage from "Hooks/useMessage";
import { Controller, useForm } from "react-hook-form";
import { contentApi } from "Datasource/content";
import { headerTableMonthlyBudgetPage } from "Datasource/dataCommonTable";
import CardTable from "components/common/ListTable/CardTable";
import EditIcon from "@mui/icons-material/Edit";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import RefreshIcon from "@mui/icons-material/Refresh";
import SelectComponent from "components/common/Select/defeault";
import TextFieldComponent from "components/common/Input/TextField";
import formatMoney from "utilities/formatMoney";
import { utils, writeFileXLSX } from "xlsx";
import dayjs from "dayjs";
import Chip from "@mui/material/Chip";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import LockIcon from "@mui/icons-material/Lock";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { AccordionDetails, MenuItem } from "@material-ui/core";
import useDebounce from "Hooks/useDebounce";
import ModalDelete from "components/common/Modal/ModalDelete";
interface Props {}

enum MonthlyBudgetStatus {
    APPROVED = "approved",
    PROCESSING = "processing",
    ALL = "",
}

enum ExportSheetProperty {
    NAME = "Họ và tên",
    EMPLOYEE_ID = "Mã nhân viên",
    AMOUNT = "Số tiền",
    STATUS = "Trạng thái",
    NOTE = "Ghi chú",
    DATE = "Ngày đóng",
    CREATED_AT = "Ngày tạo",
    UPDATED_AT = "Ngày cập nhật",
    TRANSACTION_ID = "Mã giao dịch",
}

const mapStatusToColor = {
    [MonthlyBudgetStatus.APPROVED]: "bg-green-600",
    [MonthlyBudgetStatus.PROCESSING]: "bg-blue-600",
    [MonthlyBudgetStatus.ALL]: "bg-red-600",
};

const mapStatus = {
    [MonthlyBudgetStatus.APPROVED]: "Đã đóng",
    [MonthlyBudgetStatus.PROCESSING]: "Đang xử lý",
    [MonthlyBudgetStatus.ALL]: "Tất cả",
};

interface FormSubmit {
    note?: string;
    newStatus?: MonthlyBudgetStatus;
    amount?: number;
}

const listStatus = [
    { label: "Đã đóng", value: MonthlyBudgetStatus.APPROVED },
    { label: "Đang xử lý", value: MonthlyBudgetStatus.PROCESSING },
    { label: "Tất cả", value: MonthlyBudgetStatus.ALL },
];

function MonthlyBudgetPage(props: Props) {
    const {} = props;
    const dispatch = useAppDispatch();
    const { openMessage } = useMessage();
    const [page, setPage] = useState<number>(1);
    const [dataMemberList, setDataMemberList] = useState<any>();
    const [isOpenLockModal, setIsOpenLockModal] = useState<boolean>(false);
    const [editingStatus, setEditingStatus] = useState<boolean>(false);
    const [editingAmount, setEditingAmount] = useState<boolean>(false);
    const [editingNote, setEditingNote] = useState<boolean>(false);
    const [editingId, setEditingId] = useState<string>("");
    const [transactionId, setTransactionId] = useState<string>("");
    const [monthOptions, setMonthOptions] = useState<any>([]);
    const [selectedMonth, setSelectedMonth] = useState<string>(
        `${dayjs().month() + 1}/${dayjs().year()}`
    );
    const [filterName, setFilterName] = useState<string>("");
    const [filterID, setFilterID] = useState<string>("");
    const [filterStatus, setFilterStatus] = useState<string>("");

    const debounceName = useDebounce(filterName);
    const debounceID = useDebounce(filterID);

    useEffect(() => {
        dispatch(changeLoading(true));
        getListMonthlyBudget(
            page,
            selectedMonth,
            debounceName,
            debounceID,
            filterStatus
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, selectedMonth, debounceName, debounceID, filterStatus]);

    const getListMonthlyBudget = async (
        page: number = 1,
        currentMonth: string = `${dayjs().month() + 1}/${dayjs().year()}`,
        name?: string,
        id?: string,
        status?: string
    ) => {
        try {
            const {
                data: { META_DATA },
            } = await contentApi.getAllMonthlyBudget(
                page,
                currentMonth,
                name,
                id,
                status
            );
            setDataMemberList(META_DATA);
            dispatch(changeLoading(false));
        } catch (error) {
            dispatch(changeLoading(false));
            if (error instanceof Error) {
                const { response } = error as AxiosError;
                const { ...errorObject }: any = response;
                openMessage({
                    variant: "error",
                    message: errorObject?.data.message,
                });
            }
        }
    };

    const syncTotalMonthlyBudget = async () => {
        try {
            const response = await contentApi
                .handleSyncTotalMonthlyBudget()
                .then((res: any) => {
                    openMessage({
                        variant: `${
                            res.data.STATUS_CODE === 200 ? "success" : "error"
                        }`,
                        message: `${
                            res.data.STATUS_CODE === 200
                                ? "Tổng hợp dữ liệu thành công"
                                : res.data.MESSAGE_CLIENT
                        }`,
                    });
                    if (res.data.STATUS_CODE === 200) {
                        getListMonthlyBudget(
                            page,
                            selectedMonth,
                            debounceName,
                            debounceID,
                            filterStatus
                        );
                    }
                    handlerResetAll();
                });
            return response;
        } catch (error) {
            dispatch(changeLoading(false));
            if (error instanceof Error) {
                const { response } = error as AxiosError;
                const { ...errorObject }: any = response;
                openMessage({
                    variant: "error",
                    message: errorObject?.data.message,
                });
            }
        }
    };

    const handlerResetAll = () => {
        dispatch(changeLoading(false));
        reset();
        setEditingStatus(false);
        setEditingAmount(false);
        setEditingId("");
        setTransactionId("");
    };

    const {
        register,
        handleSubmit,
        control,
        reset,
        setValue,
        formState: { errors },
    } = useForm<FormSubmit>();

    const onSubmitAmount = (data: any) => {
        try {
            dispatch(changeLoading(true));
            const params = {
                title: selectedMonth,
                amountUpdate: data.amount,
                note: data.note,
            };
            const res = contentApi
                .handleChangeMonthlyBudgetAmount(params, transactionId)
                .then((res: any) => {
                    openMessage({
                        variant: `${
                            res.data.STATUS_CODE === 200 ? "success" : "error"
                        }`,
                        message: `${
                            res.data.STATUS_CODE === 200
                                ? "Sửa giao dịch thành công"
                                : res?.data?.MESSAGE_CLIENT
                        }`,
                    });
                    if (res.data.STATUS_CODE === 200) {
                        getListMonthlyBudget(
                            page,
                            selectedMonth,
                            debounceName,
                            debounceID,
                            filterStatus
                        );
                    }
                });
            handlerResetAll();
            return res;
        } catch (error) {
            if (error instanceof Error) {
                const { response } = error as AxiosError;
                const { ...errorObject }: any = response;
                openMessage({
                    variant: "error",
                    message: errorObject?.data.message,
                });
            }
        }
    };

    const handleExportExcelSheet = useCallback(() => {
        const formattedResult = dataMemberList.listTransactions.map(
            (item: any) => {
                return {
                    [ExportSheetProperty.NAME]: item.employeeName,
                    [ExportSheetProperty.EMPLOYEE_ID]: item.employeeId,
                    [ExportSheetProperty.AMOUNT]: item.amount,
                    [ExportSheetProperty.STATUS]: item.status,
                    [ExportSheetProperty.NOTE]: item.note,
                    [ExportSheetProperty.DATE]:
                        item.date && dayjs(`${item.date}`).format("DD-MM-YYYY"),
                    [ExportSheetProperty.CREATED_AT]:
                        item.createdAt &&
                        dayjs(`${item.createdAt}`).format("DD-MM-YYYY"),
                    [ExportSheetProperty.UPDATED_AT]:
                        item.updatedAt &&
                        dayjs(`${item.updatedAt}`).format("DD-MM-YYYY"),
                    [ExportSheetProperty.TRANSACTION_ID]: item._id,
                };
            }
        );
        const ws = utils.json_to_sheet(formattedResult);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "MonthlyBudget");

        writeFileXLSX(wb, "MonthlyBudget.xlsx");
    }, [dataMemberList]);

    const handleLockMonthlyBudget = async () => {
        dispatch(changeLoading(true));
        try {
            const params = {
                title: selectedMonth,
            };
            const response = await contentApi
                .handleLockMonthlyBudget(params)
                .then((res: any) => {
                    openMessage({
                        variant: `${
                            res.data.STATUS_CODE === 201 ? "success" : "error"
                        }`,
                        message: `${
                            res.data.STATUS_CODE === 201
                                ? "Chốt sổ thành công"
                                : res.data.MESSAGE_CLIENT
                        }`,
                    });
                    if (res.data.STATUS_CODE === 201) {
                        getListMonthlyBudget(
                            page,
                            selectedMonth,
                            debounceName,
                            debounceID,
                            filterStatus
                        );
                    }
                    handlerResetAll();
                });
            return response;
        } catch (error) {
            if (error instanceof Error) {
                const { response } = error as AxiosError;
                const { ...errorObject }: any = response;
                openMessage({
                    variant: "error",
                    message: errorObject?.data.message,
                });
            }
        }
    };

    const onCancelForm = () => {
        setEditingStatus(false);
        setEditingAmount(false);
        setEditingNote(false);
        setEditingId("");
        setTransactionId("");
    };

    const inputProps = {
        step: 100000,
        min: 0,
    };

    const handleChangeMonth = (
        event: React.MouseEvent<HTMLElement>,
        newMonth: string
    ) => {
        setSelectedMonth(newMonth);
    };

    const instantChangeStatus = async (status: string, id: string) => {
        try {
            dispatch(changeLoading(true));
            const params = {
                title: selectedMonth,
                newStatus: status,
            };
            const res = await contentApi
                .handleChangeMonthlyBudgetStatus(params, id)
                .then((res: any) => {
                    openMessage({
                        variant: `${
                            res.data.STATUS_CODE === 200 ? "success" : "error"
                        }`,
                        message: `${
                            res.data.STATUS_CODE === 200
                                ? "Sửa thành công"
                                : res.data.MESSAGE_CLIENT
                        }`,
                    });
                    if (res.data.STATUS_CODE === 200) {
                        getListMonthlyBudget(
                            page,
                            selectedMonth,
                            debounceName,
                            debounceID,
                            filterStatus
                        );
                    }
                    handlerResetAll();
                });
            return res;
        } catch (error) {
            dispatch(changeLoading(false));
            if (error instanceof Error) {
                const { response } = error as AxiosError;
                const { ...errorObject }: any = response;
                openMessage({
                    variant: "error",
                    message: errorObject?.data.message,
                });
            }
        }
    };

    useEffect(() => {
        const fetchOption = async () => {
            const { data } = await contentApi.getAllRecordedMonth();
            const options = data.META_DATA.map((month: any) => ({
                value: month,
                label: `Tháng ${month}`,
            }));
            setMonthOptions(options);
        };
        fetchOption();
    }, []);

    return (
        <>
            <BoxLightBlue />
            <BoxContent>
                <Box
                    display={"flex"}
                    alignItems={"center"}
                    className="w-full mb-6 shadow-lg rounded bg-white p-6"
                >
                    <Typography>
                        Total:{" "}
                        <b>
                            {dataMemberList &&
                                formatMoney(dataMemberList.total)}
                        </b>
                    </Typography>
                    <RefreshIcon
                        className="pl-2 cursor-pointer"
                        fontSize="large"
                        onClick={syncTotalMonthlyBudget}
                        color="action"
                    />
                </Box>
                <Accordion className="px-4">
                    <AccordionSummary
                        className="filter-div"
                        expandIcon={<GridExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className="font-bold">Bộ lọc</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box display="flex" flexDirection="column" width="100%">
                            <Box
                                marginBottom="4px"
                                display={"flex"}
                                justifyContent={"space-between"}
                                width="100%"
                            >
                                <div className="w-1/2 pr-3">
                                    <TextFieldComponent
                                        label="Tên"
                                        // value={value}
                                        onChangeValue={setFilterName}
                                        className="w-full"
                                        customStyle={{ height: "40px" }}
                                    />
                                </div>
                                <div className="w-1/2 pl-3">
                                    <TextFieldComponent
                                        label="Mã nhân viên"
                                        // value={value}
                                        onChangeValue={setFilterID}
                                        className="w-full"
                                        customStyle={{ height: "40px" }}
                                    />
                                </div>
                            </Box>
                            <Box width="100%">
                                <SelectComponent
                                    // labelId="demo-simple-select-helper-label"
                                    // id="demo-simple-select-helper"
                                    // value={value}
                                    data={listStatus}
                                    placeholder="Trạng thái"
                                    onChange={(value: any) =>
                                        setFilterStatus(value.value)
                                    }
                                    // size="small"
                                    className="mb-4"
                                    customStyle={{
                                        width: "100%",
                                    }}
                                />
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    className="my-4"
                >
                    <Typography>
                        Danh sách quỹ thành viên tháng:{" "}
                        {selectedMonth !== null &&
                        Number(selectedMonth.split("/")[0]) < 10
                            ? "0"
                            : "Vui lòng chọn tháng"}
                        {selectedMonth !== null &&
                            selectedMonth.replace("/", "-")}
                    </Typography>
                    <Box>
                        <Button
                            className="m-button m-button-success mr-5 w-170"
                            type="button"
                            onClick={() => {
                                setIsOpenLockModal(true);
                            }}
                        >
                            <LockIcon /> Chốt tháng
                        </Button>
                        <Button
                            className="m-button m-button-warning mr-5 w-170"
                            type="button"
                            onClick={handleExportExcelSheet}
                        >
                            <FileDownloadIcon /> Xuất excel
                        </Button>
                    </Box>
                </Box>
                <Box className="mb-8">
                    <ToggleButtonGroup
                        value={selectedMonth}
                        exclusive
                        onChange={handleChangeMonth}
                        aria-label="text alignment"
                        color="success"
                    >
                        {monthOptions.map((month: any) => (
                            <ToggleButton
                                value={month.value}
                                aria-label="left aligned"
                                className="font-bold"
                            >
                                {month.label}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
                </Box>
                <CardTable
                    dataHeaderTable={headerTableMonthlyBudgetPage}
                    className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white overflow-x-auto"
                    dataBodyTable={dataMemberList}
                    currentPage={dataMemberList?.currentPage}
                >
                    <>
                        {dataMemberList &&
                            dataMemberList.listTransactions.map(
                                (item: any, index: any) => (
                                    <tr key={index}>
                                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                                            <Typography
                                                className={
                                                    "ml-3 font-bold text-blueGray-600"
                                                }
                                            >
                                                {index + 1}
                                            </Typography>
                                        </th>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                                            <Typography>
                                                {item?.employeeId}
                                            </Typography>
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                                            <Typography>
                                                {item?.employeeName}
                                            </Typography>
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                                            {editingAmount &&
                                            item?._id === editingId ? (
                                                <Box
                                                    justifyContent={"center"}
                                                    display={"flex"}
                                                >
                                                    <form
                                                        onSubmit={handleSubmit(
                                                            onSubmitAmount
                                                        )}
                                                        id="content-form-b"
                                                        className="flex flex-row items-center"
                                                    >
                                                        <Controller
                                                            name="amount"
                                                            control={control}
                                                            rules={{
                                                                required: true,
                                                            }}
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    onBlur,
                                                                    value,
                                                                },
                                                            }) => (
                                                                <TextFieldComponent
                                                                    value={
                                                                        value
                                                                    }
                                                                    onChangeValue={
                                                                        onChange
                                                                    }
                                                                    type="number"
                                                                    inputProps={
                                                                        inputProps
                                                                    }
                                                                    className="w-32"
                                                                    size="small"
                                                                    margin="0"
                                                                />
                                                            )}
                                                        />
                                                        <div className="pl-2">
                                                            <IconButton
                                                                size="small"
                                                                type="submit"
                                                                form="content-form-b"
                                                            >
                                                                <CheckBoxIcon color="success" />
                                                            </IconButton>
                                                            <IconButton
                                                                size="small"
                                                                onClick={
                                                                    onCancelForm
                                                                }
                                                            >
                                                                <DisabledByDefaultIcon color="error" />
                                                            </IconButton>
                                                        </div>
                                                    </form>
                                                </Box>
                                            ) : (
                                                <Box
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    justifyContent={"center"}
                                                >
                                                    <div className="w-32 text-center">
                                                        <Typography>
                                                            {item?.amount
                                                                ? formatMoney(
                                                                      item?.amount
                                                                  )
                                                                : "-"}
                                                        </Typography>
                                                    </div>
                                                    <div
                                                        className="flex justify-center items-center p-2 cursor-pointer"
                                                        onClick={() => {
                                                            setEditingAmount(
                                                                true
                                                            );
                                                            setEditingId(
                                                                item?._id
                                                            );
                                                            setTransactionId(
                                                                item?._id
                                                            );
                                                            setValue(
                                                                "amount",
                                                                item?.amount
                                                            );
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </div>
                                                </Box>
                                            )}
                                        </td>
                                        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                                            {editingNote &&
                                            item?._id === editingId ? (
                                                <Box
                                                    justifyContent={"center"}
                                                    display={"flex"}
                                                >
                                                    <form
                                                        onSubmit={handleSubmit(
                                                            onSubmitAmount
                                                        )}
                                                        id="content-form-b"
                                                        className="flex flex-row items-center"
                                                    >
                                                        <Controller
                                                            name="note"
                                                            control={control}
                                                            rules={{
                                                                required: true,
                                                            }}
                                                            render={({
                                                                field: {
                                                                    onChange,
                                                                    onBlur,
                                                                    value,
                                                                },
                                                            }) => (
                                                                <TextFieldComponent
                                                                    value={
                                                                        value
                                                                    }
                                                                    onChangeValue={
                                                                        onChange
                                                                    }
                                                                    type="text"
                                                                    inputProps={
                                                                        inputProps
                                                                    }
                                                                    className="w-32"
                                                                    size="small"
                                                                    margin="0"
                                                                />
                                                            )}
                                                        />
                                                        <div className="pl-2">
                                                            <IconButton
                                                                size="small"
                                                                type="submit"
                                                                form="content-form-b"
                                                            >
                                                                <CheckBoxIcon color="success" />
                                                            </IconButton>
                                                            <IconButton
                                                                size="small"
                                                                onClick={
                                                                    onCancelForm
                                                                }
                                                            >
                                                                <DisabledByDefaultIcon color="error" />
                                                            </IconButton>
                                                        </div>
                                                    </form>
                                                </Box>
                                            ) : (
                                                <Box
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    justifyContent={"center"}
                                                >
                                                    <div className="w-32 text-center">
                                                        <Typography>
                                                            {item?.note
                                                                ? item?.note
                                                                : "-"}
                                                        </Typography>
                                                    </div>
                                                    <div
                                                        className="flex justify-center items-center p-2 cursor-pointer"
                                                        onClick={() => {
                                                            setEditingNote(
                                                                true
                                                            );
                                                            setEditingId(
                                                                item?._id
                                                            );
                                                            setTransactionId(
                                                                item?._id
                                                            );
                                                            setValue(
                                                                "note",
                                                                item?.note
                                                            );
                                                        }}
                                                    >
                                                        <EditIcon />
                                                    </div>
                                                </Box>
                                            )}
                                        </td>
                                        <td className="px-6 align-middle flex justify-center text-xs whitespace-nowrap p-4 text-center">
                                            <div
                                                className={`rounded-md p-2 w-28 text-white text-center cursor-pointer ${
                                                    mapStatusToColor[
                                                        item?.status as MonthlyBudgetStatus
                                                    ]
                                                }`}
                                                onClick={() =>
                                                    instantChangeStatus(
                                                        item.status ===
                                                            "processing"
                                                            ? "approved"
                                                            : "processing",
                                                        item._id
                                                    )
                                                }
                                            >
                                                <Typography>
                                                    {
                                                        mapStatus[
                                                            item?.status as MonthlyBudgetStatus
                                                        ]
                                                    }
                                                </Typography>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            )}
                    </>
                </CardTable>
            </BoxContent>
            <ModalDelete
                classNameContent="bg-white"
                styleComponent={{
                    position: "absolute" as "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: 600,
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 4,
                }}
                title="Bạn chắc chắn muốn chốt sổ?"
                isOpenModal={isOpenLockModal}
                onCloseModal={() => {
                    setIsOpenLockModal(false);
                }}
                onClickConfirm={() => handleLockMonthlyBudget()}
            />
        </>
    );
}

export default MonthlyBudgetPage;
