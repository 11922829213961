/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import dashboardAxios from "Api/Interceptors";
import _ from "lodash";

const formDataCreateNew = (params: any) => {
  const { title, publishedDate, mainImage, status, content, homeDisplay, img, type } =
    params;
  const dataArray = new FormData();
  if (mainImage) {
    dataArray.append("mainImg", mainImage);
  }
  if (img) {
    dataArray.append("img", img);
  }
  dataArray.append("title", title);
  dataArray.append("publishedDate", publishedDate);
  dataArray.append("status", status);
  dataArray.append("content", content);
  dataArray.append("homeDisplay", homeDisplay);
  dataArray.append("type", type);
  return dataArray;
};

export const newsApi = {
  getAllNew: (params: any) => {
    const { page, title, datePickerFrom, datePickerTo, status } = params;
    return dashboardAxios.get(
      `/admin/news/paginate?page=${
        page ? page : 1
      }&pageSize=10&status=${
        status ? status : ""
      }&title=${title ? title : ""}&datePickerFrom=${
        datePickerFrom ? datePickerFrom : ""
      }&datePickerTo=${datePickerTo ? datePickerTo : ""}&orderBy=${!_.isEmpty(params.orderBy) ? params.orderBy: 'desc'}&sortBy=${!_.isEmpty(params.sortBy) ? params.sortBy : '_id'}`
    );
  },
  deleteNew: (id: string) => dashboardAxios.delete(`/admin/news/${id}`),
  createNew: (params: any) => {
    const formDataField = formDataCreateNew(params);
    const response = dashboardAxios.postFormData("admin/news", formDataField);
    return response;
  },
  editNew: (params: any, id: string) => {
    const formDataField = formDataCreateNew(params);
    const response = dashboardAxios.patchFormData(
      `admin/news/${id}`,
      formDataField
    );
    return response;
  },
  changeStatusNews: (params: any) => {
    const { id, value } = params;
    const dataArray = new FormData();
    dataArray.append("status", value);
    const res = dashboardAxios.patch(`admin/news/${id}`, dataArray);
    return res;
  },
};
