/*eslint @typescript-eslint/explicit-module-boundary-types: 0*/
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { combineReducers } from 'redux';
import thunk from 'redux-thunk';
import Auth from 'App/Features/Auth';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import Animation from './Features/Animation';

const reducers = combineReducers({
  auth: Auth,
  animation: Animation,
});

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
