import React from 'react'

interface Props {}

function Page404(props: Props) {
  const {} = props

  return <h1 className="text-3xl font-bold underline">404 notfound</h1>;
}

export default Page404
