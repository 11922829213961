import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormHelperText,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { changeLoading } from "App/Features/Animation";
import { useAppDispatch } from "App/Store";
import { AxiosError } from "axios";
import BoxContent from "components/common/BoxContent";
import BoxLightBlue from "components/common/BoxLightBule";
import DatePickerComponent from "components/common/DatePicker";
import TextFieldComponent from "components/common/Input/TextField";
import CardTable from "components/common/ListTable/CardTable";
import { headerTablePublicPost } from "Datasource/dataCommonTable";
import useMessage from "Hooks/useMessage";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { postApi } from "Datasource/post";
import StatusSelect from "components/common/Select/status";
import ModalDelete from "components/common/Modal/ModalDelete";
import ModalComponent from "components/common/Modal";
import { format } from "date-fns";
import "./index.scss";
import IconButton from "@mui/material/IconButton";
import PublishIcon from "@mui/icons-material/Publish";
import EditIcon from "@mui/icons-material/Edit";
import GetAppIcon from "@mui/icons-material/GetApp";
import StoreIcon from "@mui/icons-material/Store";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Chip from "@mui/material/Chip";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import WYSIWYGEditor from "../../components/common/Edittor";

interface Props {}

function PublishPost(props: Props) {
  const {} = props;
  const dispatch = useAppDispatch();
  const { openMessage } = useMessage();
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [startDate, setStartDate] = useState<Date>();
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [listPost, setListPost] = useState<any>();
  const [isOpenModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [itemChosse, setItemChosse] = useState<any>();
  const [namAction, setNameAction] = useState<string>();
  useEffect(() => {
    dispatch(changeLoading(true));
    const params = {
      page: currentPage,
    };
    getListPost(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const {
    register,
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<any>();

  const {
    register: register2,
    setValue: setValue2,
    control: control2,
    reset: reset2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm();

  const getListPost = async (params: any) => {
    try {
      const {
        data: { META_DATA },
      } = await postApi.getAllPost({...params});
      setListPost(META_DATA);

      dispatch(changeLoading(false));
    } catch (error) {
      if (error instanceof Error) {
        dispatch(changeLoading(false));
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const onSubmit = async (data: any) => {
    try {
      dispatch(changeLoading(true));
      const params = {
        title: data.title && data?.title.trim(),
        publishedDateFrom: data?.publishedDateFrom,
        publishedDateTo: data?.publishedDateTo,
        page: currentPage,
        // status: data?.status,
      };

      getListPost(params);
      dispatch(changeLoading(false));
    } catch (error) {
      dispatch(changeLoading(false));
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const changeStatusPublic = async (value: string, id: string) => {
    const newStatus = {
      status: value ? value : "",
      id,
    };
    dispatch(changeLoading(true));
    try {
      const res = await postApi.changeStatusPost(newStatus).then((res: any) => {
        openMessage({
          variant: `${res.data.STATUS_CODE === 200 ? "success" : "error"}`,
          message: `${
            res.data.STATUS_CODE === 200
              ? "Thay đổi trạng thái thành công"
              : res.data.MESSAGE_CLIENT
          }`,
        });
        if (res.data.STATUS_CODE === 200) {
          getListPost({ page: 1 });
          dispatch(changeLoading(false));
          hanlderReset();
        }
      });
      return res;
    } catch (error) {
      dispatch(changeLoading(false));
      hanlderReset();
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const restorePost = async (id: string) => {
    try {
      const res = await postApi.restorePost(id).then((res: any) => {
        openMessage({
          variant: `${res.data.STATUS_CODE === 201 ? "success" : "error"}`,
          message: `${
            res.data.STATUS_CODE === 201
              ? "Lưu trữ thành công"
              : res.data.MESSAGE_CLIENT
          }`,
        });
        if (res.data.STATUS_CODE === 201) {
          getListPost({ page: 1 });
          dispatch(changeLoading(false));
          hanlderReset();
        }
      });
      return res;
    } catch (error) {
      dispatch(changeLoading(false));
      hanlderReset();
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const deletePost = async () => {
    try {
      const res = await postApi.deletePost(itemChosse).then((res: any) => {
        openMessage({
          variant: `${res.data.STATUS_CODE === 204 ? "success" : "error"}`,
          message: `${
            res.data.STATUS_CODE === 204
              ? "Xóa thành công"
              : res.data.MESSAGE_CLIENT
          }`,
        });
        if (res.data.STATUS_CODE === 204) {
          getListPost({ page: 1 });
          dispatch(changeLoading(false));
          hanlderReset();
        }
      });
      return res;
    } catch (error) {
      dispatch(changeLoading(false));
      hanlderReset();
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const hanlderSubmitAction = async (data: any) => {
    const {
      title,
      deadline,
      description,
      employer,
      status,
      benefit,
      requirement,
    } = data;
    dispatch(changeLoading(true));
    try {
      const newParam = {
        title: title?.trim(),
        deadline: format(deadline, "yyyy/MM/dd"),
        description: description?.trim(),
        employer: employer?.trim(),
        status,
        benefit: benefit?.trim(),
        requirement: requirement?.trim(),
        ...data,
      };
      if (namAction === "edit") {
        const res = await postApi
          .editPost(newParam, data?.id)
          .then((res: any) => {
            openMessage({
              variant: `${res.data.STATUS_CODE === 200 ? "success" : "error"}`,
              message: `${
                res.data.STATUS_CODE === 200
                  ? namAction === "edit"
                    ? "Sửa bài viết thành công"
                    : "Tạo bải viết thành công"
                  : res.data.MESSAGE_CLIENT
              }`,
            });
            if (res.data.STATUS_CODE === 200) {
              getListPost({ page: 1 });
              hanlderReset();
              dispatch(changeLoading(false));
              // return res;
            }
          });
      } else {
        const res = await postApi.createPost(newParam).then((res: any) => {
          openMessage({
            variant: `${res.data.STATUS_CODE === 201 ? "success" : "error"}`,
            message: `${
              res.data.STATUS_CODE === 201
                ? namAction === "edit"
                  ? "Sửa bài viết thành công"
                  : "Tạo bài viết thành công"
                : res.data.MESSAGE_CLIENT
            }`,
          });
          if (res.data.STATUS_CODE === 201) {
            getListPost({ page: 1, orderBy: "desc", sortBy: "createdAt" });
            hanlderReset();
            dispatch(changeLoading(false));
            // return res;
          }
        });
        return res;
      }
    } catch (error) {
      dispatch(changeLoading(false));
      hanlderReset();
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const hanlderReset = () => {
    reset();
    reset2();
    setOpenModal(false);
    setOpenModalDelete(false);
    setItemChosse(null);
  };

  const setValueFormEdit = (item: any) => {
    if (item) {
      const {
        title,
        deadline,
        description,
        employer,
        status,
        benefit,
        requirement,
        _id,
      } = item;
      setValue2("title", title);
      setValue2("deadline", new Date(deadline));
      setValue2("description", description);
      setValue2("employer", employer);
      setValue2("status", status);
      setValue2("benefit", benefit);
      setValue2("requirement", requirement);
      setValue2("id", _id);
      setOpenModal(true);
    } else {
      openMessage({
        variant: "error",
        message: "An error occurred, please try again",
      });
    }
  };

  return (
    <>
      <BoxLightBlue />
      <BoxContent>
        <Box className="box-search">
          <Accordion>
            <AccordionSummary
              className="filter-div"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-bold">Bộ lọc</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <form
                onSubmit={handleSubmit(onSubmit)}
                id="content-form"
                className="mt-5"
              >
                <Controller
                  name="title"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TextFieldComponent
                      className="bg-white w-full"
                      label="Tên bài đăng"
                      value={value}
                      onChangeValue={onChange}
                      warningText={errors.name && "Đây là 1 trường bắt buộc"}
                    />
                  )}
                />
                <Box display={"flex"} flexDirection={"column"}>
                  <Typography>Thời gian đăng</Typography>
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    marginTop={1}
                  >
                    <Controller
                      name="publishedDateFrom"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Box className="w-full mr-5">
                          <Typography className="text-blueGray-400">
                            Từ
                          </Typography>
                          <DatePickerComponent
                            onChangeDate={(value) => {
                              onChange(value);
                              setStartDate(value);
                            }}
                            startDate={value}
                            selectsStart={true}
                            className="w-full m-date-picker"
                          />
                        </Box>
                      )}
                    />
                    <Controller
                      name="publishedDateTo"
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <Box className="w-full ml-5">
                          <Typography className="text-blueGray-400">
                            Đến
                          </Typography>
                          <DatePickerComponent
                            onChangeDate={onChange}
                            startDate={value}
                            startDateDefault={startDate}
                            selectsEnd={true}
                            className="w-full m-date-picker bg-white"
                            disabled={!startDate}
                          />
                        </Box>
                      )}
                    />
                  </Box>
                </Box>
                <Box marginTop={3}>
                  <Controller
                    name="status"
                    control={control}
                    rules={{ required: false }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <StatusSelect value={value} onChange={onChange} />
                    )}
                  />
                </Box>
                <Box className="w-full" display={"flex"}>
                  <Button
                    className="m-button m-button-warning m-button-middle-div"
                    type="submit"
                    form="content-form"
                  >
                    <SearchIcon />
                    Tìm kiếm
                  </Button>
                </Box>
              </form>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className="box-content mt-5">
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            className="my-4"
          >
            <Typography>Danh sách bài đăng đã xuất bản</Typography>
            <Button
              className="m-button m-button-success"
              type="button"
              onClick={() => {
                setOpenModal(true);
                setNameAction("add");
              }}
            >
              <AddIcon /> Thêm mới
            </Button>
          </Box>
          <CardTable
            dataHeaderTable={headerTablePublicPost}
            className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white overflow-x-auto"
            dataBodyTable={listPost?.items}
          >
            <>
              {listPost &&
                listPost.items?.map((item: any, index: any) => (
                  <tr key={index}>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                      <Typography
                        className={"ml-3 font-bold text-blueGray-600"}
                      >
                        {listPost.currentPage <= 1
                          ? index + 1
                          : (listPost.currentPage - 1) * 10 + index + 1}
                      </Typography>
                    </th>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                      <Typography className="text-sm">{item?.title}</Typography>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4 text-center">
                      <Typography>
                        {dayjs(`${item?.publishedDate}`).format("DD-MM-YYYY")}
                      </Typography>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4 text-center">
                      {item?.status == "published" ? (
                        <Chip
                          label={"Đã xuất bản"}
                          color="primary"
                          variant="outlined"
                        />
                      ) : item?.status === "draft" ? (
                        <Chip
                          label={"Bản nháp"}
                          color="secondary"
                          variant="outlined"
                        />
                      ) : (
                        <Chip
                          label={"Đã lưu trữ"}
                          color="warning"
                          variant="outlined"
                        />
                      )}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                      <Box display={"flex"} justifyContent={'center'}>
                        <>
                          {item.status === "published" && (
                            <Tooltip
                              title={"Gỡ bài"}
                              arrow
                              placement={"top-end"}
                            >
                              <IconButton
                                aria-label="Unpublished"
                                className="m-button-rounded m-button-danger mr-5"
                                onClick={() =>
                                  changeStatusPublic("draft", item._id)
                                }
                              >
                                <GetAppIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                          {item.status === "draft" && (
                            <>
                              <Tooltip
                                title={"Chỉnh sửa"}
                                arrow
                                placement={"top-end"}
                              >
                                <IconButton
                                  aria-label="delete"
                                  className="m-button-rounded m-button-warning mr-5"
                                  onClick={() => {
                                    setNameAction("edit");
                                    setValueFormEdit(item);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={"Đăng bài"}
                                arrow
                                placement={"top-end"}
                              >
                                <IconButton
                                  aria-label="publish"
                                  className="m-button-rounded m-button-success mr-5"
                                  onClick={() =>
                                    changeStatusPublic("published", item._id)
                                  }
                                >
                                  <PublishIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={"Lưu trữ"}
                                arrow
                                placement={"top-end"}
                              >
                                <IconButton
                                  aria-label="archived"
                                  className="m-button-rounded m-button-success mr-5 ml-2"
                                  onClick={() =>
                                    changeStatusPublic("archived", item._id)
                                  }
                                >
                                  <StoreIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                          {item.status === "archived" && (
                            <>
                              <Tooltip
                                title={"Khôi phục"}
                                arrow
                                placement={"top-end"}
                              >
                                <IconButton
                                  aria-label="Restore"
                                  className="m-button-rounded m-button-success mr-5 ml-2"
                                  onClick={() => {
                                    changeStatusPublic("draft", item._id);
                                  }}
                                >
                                  <SettingsBackupRestoreIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip
                                title={"Xoá"}
                                arrow
                                placement={"top-end"}
                              >
                                <IconButton
                                  aria-label="Permanently Delete"
                                  className="m-button-rounded m-button-success mr-5 ml-2"
                                  onClick={() => {
                                    setItemChosse(item._id);
                                    setOpenModalDelete(true);
                                  }}
                                >
                                  <DeleteForeverIcon />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </>
                      </Box>
                    </td>
                  </tr>
                ))}
            </>
          </CardTable>
          <Pagination
            style={{ justifyContent: "center", display: "flex" }}
            count={listPost?.lastPage}
            onChange={(e, numberPage: number) => {
              setCurrentPage(numberPage);
            }}
            color="primary"
          />
        </Box>
      </BoxContent>
      <ModalComponent
        classNameContent="bg-white"
        open={isOpenModal}
        modalType={namAction}
        handleClose={() => {
          setOpenModal(false);
          reset2();
          // onReset();
        }}
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 1000,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <>
          {namAction === "add" ? (
            <Typography>Thêm mới bài đăng</Typography>
          ) : (
            <Typography>Sửa bài đăng</Typography>
          )}
          <form
            onSubmit={handleSubmit2(hanlderSubmitAction)}
            id="content-form-a"
            className="mt-5"
          >
            <Box overflow={"auto"} maxHeight={`calc(100vh - 300px)`}>
              <Controller
                name="title"
                control={control2}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <TextFieldComponent
                      label="Tiêu đề"
                      value={value}
                      onChangeValue={onChange}
                      warningText={
                        errors2.title && "Đây là một trường bắt buộc"
                      }
                    />
                  </>
                )}
              />
              <Controller
                name="employer"
                control={control2}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <TextFieldComponent
                    label="Phụ đề"
                    value={value}
                    onChangeValue={onChange}
                    warningText={
                      errors2.employer && "Đây là một trường bắt buộc"
                    }
                  />
                )}
              />
              <Controller
                name="deadline"
                control={control2}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Box className="w-full mb-5">
                    <Typography className="text-blueGray-400">
                      Hạn nộp hồ sơ
                    </Typography>
                    <DatePickerComponent
                      onChangeDate={onChange}
                      startDate={value}
                      className="w-full"
                    />
                    {errors2.deadline && (
                      <FormHelperText
                        id="component-error-text"
                        className="text-red-500"
                      >
                        Đây là một trường bắt buộc
                      </FormHelperText>
                    )}
                  </Box>
                )}
              />
              <Controller
                name="description"
                control={control2}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Box>
                    <Typography className="text-blueGray-400">
                      Miêu tả
                    </Typography>
                    <WYSIWYGEditor
                      label="Miêu tả"
                      value={value}
                      onChange={onChange}
                      multiline={true}
                      minRows={20}
                      warningText={
                        errors2.description && "Đây là một trường bắt buộc"
                      }
                      className={"h-100 w-full"}
                    />
                    {errors2.description && (
                      <FormHelperText
                        id="component-error-text"
                        className="text-red-500"
                      >
                        Đây là một trường bắt buộc
                      </FormHelperText>
                    )}
                  </Box>
                )}
              />
              <Controller
                name="requirement"
                control={control2}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Box>
                    <Typography className="text-blueGray-400">
                      Yêu cầu
                    </Typography>
                    <WYSIWYGEditor
                      label="Yêu cầu"
                      value={value}
                      multiline={true}
                      minRows={20}
                      onChange={onChange}
                      warningText={
                        errors2.requirement && "Đây là một trường bắt buộc"
                      }
                    />
                    {errors2.requirement && (
                      <FormHelperText
                        id="component-error-text"
                        className="text-red-500"
                      >
                        Đây là một trường bắt buộc
                      </FormHelperText>
                    )}
                  </Box>
                )}
              />
              <Controller
                name="benefit"
                control={control2}
                rules={{ required: false }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Box>
                    <Typography className="text-blueGray-400">
                      Quyền lợi
                    </Typography>
                    <WYSIWYGEditor
                      label="Quyền lợi"
                      value={value}
                      onChange={onChange}
                      multiline={true}
                      minRows={20}
                      warningText={
                        errors2.benefit && "Đây là một trường bắt buộc"
                      }
                    />
                    {errors2.benefit && (
                      <FormHelperText
                        id="component-error-text"
                        className="text-red-500"
                      >
                        Đây là một trường bắt buộc
                      </FormHelperText>
                    )}
                  </Box>
                )}
              />
              <Controller
                name="status"
                control={control2}
                rules={{ required: true }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <>
                    <StatusSelect value={value} onChange={onChange} />
                    {errors2.status && (
                      <FormHelperText
                        id="component-error-text"
                        className="text-red-500"
                      >
                        Đây là một trường bắt buộc
                      </FormHelperText>
                    )}
                  </>
                )}
              />
            </Box>
          </form>
        </>
      </ModalComponent>
      <ModalDelete
        classNameContent="bg-white"
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        isOpenModal={isOpenModalDelete}
        onCloseModal={() => setOpenModalDelete(false)}
        onClickConfirm={() => deletePost()}
      />
    </>
  );
}

export default PublishPost;
