import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "App/Store";
import useMessage from "Hooks/useMessage";
import BoxLightBlue from "components/common/BoxLightBule";
import BoxContent from "components/common/BoxContent";
import { Box } from "@mui/system";
import { Typography, Button, FormHelperText } from "@material-ui/core";
import { headerTableBudgetManagement } from "Datasource/dataCommonTable";
import { Controller, useForm } from "react-hook-form";
import CardTable from "components/common/ListTable/CardTable";
import SelectComponent from "components/common/Select/defeault";
import DatePickerComponent from "components/common/DatePicker";
import ModalComponent from "components/common/Modal";
import SearchableDropDown from "components/common/SeachableDropDown";
import { contentApi } from "Datasource/content";
import { changeLoading } from "App/Features/Animation";
import { AxiosError } from "axios";
import useDebounce from "Hooks/useDebounce";
import formatMoney from "utilities/formatMoney";
import { parseISO } from "date-fns";
import ModalDelete from "components/common/Modal/ModalDelete";
import { NumericFormat } from "react-number-format";
import { Category } from "@material-ui/icons";
import dayjs from "dayjs";
import BudgetCharts from "./BudgetCharts";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import BarChartIcon from "@mui/icons-material/BarChart";

interface Props {}

interface FormSubmit {
  plan?: number;
  actual?: number;
  type?: any;
  category?: any;
  description?: any;
  createdDate?: Date;
}

const listCategoryType = [
  { label: "Thu", value: "Thu" },
  { label: "Chi", value: "Chi" },
];

const listOfMonth = Array.from(
  { length: dayjs().month() + 1 },
  (value: any, index: any) => {
    return { label: `Tháng ${index + 1}`, value: String(index + 1) };
  }
);

function BudgetManagementPage(props: Props) {
  const {} = props;

  const { openMessage } = useMessage();
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [isOpenDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [isOpenChartModal, setOpenChartModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("add");
  const [selectedBudgetID, setSelectedBudgetID] = useState<string>("");
  const [dataBudgetList, setDataBudgetList] = useState<any>();
  const [listCategory, setListCategory] = useState<any>();
  const [selectedType, setSelectedType] = useState<any>();
  const [selectedCategory, setSelectedCategory] = useState<any>();
  const [searchValue, setSearchValue] = useState<any>();
  const [descriptionList, setDescriptionList] = useState<any>();
  // Lấy ngày đầu tiên của tháng hiện tại
  const [startDate, setStartDate] = useState<any>(
    new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  );
  const [endDate, setEndDate] = useState<any>("");

  const dispatch = useAppDispatch();

  const debouncedSearch = useDebounce(searchValue);

  const getAllBudgetTransaction = async (
    startDate: string,
    endDate: string = ""
  ) => {
    try {
      const { data } = await contentApi.getAllBudgetTransaction(
        startDate,
        endDate
      );
      setDataBudgetList(data);
      dispatch(changeLoading(false));
    } catch (error) {
      dispatch(changeLoading(false));

      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const getCategoryByType = async (type: string) => {
    try {
      const { data } = await contentApi.getCategoryByType(type);
      let newData: any = [];
      data.map((item: any) => {
        let newCategory = {
          value: item._id,
          label: item.name,
        };
        return newData.push(newCategory);
      });
      setListCategory(newData);
      dispatch(changeLoading(false));
    } catch (error) {
      dispatch(changeLoading(false));

      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const getDescriptionSuggestList = async (
    description: string,
    type: string,
    category: string
  ) => {
    try {
      const { data } = await contentApi.getAllDescription(
        description,
        type,
        category
      );
      let newData: any = [];
      data.map((item: any) => {
        let newDescriptionSuggest = {
          value: item._id,
          title: item.description,
        };
        return newData.push(newDescriptionSuggest);
      });
      setDescriptionList(newData);
    } catch (error) {
      dispatch(changeLoading(false));

      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const createNewDescription = async (
    description: string,
    type: any,
    category: string
  ) => {
    try {
      const params: any = {
        description,
        type,
        category,
      };
      const { data } = await contentApi.createNewDescription(params);
      const newDescription = {
        title: data.META_DATA.description,
        value: data.META_DATA._id,
      };
      setValue("description", newDescription);
    } catch (error) {
      dispatch(changeLoading(false));

      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  useEffect(() => {
    dispatch(changeLoading(true));
    getAllBudgetTransaction(startDate, endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  useEffect(() => {
    if (selectedType) {
      dispatch(changeLoading(true));
      getCategoryByType(selectedType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType]);

  useEffect(() => {
    if (selectedType) {
      getDescriptionSuggestList(
        debouncedSearch,
        selectedType,
        selectedCategory
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, selectedType, selectedCategory]);

  const isMatchedResult = useMemo(() => {
    if (descriptionList && descriptionList.length > 0) {
      return (
        descriptionList.findIndex((e: any) => {
          return e.title === debouncedSearch;
        }) !== -1
      );
    } else return false;
  }, [descriptionList, debouncedSearch]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormSubmit>();

  const renderModal = useCallback(() => {
    return (
      <>
        <Box display="flex" flexDirection="row">
          <Controller
            name="plan"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Box
                width="50%"
                display="flex"
                flexDirection="column"
                paddingRight="8px"
              >
                <Typography className="text-blueGray-400">
                  Số tiền dự kiến
                </Typography>
                <NumericFormat
                  value={value}
                  thousandSeparator=","
                  onChange={(e: any) => onChange(e.target.value)}
                  className="w-full rounded"
                  placeholder="Vui lòng nhập số tiền"
                />
                {errors.plan && (
                  <FormHelperText
                    id="component-error-text"
                    className="text-red-500"
                  >
                    Đây là 1 trường bắt buộc
                  </FormHelperText>
                )}
              </Box>
            )}
          />
          <Controller
            name="actual"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Box
                width="50%"
                display="flex"
                flexDirection="column"
                paddingLeft="8px"
              >
                <Typography className="text-blueGray-400">
                  Số tiền thực tế
                </Typography>
                <NumericFormat
                  value={value}
                  thousandSeparator=","
                  onChange={(e: any) => onChange(e.target.value)}
                  className="w-full rounded"
                  placeholder="Vui lòng nhập số tiền"
                />
                {errors.actual && (
                  <FormHelperText
                    id="component-error-text"
                    className="text-red-500"
                  >
                    Đây là 1 trường bắt buộc
                  </FormHelperText>
                )}
              </Box>
            )}
          />
        </Box>
        <div className="">
          <Controller
            name="type"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <Typography className="text-blueGray-400 mt-5">
                  Khoản thu/chi
                </Typography>
                <SelectComponent
                  disabled={modalType == "edit"}
                  value={value}
                  onChange={(e: any, type: any) => {
                    if (type.action === "clear") {
                      setSelectedType(undefined);
                    }
                    onChange(e);
                    setSelectedType(e.value);
                  }}
                  isSearchable={false}
                  isClearable={false}
                  // onInputChange={(e: any) => e.value === "" && setSelectedType(e.value)}
                  data={listCategoryType}
                  placeholder="Vui lòng chọn"
                  customStyle={{
                    height: "40px",
                  }}
                />
                {errors.type && (
                  <FormHelperText
                    id="component-error-text"
                    className="text-red-500"
                  >
                    Đây là 1 trường bắt buộc
                  </FormHelperText>
                )}
              </>
            )}
          />
        </div>
        <div className="mb-5">
          <Controller
            name="category"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <>
                <Typography className="text-blueGray-400 mt-5">Loại</Typography>
                <SelectComponent
                  value={value}
                  onChange={(e: any, type: any) => {
                    if (type.action === "clear") {
                      setSelectedCategory(undefined);
                    }
                    onChange(e);
                    setSelectedCategory(e.value);
                  }}
                  isSearchable={false}
                  isClearable={false}
                  data={listCategory}
                  placeholder="Vui lòng chọn"
                  disabled={!selectedType || modalType == "edit"}
                  customStyle={{
                    height: "40px",
                    "background-color": "white",
                  }}
                />
                {errors.type && (
                  <FormHelperText
                    id="component-error-text"
                    className="text-red-500"
                  >
                    Đây là 1 trường bắt buộc
                  </FormHelperText>
                )}
              </>
            )}
          />
        </div>
        <Controller
          name="description"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <Typography className="text-blueGray-400 mt-5">
                Miêu tả
              </Typography>
              <SearchableDropDown
                keyValue="description"
                value={value}
                setValue={setValue}
                setSearchValue={setSearchValue}
                option={descriptionList}
                disabled={!selectedCategory || modalType == "edit"}
                isMatchedResult={isMatchedResult}
                submitCreateNewModal={() =>
                  createNewDescription(
                    debouncedSearch,
                    selectedType,
                    selectedCategory
                  )
                }
              />
              {errors.description && (
                <FormHelperText
                  id="component-error-text"
                  className="text-red-500"
                >
                  Đây là 1 trường bắt buộc
                </FormHelperText>
              )}
            </>
          )}
        />
        <div className="mt-3">
          <Controller
            name="createdDate"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <Box className="w-full mb-5">
                <Typography className="text-blueGray-400">Ngày tạo</Typography>
                <DatePickerComponent
                  disabled={modalType == "edit"}
                  onChangeDate={onChange}
                  startDate={value}
                  className="w-full m-date-picker"
                />
                {errors.createdDate && (
                  <FormHelperText
                    id="component-error-text"
                    className="text-red-500"
                  >
                    Đây là 1 trường bắt buộc
                  </FormHelperText>
                )}
              </Box>
            )}
          />
        </div>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors, selectedType, selectedCategory, listCategory, descriptionList]);

  const handleChangeRow = () => {};

  const handleResetAll = () => {
    reset();
    setOpenModal(false);
    setSelectedType(undefined);
    setSearchValue(undefined);
    setSelectedCategory(undefined);
    setSelectedBudgetID("");
    setModalType("add");
    setOpenDeleteModal(false);
  };

  let valueSelected = {
    label: "",
    value: "",
  }

  const defaultValue = {
    title: "",
    value: "",
  };

  const setValueEditingTransaction = useCallback(
    (item: any) => {
      const selectedType = listCategoryType.filter((type: any) => {
        return type.value === item.type;
      });
      setSelectedType(selectedType[0].value);
      setSelectedCategory(item.category[0]._id);
      valueSelected.value = item.category[0]._id;
      valueSelected.label = item.category[0].name;
      defaultValue.value = item.description[0]?._id;
      defaultValue.title = item.description[0]?.description;

      setValue("createdDate", parseISO(item.date));
      setValue("type", selectedType[0]);
      setValue("plan", item.plan);
      setValue("actual", item.actual);
      setValue("category", valueSelected);
      setValue("description", defaultValue);
    },
    [listCategoryType, listCategory, descriptionList]
  );

  const onSubmit = async (data: any) => {
    try {
      if (modalType === "add") {
        dispatch(changeLoading(true));
        const params = {
          plan: data.plan.replaceAll(",", ""),
          actual: data.actual.replaceAll(",", ""),
          type: data.type.value,
          category: data.category.value,
          description: data.description.value,
          date: data.createdDate,
        };
        const res = await contentApi
          .createNewTransaction(params)
          .then((res: any) => {
            openMessage({
              variant: `${res.data.STATUS_CODE === 201 ? "success" : "error"}`,
              message: `${
                res.data.STATUS_CODE === 201
                  ? "Thêm mới thành công"
                  : res.data.MESSAGE_CLIENT
              }`,
            });
            if (res.data.STATUS_CODE === 201) {
              getAllBudgetTransaction(startDate, endDate);
              handleResetAll();
            }
          });
        return res;
      } else if (modalType === "edit") {
        dispatch(changeLoading(true));
        const params = data && {
          date: data.createdDate,
          description: data.description.value,
          plan: typeof data.plan === "string" ? data?.plan.replaceAll(",", "") : data?.plan,
          actual: typeof data.actual === "string" ? data?.actual.replaceAll(",", "") : data?.actual,
          category: data.category.value,
        };

        const res = await contentApi
          .handleEditBudgetTransaction(selectedBudgetID, params)
          .then((res: any) => {
            openMessage({
              variant: `${res.data.STATUS_CODE === 200 ? "success" : "error"}`,
              message: `${
                res.data.STATUS_CODE === 200
                  ? "Cập nhật thành công"
                  : res.data.MESSAGE_CLIENT
              }`,
            });
            if (res.data.STATUS_CODE === 200) {
              getAllBudgetTransaction(startDate, endDate);
              handleResetAll();
            }
          })
        return res;
      }
    } catch (error) {
      dispatch(changeLoading(false));
      // if (error instanceof Error) {
      //   const { response } = error as AxiosError;
      //   const { ...errorObject }: any = response;
      //   openMessage({
      //     variant: "error",
      //     message: errorObject?.data.message,
      //   });
      // }
    }
  };

  const handleDeleteBudgetTransaction = async (id: string) => {
    try {
      const res = await contentApi
        .handleDeleteBudgetTransaction(id)
        .then((res: any) => {
          openMessage({
            variant: `${res.data.STATUS_CODE === 204 ? "success" : "error"}`,
            message: `${
              res.data.STATUS_CODE === 204
                ? "Xóa thành công"
                : res.data.MESSAGE_CLIENT
            }`,
          });
          if (res.data.STATUS_CODE === 204) {
            setOpenDeleteModal(false);
            setSelectedBudgetID("");
            getAllBudgetTransaction(startDate, endDate);
            reset();
          }
        });
      return res;
    } catch (error) {
      dispatch(changeLoading(false));
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  return (
    <>
      <BoxLightBlue />
      <BoxContent>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className="my-4"
        >
          <Typography>
            <h6>Quản lý thu chi</h6>
          </Typography>
          <Box
            className="align-c"
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"start"}
          >
            <span className="mr-5">Từ</span>
            <DatePickerComponent
              onChangeDate={(value) => setStartDate(value)}
              selectsStart
              startDate={startDate}
              className="w-full m-date-picker"
            />
            <span className="mr-5 ml-5">đến</span>
            <DatePickerComponent
              minDate={startDate}
              onChangeDate={(value) => setEndDate(value)}
              startDate={endDate}
              className="w-full m-date-picker"
            />
          </Box>
          <Box>
            <Button
              className="m-button m-button-warning mr-5"
              type="button"
              onClick={() => {
                setOpenChartModal(true);
              }}
            >
              <BarChartIcon /> Báo cáo
            </Button>
            <Button
              className="m-button m-button-success"
              type="button"
              onClick={() => {
                setOpenModal(true);
                setModalType("add");
              }}
            >
              <AddIcon /> Thêm mới
            </Button>
          </Box>
        </Box>
        <CardTable
          dataHeaderTable={headerTableBudgetManagement}
          className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white overflow-x-auto"
          dataBodyTable={dataBudgetList}
          handleAction={handleChangeRow}
          currentPage={1}
        >
          <>
            {dataBudgetList &&
              dataBudgetList.map((item: any, index: any) => (
                <tr key={index}>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                    <Typography className={"ml-3"}>
                      {dayjs(`${item.date}`).format("DD-MM-YYYY")}
                    </Typography>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                    <Typography>{item?.type}</Typography>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                    <Typography>{item?.category[0]?.name}</Typography>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                    <Typography>{item?.description[0]?.description}</Typography>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                    <Typography>{formatMoney(item?.actual)}</Typography>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center w-100">
                    <Box display={"flex"}>
                      <IconButton
                        aria-label="delete"
                        className="m-button-rounded m-button-warning mr-5"
                        onClick={() => {
                          setModalType("edit");
                          setValueEditingTransaction(item);
                          setOpenModal(true);
                          setSelectedBudgetID(item._id);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        className="m-button-rounded m-button-danger"
                        onClick={() => {
                          setOpenDeleteModal(true);
                          setSelectedBudgetID(item._id);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </td>
                </tr>
              ))}
          </>
        </CardTable>
      </BoxContent>
      <ModalComponent
        classNameContent="bg-white"
        open={isOpenModal}
        modalType={modalType}
        handleClose={() => {
          handleResetAll();
        }}
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 1000,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <>
          <Typography>
            {modalType === "add" ? "Thêm mới thu chi" : "Cập nhật thu chi"}
          </Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id="content-form-a"
            className="mt-5"
          >
            <Box overflow={"visible"} maxHeight={600} className="p-3">
              {renderModal()}
            </Box>
          </form>
        </>
      </ModalComponent>
      <ModalComponent
        hideSaveButton={true}
        classNameContent="bg-white"
        open={isOpenChartModal}
        handleClose={() => {
          setOpenChartModal(false);
        }}
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 1000,
          maxHeight: "745px",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          overflow: "auto",
        }}
      >
        <BudgetCharts />
      </ModalComponent>
      <ModalDelete
        isOpenModal={isOpenDeleteModal}
        onCloseModal={() => {
          handleResetAll();
        }}
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        onClickConfirm={() => {
          handleDeleteBudgetTransaction(selectedBudgetID);
        }}
      />
    </>
  );
}

export default BudgetManagementPage;
