import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import BoxContent from "components/common/BoxContent";
import BoxLightBlue from "components/common/BoxLightBule";
import "./index.scss";
import { useAppDispatch } from "App/Store";
import { Controller, useForm } from "react-hook-form";
import CardTable from "components/common/ListTable/CardTable";
import Button from "@mui/material/Button";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import TextFieldComponent from "components/common/Input/TextField";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { headerTableTemplate } from "Datasource/dataCommonTable";
import Tooltip from "@mui/material/Tooltip";
import Pagination from "@mui/material/Pagination";
import ModalComponent from "components/common/Modal";
import { changeLoading } from "App/Features/Animation";
import { templateApi } from "Datasource/template";
import { AxiosError } from "axios";
import useMessage from "Hooks/useMessage";
import ModalDelete from "components/common/Modal/ModalDelete";
import TemplateTypeSelect from "./template";
import TemplateVariableSelect from "./templateVariable";
import ModalComponentNoButton from "components/common/Modal/ModelChoosingVariable";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface Props {}

function TemplateManagement(props: Props) {
  const {} = props;
  const { openMessage } = useMessage();
  const dispatch = useAppDispatch();
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [isOpenModalVariable, setOpenModalVariable] = useState<boolean>(false);
  const [listNew, setListNew] = useState<any>();
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [itemChosse, setItemChosse] = useState<any>();
  const [isOpenModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [nameAction, setNameAction] = useState<string>("");
  const [currentEditorContent, setCurrentEditorContent] = useState<string>("");
  const [valueEditor, setValueEditor] = useState<any>("");
  const [type, setType] = useState<any>("");
  const [showBudgetType, setShowBudgetType] = useState<boolean>(false);
  const parse = require("html-react-parser");

  useEffect(() => {
    dispatch(changeLoading(true));
    const params = {
      page: currentPage,
      status: "published",
    };
    getListTemplate(params);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const {
    register,
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<any>();

  const {
    register: register2,
    setValue: setValue2,
    control: control2,
    reset: reset2,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
  } = useForm<any>();

  const {
    register: register3,
    setValue: setValue3,
    control: control3,
    reset: reset3,
    formState: { errors: errors3 },
    handleSubmit: handleSubmit3,
  } = useForm<any>();

  const setValueEditForm = (item: any) => {
    if("budget" === item.type) {
      setShowBudgetType(true);
    }
    setValue2("type", item.type);
    if(item.attributes && item.attributes.length > 0) {
      setValue2("attributeValue", item.attributes[0].v);
    }
    setCurrentEditorContent(item.content);
  };

  const onSubmit = async (data: any) => {
    try {
      dispatch(changeLoading(true));
      const params = {
        type: data.type,
      };
      const {
        data: { META_DATA },
      } = await templateApi.getAllTemplates(params);
      setListNew(META_DATA);
      dispatch(changeLoading(false));
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const handlerResetAll = () => {
    reset2();
    setOpenModal(false);
    setNameAction("");
    setCurrentEditorContent("");
    setShowBudgetType(false)
  };

  const getListTemplate = async (params: any) => {
    try {
      const {
        data: { META_DATA },
      } = await templateApi.getAllTemplates(params);
      setListNew(META_DATA);
      dispatch(changeLoading(false));
    } catch (error) {
      dispatch(changeLoading(false));
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const onSubmit3 = async (data: any) => {
    setOpenModalVariable(false);
    setOpenModal(false);
    resetForm3();
    setTimeout(async () => {
      setOpenModal(true);
      let generatedContent = await generateContent(valueEditor, data.variable);
      setValueEditForm({ type: type, content: generatedContent });
      setCurrentEditorContent(generatedContent);
    }, 1);
  };

  const resetForm3 = async () => {
    reset3();
  };

  const generateContent = async (content: string, variable: string) => {
    if (valueEditor.includes("$")) {
      const lstSplit = content.split("$");
      return `${lstSplit[0]} {{${variable}}} ${lstSplit[1]}`;
    }
    return ``;
  };

  const onSubmitFormAdd = async (data: any) => {
    try {
      const params = "budget" === data.type ?
        {
          type: data?.type,
          content: currentEditorContent?.trim(),
          id: "",
          attributes: [{k: "budget", v: data.attributeValue}]
        } :
        {
          type: data?.type,
          content: currentEditorContent?.trim(),
          id: ""
        }
      if (nameAction == "edit") {
        const res = await templateApi
          .editTemplate(params, itemChosse._id)
          .then((res: any) => {
            handlerResetAll();
            getListTemplate({});
            openMessage({
              variant: `${res.data.STATUS_CODE === 200 ? "success" : "error"}`,
              message: `${
                res.data.STATUS_CODE === 200
                  ? "Cập nhật thành công"
                  : res.data.MESSAGE_CLIENT
              }`,
            });
          });
        return res;
      } else {
        await templateApi.createTemplate(params).then((res) => {
          openMessage({
            variant: `${res.data.STATUS_CODE === 201 ? "success" : "error"}`,
            message: `${
              res.data.STATUS_CODE === 201
                ? "Thêm mới thành công"
                : res.data.MESSAGE_CLIENT
            }`,
          });
        });
        handlerResetAll();
        onReset();
        getListTemplate({});
      }
    } catch (error) {
      const { response } = error as AxiosError;
      const { ...errorObject }: any = response;
      openMessage({
        variant: "error",
        message: errorObject?.data.message,
      });
    }
  };

  const handlerDeleteClient = async (id: string) => {
    try {
      const res = await templateApi.deleteTemplate(id).then((res: any) => {
        openMessage({
          variant: `${res.data.STATUS_CODE === 204 ? "success" : "error"}`,
          message: `${
            res.data.STATUS_CODE === 204
              ? "Xóa thành công"
              : res.data.MESSAGE_CLIENT
          }`,
        });
        if (res.data.STATUS_CODE === 204) {
          setOpenModalDelete(false);
          setItemChosse(null);
          getListTemplate({});
        }
      });
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const onReset = () => {
    setOpenModal(false);
    reset();
  };

  const onKeyPress = async (data: any) => {
    if (currentEditorContent != data && data.includes("$")) {
      setOpenModalVariable(true);
      setValueEditor(data);
      setType(control2._formValues.type);
    } else {
      setCurrentEditorContent(data);
    }
  };

  const handleSelectBudget = (e: any) => {
    if("budget" === e.target.value) {
      setShowBudgetType(true);
    } else {
      setShowBudgetType(false);
    }
  }

  return (
    <Box>
      <BoxLightBlue />
      <BoxContent>
        <Box className="box-search">
          <Accordion>
            <AccordionSummary
              className="filter-div"
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className="font-bold">Bộ lọc</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <form
                onSubmit={handleSubmit(onSubmit)}
                id="content-form"
                className="mt-5"
              >
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: false }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TemplateTypeSelect value={value} onChange={onChange} />
                  )}
                />

                <Box className="w-full" display={"flex"}>
                  <Button
                    className="m-button m-button-warning m-button-middle-div"
                    type="submit"
                  >
                    <SearchIcon />
                    Tìm kiếm
                  </Button>
                </Box>
              </form>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box className="box-content mt-5">
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            className="my-4"
          >
            <Typography>Danh sách Template</Typography>
            <Button
              className="m-button m-button-success"
              type="button"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <AddIcon /> Thêm mới
            </Button>
          </Box>
          <CardTable
            dataHeaderTable={headerTableTemplate}
            className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white overflow-x-auto"
            dataBodyTable={listNew?.items}
          >
            <>
              {listNew &&
                listNew.items?.map((item: any, index: any) => (
                  <tr key={index}>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left ">
                      <Typography
                        className={"ml-3 font-bold text-blueGray-600"}
                      >
                        {listNew.currentPage <= 1
                          ? index + 1
                          : (listNew.currentPage - 1) * 10 + index + 1}
                      </Typography>
                    </th>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left ">
                      {item?.type === "budget" ? <Typography>{`${item?.type} (${item.attributes[0].v})`}</Typography> : <Typography>{item?.type}</Typography>}
                      {/*<Typography>{item?.type}</Typography>*/}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left ">
                      <Typography>{parse(item?.content)}</Typography>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left ">
                      <Box display={"flex"}>
                        <Tooltip title="Edit" arrow placement="top-end">
                          <IconButton
                            aria-label="delete"
                            className="m-button-rounded m-button-warning mr-5"
                            onClick={() => {
                              setNameAction("edit");
                              setOpenModal(true);
                              setItemChosse(item);
                              setValueEditForm(item);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Xóa" arrow placement="top-end">
                          <IconButton
                            aria-label="delete"
                            className="m-button-rounded m-button-danger"
                            onClick={() => {
                              setOpenModalDelete(true);
                              setItemChosse(item);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </td>
                  </tr>
                ))}
            </>
          </CardTable>
          <Pagination
            style={{ justifyContent: "center", display: "flex" }}
            count={listNew?.lastPage}
            onChange={(e, numberPage: number) => {
              setCurrentPage(numberPage);
            }}
            color="primary"
          />
        </Box>
        <ModalComponent
          classNameContent="bg-white"
          open={isOpenModal}
          modalType={nameAction === "edit" ? "edit" : "add"}
          handleClose={() => {
            setOpenModal(false);
            handlerResetAll();
          }}
          styleComponent={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 1000,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <>
            <Typography variant="h6">Thêm mới template</Typography>
            <form
              onSubmit={handleSubmit2(onSubmitFormAdd)}
              id="content-form-a"
              className="mt-5"
            >
              <div className="mt-3 mb-4">
                <Controller
                  name="type"
                  control={control2}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TemplateTypeSelect value={value} onChange={(e) => {
                      onChange(e);
                      handleSelectBudget(e);
                    }} />
                  )}
                />

                {showBudgetType && <Controller
                    name="attributeValue"
                    control={control2}
                    rules={{ required: true }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <TemplateTypeSelect value={value} onChange={onChange} showBudgetType={showBudgetType}/>
                    )}
                />}
              </div>
              <div className="mb-4">
                <Typography className="mb-2">Nội dung template</Typography>
                {/* <WYSIWYGEditor
                    onChange={onKeyPress}
                    value={currentEditorContent}
                  /> */}
                <TextFieldComponent
                  label="Miêu tả dự án"
                  value={currentEditorContent}
                  onChangeValue={onKeyPress}
                  warningText={
                    errors.description ? "Đây là một trường bắt buộc" : ""
                  }
                  multiline={true}
                  rows={8}
                  margin="10px 0px 0px 0px"
                  size="large"
                />
              </div>
            </form>
          </>
        </ModalComponent>

        <ModalComponentNoButton
          classNameContent="bg-white"
          open={isOpenModalVariable}
          handleClose={() => {
            setOpenModalVariable(false);
            handlerResetAll();
          }}
          styleComponent={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <>
            <Typography variant="h6">Thêm mới template</Typography>
            <form
              onSubmit={handleSubmit3(onSubmit3)}
              id="content-form-add"
              className="mt-5"
            >
              <div className="mt-3 mb-4">
                <Controller
                  name="variable"
                  control={control3}
                  rules={{ required: true }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <TemplateVariableSelect value={value} onChange={onChange} />
                  )}
                />
              </div>
            </form>
          </>
        </ModalComponentNoButton>

        <ModalDelete
          isOpenModal={isOpenModalDelete}
          onCloseModal={() => setOpenModalDelete(false)}
          onClickConfirm={() => handlerDeleteClient(itemChosse._id)}
        />
      </BoxContent>
    </Box>
  );
}

export default TemplateManagement;
