import {Breadcrumbs, Typography} from "@mui/material";
import UserDropdown from "components/common/Dropdowns/UserDropdown";
import React from "react";
import {Link, useHistory} from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';

interface Props {}

function HeaderComponent(props: Props) {
  const {} = props;
  const history = useHistory();

  const renderBreadcrumb = () => {
    const pathName = window.location.pathname;
    switch (pathName) {
      case "/":
        return (
          <a
            className="font-bold text-sm uppercaser hidden lg:inline-block font-semibold"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            <HomeIcon/>
          </a>
        );
      case "/users":
      case "/roles":
      case "/permission":
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
                <HomeIcon/> / Quản lý nhân sự
            </Link>
            <Typography className="font-bold text-sm uppercaser hidden lg:inline-block font-semibold">
              {pathName === "/users"
                ? "Nhân viên"
                : pathName === "/roles"
                ? "Chức vụ"
                : pathName === "/permission" && "Phân quyền"}
            </Typography>
          </Breadcrumbs>
        );
        case "/home-page":
        case "/who-we-are":
        case "/technical-capability":
        case "/our-story":
        case "/projects":
        case "/clients":
        return (
          <Breadcrumbs aria-label="breadcrumb">
            <Link color="inherit" to="/">
                <HomeIcon/> / Quản lý nội dung
            </Link>
            <Typography className="font-bold text-sm uppercaser hidden lg:inline-block font-semibold">
              {pathName === "/home-page"
                ? "Trang chủ"
                : pathName === "/who-we-are"
                ? "Về chúng tôi"
                : pathName === "/technical-capability"
                ? "Công nghệ"
                : pathName === "/our-story"
                ? "Câu chuyện của chúng tôi"
                : pathName === "/projects"
                ? "Dự án"
                : "Khách hàng"
              }
            </Typography>
          </Breadcrumbs>
        );
        case "/published-news":
        case "/draft-news":
        case "/folders-management":
            return (
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/">
                        <HomeIcon/> / Ảnh
                    </Link>
                    <Typography className="font-bold text-sm uppercaser hidden lg:inline-block font-semibold">
                        {pathName === "/folders-management" ? "Quản trị thư mục" : ""}
                    </Typography>
                </Breadcrumbs>
            )
        case "/publish-post":
            return (
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/">
                        <HomeIcon/> / Ảnh
                    </Link>
                    <Typography className="font-bold text-sm uppercaser hidden lg:inline-block font-semibold">
                        {pathName === "/publish-post" ? "Bài đăng đã xuất bản" : ""}
                    </Typography>
                </Breadcrumbs>
            )
        case "/submission":
            return (
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/">
                        <HomeIcon/> / Chia sẻ
                    </Link>
                    <Typography className="font-bold text-sm uppercaser hidden lg:inline-block font-semibold">
                        {pathName === "/submission" ? "Submission" : ""}
                    </Typography>
                </Breadcrumbs>
            )
        case "/budget-management":
        case "/budget-categories":
        case "/monthly-budget":
        case "/thu-chi":
            return (
                <Breadcrumbs aria-label="breadcrumb">
                    <Link color="inherit" to="/">
                        <HomeIcon/> / Quỹ
                    </Link>
                    <Typography className="font-bold text-sm uppercaser hidden lg:inline-block font-semibold">
                        {pathName === "/budget-management" ? "Quản lí thu chi"
                            : pathName === "/budget-categories" ? "Định nghĩa loại thu chi"
                                : pathName === "/monthly-budget" ? "Quỹ thành viên tháng" : "Bảng tổng hợp thu chi"}
                    </Typography>
                </Breadcrumbs>
            );
        // case "/thu-chi":
        //     return (
        //         <Breadcrumbs aria-label="breadcrumb">
        //             <Link color="inherit" to="/">
        //                 <HomeIcon/> / Thu chi
        //             </Link>
        //             <Typography className="font-bold text-sm uppercaser hidden lg:inline-block font-semibold">
        //                 {pathName === "/thu-chi" ? "Thu chi" : ""}
        //             </Typography>
        //         </Breadcrumbs>
        //     )
      default:
        break;
    }
  };

  return (
    <>
      {/* Navbar */}
      <nav className="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4">
        <div className="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4">
          {renderBreadcrumb()}
          {/* Form */}
          <div className="md:flex hidden flex-row flex-wrap items-center lg:ml-auto mr-3">
          </div>
          {/* User */}
          <ul className="flex-col md:flex-row list-none items-center hidden md:flex">
            <UserDropdown />
          </ul>
        </div>
      </nav>
      {/* End Navbar */}
    </>
  );
}

export default HeaderComponent;
