/* eslint-disable react/jsx-no-undef */
import { Button, TextareaAutosize, TextField, Typography } from "@mui/material";
import { AxiosError } from "axios";
import BoxContent from "components/common/BoxContent";
import BoxLightBlue from "components/common/BoxLightBule";
import useMessage from "Hooks/useMessage";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { contentApi } from "Datasource/content";
import { useDropzone } from "react-dropzone";

interface Props {}

function OurStory(props: Props) {
  const {} = props;
  const {
    register,
    setValue,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<any>();
  const { openMessage } = useMessage();
  const [imageUpload, setImageUpload] = useState<any>(null);
  const [nameImage, setNameImage] = useState<string>("");

  const onSubmit = async (data: any) => {
    let newParam;
    if (imageUpload.size > 0) {
      newParam = {
        content: data.content,
        duPhoto: imageUpload,
      };
    } else {
      newParam = {
        content: data.content,
      };
    }
    handlerCreateNewAboutUS(newParam);

  };

  const handlerCreateNewAboutUS = async (params: any) => {
    try {
      const res = await contentApi.createOurStory(params).then((res: any) => {
        openMessage({
          variant: `${res.data.STATUS_CODE === 201 ? "success" : "error"}`,
          message: `${
            res.data.STATUS_CODE === 201
              ? "Tạo câu chuyện thành công"
              : res.data.MESSAGE_CLIENT
          }`,
        });
        setValue("content", '')
        setValue('duPhoto','')
        setImageUpload(null);
        setNameImage('')
      });
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (fs: File[]) => {
      // setNameImage(fs[0].name);
      setNameImage(URL.createObjectURL(fs[0]));
      setImageUpload(fs[0]);
      // setImageUpload(fs[0]);
    },
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
    },
  });

  return (
    <>
      <BoxLightBlue />
      <BoxContent>
        <form onSubmit={handleSubmit(onSubmit)} id="content-form">
          <Typography>Chúng ta là ai ?</Typography>
          <Controller
            name="content"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextareaAutosize
                aria-label="minimum height"
                minRows={3}
                placeholder="Vui lòng nhập nội dung"
                value={value}
                onChange={(e: any) => onChange(e.target.value)}
                className="w-full"
              />
            )}
          />

          <div className="flex mt-8">
            <div className="flex flex-col w-full" style={{ paddingRight: 10 }}>
              <div>
                <TextField
                  id="outlined-multiline-flexible"
                  label="Hình ảnh câu chuyện của chúng tôi"
                  maxRows={1}
                  value={imageUpload ? imageUpload?.name : " "}
                  helperText="Vui lòng chỉ updload file dạng png, jpg, gif"
                  className="w-full"
                  disabled
                  size='small'
                />
              </div>
            </div>
            <div className="w-full relative" style={{ padding: "0px 10px" }}>
              <div
                className={`box-border p-2`}
                style={{
                  position: "relative",
                  border: "1px dotted rgba(59, 130, 246, 0.5)",
                }}
                {...getRootProps({
                  onClick: (e) => {
                    e.stopPropagation();
                  },
                })}
              >
                {isDragActive ? (
                  <div>
                    <Typography className="text-drop-file fw-600">
                      DRAG <span className="tractor">&</span> DROP
                    </Typography>
                  </div>
                ) : (
                  <>
                    <div
                      className="box-content-drop"
                      {...getRootProps({
                        onClick: (e) => {
                          e.stopPropagation();
                        },
                      })}
                    >
                      <label
                        htmlFor="select_file"
                        className="text-f20 text-bold"
                      >
                        <span className="box-blue">Chọn ảnh</span> hoặc kéo &
                        thả nó ở đây
                      </label>
                      <input
                        {...getInputProps()}
                        type="file"
                        name="file"
                        id="select_file"
                        className="inputFile"
                        placeholder="Chọn ảnh"
                        style={{ display: "none" }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="w-full relative">
              <img
                loading="lazy"
                src={nameImage}
                className="rounded"
                alt="image"
              />
            </div>
          </div>
        </form>
        <div className="mx-auto relative d-flex mt-20">
          <button
            className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-fit ease-linear transition-all duration-150"
            type="submit"
            form="content-form"
          >
            Cập nhật
          </button>
        </div>
      </BoxContent>
    </>
  );
}

export default OurStory;
