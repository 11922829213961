import { Box, Button, Modal, Typography } from "@mui/material";
import React from "react";
import ModalComponent from ".";
import CustomCloseButton from "./CustomCloseButton";

interface Props {}

function ModalDelete(props: any) {
    const {
        isOpenModal,
        onCloseModal,
        title,
        onClickConfirm,
        onClickCancel,
        styleComponent,
        children,
        classNameContent,
    } = props;

    return (
        <Modal
            open={isOpenModal}
            onClose={onCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                className="flex"
                sx={{
                    marginTop: "300px",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                <Box
                    className="bg-white flex relative"
                    sx={{ padding: "30px" }}
                >
                    <Box className="absolute top-0 right-0">
                        <CustomCloseButton onClick={onCloseModal} />
                    </Box>
                    <Typography sx={{ fontSize: "23px", fontWeight: "700" }}>
                        {title
                            ? title
                            : "Bạn có chắc chắn muốn xóa dữ liệu này?"}
                    </Typography>
                    <div className="m-dialog-footer flex mt-16">
                        <Button
                            className="m-button text-white text-bold mr-10 m-button-warning"
                            type="submit"
                            form="content-form-a"
                            onClick={onClickConfirm}
                        >
                            Xác nhận
                        </Button>
                        <Button
                            className="m-button m-button-danger text-white text-bold ml-atuo"
                            onClick={onCloseModal}
                        >
                            Hủy bỏ
                        </Button>
                    </div>
                </Box>
            </Box>
        </Modal>
    );
}

export default ModalDelete;
