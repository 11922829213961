import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitalStateTypes {
  isLoading?: boolean;
}

const initialState: InitalStateTypes = {
  isLoading: false,
};

export const animationSlice = createSlice({
  name: "animation",
  initialState,
  reducers: {
    changeLoading: (state, data: PayloadAction<any>) => {
      const payload: any = data.payload;
      return {
        ...state,
        isLoading: payload,
      };
    },
  },
});

export const { changeLoading } = animationSlice.actions;
export default animationSlice.reducer;
