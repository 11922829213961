import { Box, Typography, Modal } from "@mui/material";
import React from "react";
import CustomCloseButton from "./CustomCloseButton";

const ModalPreview = (props: any) => {
  const { isOpenModal, onCloseModal, item } = props;
  console.log("🚀 ~ file: ModalPreview.tsx:7 ~ ModalPreview ~ item:", item);

  const formatDate = (date: Date | string) => {
    const d = new Date(date);
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  };

  return (
    <Modal
      open={isOpenModal}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className="flex"
        sx={{
          maxWidth: "80%",
          height: "80vh",
          margin: "100px auto",
          overflowY: "scroll",
          backgroundColor: "white"
        }}
      >
        <Box sx={{ padding: "30px", position: "relative" }}>
          <Box className="absolute top-0 right-0">
            <CustomCloseButton onClick={onCloseModal} />
          </Box>
          <Typography sx={{ fontSize: "23px", fontWeight: "700" }}>
            Preview
          </Typography>
          {item && (
            <div className="mt-10">
              <div>
                <img className="w-1/2 m-auto" src={item.mainImg?.link} alt="" />
              </div>
              <div className="font-bold my-6">{item.title}</div>
              <div className="mb-6 flex">
                <svg
                  id="Layer_1"
                  data-name="Layer 1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 122.88 122.89"
                  width="24px"
                  height="24px"
                >
                  <title>date</title>
                  <path d="M81.61,4.73C81.61,2.12,84.19,0,87.38,0s5.77,2.12,5.77,4.73V25.45c0,2.61-2.58,4.73-5.77,4.73s-5.77-2.12-5.77-4.73V4.73ZM66.11,105.66c-.8,0-.8-10.1,0-10.1H81.9c.8,0,.8,10.1,0,10.1ZM15.85,68.94c-.8,0-.8-10.1,0-10.1H31.64c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H56.77c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H81.9c.8,0,.8,10.1,0,10.1Zm25.14-10.1H107c.8,0,.8,10.1,0,10.1H91.25c-.8,0-.8-10.1,0-10.1ZM15.85,87.3c-.8,0-.8-10.1,0-10.1H31.64c.8,0,.8,10.1,0,10.1ZM41,87.3c-.8,0-.8-10.1,0-10.1H56.77c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H81.9c.8,0,.8,10.1,0,10.1Zm25.14,0c-.8,0-.8-10.1,0-10.1H107c.8,0,.8,10.1,0,10.1Zm-75.4,18.36c-.8,0-.8-10.1,0-10.1H31.64c.8,0,.8,10.1,0,10.1Zm25.13,0c-.8,0-.8-10.1,0-10.1H56.77c.8,0,.8,10.1,0,10.1ZM29.61,4.73C29.61,2.12,32.19,0,35.38,0s5.77,2.12,5.77,4.73V25.45c0,2.61-2.58,4.73-5.77,4.73s-5.77-2.12-5.77-4.73V4.73ZM6.4,43.47H116.47v-22a3,3,0,0,0-.86-2.07,2.92,2.92,0,0,0-2.07-.86H103a3.2,3.2,0,0,1,0-6.4h10.55a9.36,9.36,0,0,1,9.33,9.33v92.09a9.36,9.36,0,0,1-9.33,9.33H9.33A9.36,9.36,0,0,1,0,113.55V21.47a9.36,9.36,0,0,1,9.33-9.33H20.6a3.2,3.2,0,1,1,0,6.4H9.33a3,3,0,0,0-2.07.86,2.92,2.92,0,0,0-.86,2.07v22Zm110.08,6.41H6.4v63.67a3,3,0,0,0,.86,2.07,2.92,2.92,0,0,0,2.07.86H113.55a3,3,0,0,0,2.07-.86,2.92,2.92,0,0,0,.86-2.07V49.88ZM50.43,18.54a3.2,3.2,0,0,1,0-6.4H71.92a3.2,3.2,0,1,1,0,6.4Z" />
                </svg>
                <div className="ml-2 font-bold">{formatDate(item.createdAt || new Date())}</div>
              </div>
              <div className="flex pb-6">
                <div dangerouslySetInnerHTML={{ __html: item.content }} className="flex-1 w-1/2"></div>
                <img
                  className="w-1/2 flex-1 m-auto pl-10"
                  src={item.img?.link || item.urlSmallImage}
                  alt=""
                />
              </div>
            </div>
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalPreview;
