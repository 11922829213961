/* eslint-disable jsx-a11y/img-redundant-alt */
import { Box, Button, Pagination, Typography } from "@mui/material";
import { AxiosError } from "axios";
import BoxContent from "components/common/BoxContent";
import BoxLightBlue from "components/common/BoxLightBule";
import TextFieldComponent from "components/common/Input/TextField";
import CardTable from "components/common/ListTable/CardTable";
import ModalComponent from "components/common/Modal";
import ModalDelete from "components/common/Modal/ModalDelete";
import TechStackDetailComponent from "components/Page/Contents/TechnicalCapability/TechStack";
import { contentApi } from "Datasource/content";
import {
  headerTableTechstackGroup,
  headerTableTechstackPhotos,
} from "Datasource/dataCommonTable";
import useMessage from "Hooks/useMessage";
import { parseInt } from "lodash";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Controller, useForm } from "react-hook-form";
import AddIcon from "@mui/icons-material/Add";

interface Props {}

function TechnicalCapability(props: Props) {
  const {} = props;
  const { openMessage } = useMessage();
  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [nameAction, setNameAction] = useState<string>("");
  const [nameImage, setNameImage] = useState<string>("");
  const [imageUpload, setImageUpload] = useState<any>(null);
  const [listTech, setListTech] = useState<any>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isOpenModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [isOpenModalDeleteItemDetail, setOpenModalDeleteItemDetail] =
    useState<boolean>(false);
  const [itemChosse, setItemChosse] = useState<any>(null);
  const [isModalDetail, setModalDetail] = useState<boolean>(false);
  const [listDetailTechStack, setListDetailTechStack] = useState<any>();
  const [isActionDetailTechStack, setActionDetailTechStack] =
    useState<boolean>(false);
  const [imageChoose, setFileChoose] = useState<any>();
  const [isOpenModalDetailDelete, setOpenModalDetailDelete] =
    useState<boolean>(false);

  useEffect(() => {
    getAllTechStack({page: pageNumber});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: (fs: File[]) => {
      setFileChoose(fs[0]);
      setNameImage(fs[0].name);
      setImageUpload(URL.createObjectURL(fs[0]));
    },
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/gif": [],
    },
  });

  const {
    setValue,
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const {
    register: register2,
    setValue: setValue2,
    control: control2,
    reset: reset2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
  } = useForm<any>();

  const getAllTechStack = async (param: any) => {
    try {
      const {
        data: { META_DATA },
      } = await contentApi.getAllTechStack(param);
      setListTech(META_DATA.items);
      return;
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const onSubmit = async (data: any) => {
    const newParam = {
      name: data.name,
      order: parseInt(data.order),
    };
    if (nameAction == "add") {
      handlerCreateNewTechStack(newParam);
    } else if (nameAction === "edit") {
      handlerEditTechStack(newParam, data.id);
    }
  };

  const renderModalByNameAction = () => {
    return (
      <>
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextFieldComponent
              label="Name"
              value={value}
              onChangeValue={onChange}
              warningText={errors.name && "Field this is required"}
            />
          )}
        />
        <Controller
          name="order"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextFieldComponent
              label="Order"
              value={value}
              onChangeValue={onChange}
              type="number"
              warningText={errors.order && "Field this is required"}
            />
          )}
        />
      </>
    );
  };

  const handlerDetailItem = (item: any) => {
    setModalDetail(true);
    setItemChosse(item._id);
    getDetailItemByApi(item._id);
  };

  const getDetailItemByApi = async (id: string) => {
    try {
      const { data } = await contentApi.getDetailTechStack(id);
      setListDetailTechStack(data.META_DATA);
      // dispatch(changeLoading(false));
    } catch (error) {
      if (error instanceof Error) {
        // dispatch(changeLoading(false));
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const handlerCreateNewTechStack = async (params: any) => {
    try {
      const res = await contentApi
        .handlerCreateTechStack(params)
        .then((res: any) => {
          openMessage({
            variant: `${res.data.STATUS_CODE === 201 ? "success" : "error"}`,
            message: `${
              res.data.STATUS_CODE === 201
                ? "Tạo công nghệ thành công"
                : res.data.MESSAGE_CLIENT
            }`,
          });
          if (res.data.STATUS_CODE === 201) {
            getAllTechStack({
              page: 1,
              sortBy: "createdAt",
              orderBy: "desc"
            });
            onReset();
          }
        });
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const handlerEditTechStack = async (params: any, id: string) => {
    try {
      const res = await contentApi
        .hanlderEditTechStack(params, id)
        .then((res: any) => {
          openMessage({
            variant: `${res.data.STATUS_CODE === 204 ? "success" : "error"}`,
            message: `${
              res.data.STATUS_CODE === 204
                ? "Sửa công nghệ thành công"
                : res.data.MESSAGE_CLIENT
            }`,
          });
          if (res.data.STATUS_CODE === 204) {
            getAllTechStack({
              page: 1
            });
            onReset();
          }
        });
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const onReset = () => {
    reset();
    setOpenModal(false);
    setModalDetail(false);
    setOpenModalDelete(false);
    setOpenModalDeleteItemDetail(false);
    reset2();
    setFileChoose(null);
    setNameImage("");
    setImageUpload("");
    setNameAction("");
  };

  const handlerDeleteTechStack = async (item: any) => {
    try {
      const res = await contentApi
        .dedetedTechStack(item)
        .then((res: any) => {
          reset();
          getAllTechStack({
            page: 1
          });
          setOpenModalDelete(false);
          openMessage({
            variant: `${res.data.STATUS_CODE === 204 ? "success" : "error"}`,
            message: `${
              res.data.STATUS_CODE === 204
                ? "Xóa thành công"
                : res.data.MESSAGE_CLIENT
            }`,
          });
        });
      return;
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const handlerDeleteDetailTechStack = async (item: any) => {
    try {
      const res = await contentApi
        .deletedDetailTechStack(item._id)
        .then((res: any) => {
          reset();
          getAllTechStack({
            page: 1
          });
          setOpenModalDeleteItemDetail(false);
          getDetailItemByApi(itemChosse.group._id);
          openMessage({
            variant: `${res.data.STATUS_CODE === 204 ? "success" : "error"}`,
            message: `${
              res.data.STATUS_CODE === 204
                ? "Xóa thành công"
                : res.data.MESSAGE_CLIENT
            }`,
          });
        });
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const handleAction = (action: any, item: any) => {
    if (action === "deleted") {
      setItemChosse(item);
      setOpenModalDelete(true);
    } else if (action === "edit") {
      setNameAction("edit");
      setValue("name", item.name);
      setValue("order", item.order.toString());
      setValue("id", item._id);
      setOpenModal(true);
    }
  };

  const handleActionDetailTechStack = async (data: any) => {
    let newData = {
      status: "published",
      order: 10,
      group: itemChosse,
    };
    try {
      if (imageChoose) {
        newData = {
          ...data,
          ...newData,
          photo: imageChoose,
        };
      } else {
        newData = {
          ...data,
          ...newData,
        };
      }

      if (nameAction === "edit") {
        const res = await contentApi
          .handlerEditTechStackDetail(data.id, newData)
          .then((res: any) => {
            openMessage({
              variant: `${res.data.STATUS_CODE === 200 ? "success" : "error"}`,
              message: `${
                res.data.STATUS_CODE === 200
                  ? "Sửa công nghệ thành công"
                  : res.data.MESSAGE_CLIENT
              }`,
            });
          });
      } else {
        const res = await contentApi
          .handlerCreateTechStackDetail(newData)
          .then((res: any) => {
            openMessage({
              variant: `${res.data.STATUS_CODE === 201 ? "success" : "error"}`,
              message: `${
                res.data.STATUS_CODE === 201
                  ? "Tạo mới công nghệ thành công"
                  : res.data.MESSAGE_CLIENT
              }`,
            });
            if (res.data.STATUS_CODE === 201) {
              getDetailItemByApi(itemChosse);
              setActionDetailTechStack(false);
              reset2();
              setFileChoose(null);
              setNameImage("");
              setImageUpload("");
            }
          });
      }
    } catch (error) {
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const handlerActionEditItem = (item: any) => {
    if (item) {
      setValue2("name", item?.name);
      // setValue2("photo", item.photo ? item.photo.link : "");
      setValue2("order", item?.order);
      setValue2("id", item?._id);
      setValue2("status", item?.status);
      setValue2("group", item?.group);
      setActionDetailTechStack(true);
      setNameAction("edit");
      if (item.photo) {
        setImageUpload(item?.photo?.link);
        setNameImage(item?.photo?.link);
      }
    }
  };

  return (
    <>
      <BoxLightBlue />
      <BoxContent>
        <Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            className="my-4"
          >
            <Typography>Nhóm Techstack</Typography>
            <Button
                className="m-button m-button-success"
                type="button"
                onClick={() => {
                  setNameAction("add");
                  setOpenModal(true);
                }}
            >
              <AddIcon/> Thêm mới
            </Button>
          </Box>
          <CardTable
            dataHeaderTable={headerTableTechstackGroup}
            className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white overflow-x-auto"
            dataBodyTable={listTech}
            hanlderDetail={handlerDetailItem}
            handleAction={handleAction}
          />
          <Pagination
            style={{ justifyContent: "center", display: "flex" }}
            count={listTech?.lastPage}
            onChange={(e, numberPage: number) => {
              setPageNumber(numberPage);
            }}
            color="primary"
          />
        </Box>
      </BoxContent>
      {/* TODO: Modal edit and add */}
      <ModalComponent
        classNameContent="bg-white"
        open={isOpenModal}
        modalType={nameAction}
        handleClose={() => {
          setOpenModal(false);
          onReset();
        }}
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <>
          <Typography>Techstack Group</Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id="content-form-a"
            className="mt-5"
          >
            <Box overflow={"auto"} maxHeight={`calc(100vh - 300px)`}>
              {renderModalByNameAction()}
            </Box>
            {/*<Button*/}
            {/*  style={{*/}
            {/*    marginBottom: 30,*/}
            {/*    backgroundColor: "rgba(2, 132, 199, 1) !important",*/}
            {/*  }}*/}
            {/*  className="ml-auto w-full w-btnAction text-white text-bold ml-atuo bg-lightBlue-600"*/}
            {/*  type="submit"*/}
            {/*  form="content-form"*/}
            {/*>*/}
            {/*  Save*/}
            {/*</Button>*/}
          </form>
        </>
      </ModalComponent>
      {/* TODO: Modal detail techstask */}
      <ModalComponent
        classNameContent="bg-white"
        open={isModalDetail}
        innerStack = {'open'}
        setActionDetailTechStack={setActionDetailTechStack}
        handleClose={() => {
          setModalDetail(false);
          onReset();
        }}
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <>
          <TechStackDetailComponent
            dataTable={listDetailTechStack}
            headerTable={headerTableTechstackPhotos}
            handerChosseItem={(id: string) => setItemChosse(id)}
            handlerActionDelete={() => setOpenModalDeleteItemDetail(true)}
            handlerActionEdit={(item: any) => handlerActionEditItem(item)}
          >
          </TechStackDetailComponent>
        </>
      </ModalComponent>

      {/*<ModalDelete*/}
      {/*  isOpenModal={isOpenModalDelete}*/}
      {/*  onCloseModal={() => setOpenModalDelete(false)}*/}
      {/*  onClickConfirm={() => handlerDeleteTechStack(itemChosse)}*/}
      {/*/>*/}
      <ModalDelete
          classNameContent="bg-white"
          styleComponent={{
            position: "absolute" as "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
          isOpenModal={isOpenModalDelete}
          onCloseModal={() => setOpenModalDelete(false)}
          onClickConfirm={() => handlerDeleteTechStack(itemChosse._id)}
      />
      <ModalDelete
        isOpenModal={isOpenModalDeleteItemDetail}
        onCloseModal={() => setOpenModalDeleteItemDetail(false)}
        onClickConfirm={() => handlerDeleteDetailTechStack(itemChosse)}
      />
      <ModalComponent
        classNameContent="bg-white"
        open={isActionDetailTechStack}
        handleClose={() => {
          setActionDetailTechStack(false);
          reset2();
          setFileChoose(null);
          setNameImage("");
          setImageUpload("");
          setItemChosse("");
        }}
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <>
          <form
            onSubmit={handleSubmit2(handleActionDetailTechStack)}
            id="content-form-a"
            className="mt-5"
          >
            <Controller
              name="name"
              control={control2}
              rules={{ required: true }}
              render={({ field: { onChange, onBlur, value } }) => (
                <TextFieldComponent
                  label="Name"
                  value={value}
                  onChangeValue={onChange}
                  warningText={errors2.name && "Đây là 1 trường bắt buộc"}
                />
              )}
            />
            <TextFieldComponent
              label="TechStack photos"
              value={nameImage}
              helperText="Vui lòng chỉ updload file dạng png, jpg, gif"
            />
            <Box className="boxUpload">
              <div className="w-full relative" style={{ padding: "0px 10px" }}>
                <div
                  className={`box-border p-2 mt-8`}
                  style={{
                    position: "relative",
                    border: "1px dotted rgba(59, 130, 246, 0.5)",
                  }}
                  {...getRootProps({
                    onClick: (e) => {
                      e.stopPropagation();
                    },
                  })}
                >
                  {isDragActive ? (
                    <div>
                      <Typography className="text-drop-file fw-600">
                        DRAG <span className="tractor">&</span> DROP
                      </Typography>
                    </div>
                  ) : (
                    <>
                      <div
                        className="box-content-drop"
                        {...getRootProps({
                          onClick: (e) => {
                            e.stopPropagation();
                          },
                        })}
                      >
                        <label
                          htmlFor="select_file"
                          className="text-f20 text-bold"
                        >
                          <span className="box-blue">Chọn ảnh</span> hoặc kéo &
                          thả nó ở đây
                        </label>
                        <input
                          {...getInputProps()}
                          type="file"
                          name="file"
                          id="select_file"
                          className="inputFile"
                          placeholder="Chọn ảnh"
                          style={{ display: "none" }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="w-full relative">
                <img
                  loading="lazy"
                  src={imageUpload}
                  className="rounded"
                  alt="image"
                  style={{ width: 300, height: 300, margin: "20px auto" }}
                />
              </div>
            </Box>
          </form>
        </>
      </ModalComponent>
    </>
  );
}

export default TechnicalCapability;
