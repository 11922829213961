import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormHelperText,
  Pagination,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import { changeLoading } from "App/Features/Animation";
import { useAppDispatch } from "App/Store";
import { AxiosError } from "axios";
import BoxContent from "components/common/BoxContent";
import BoxLightBlue from "components/common/BoxLightBule";
import TextFieldComponent from "components/common/Input/TextField";
import CardTable from "components/common/ListTable/CardTable";
import ModalComponent from "components/common/Modal";
import ModalDelete from "components/common/Modal/ModalDelete";
import SelectComponent from "components/common/Select/defeault";
import { contentApi } from "Datasource/content";
import { headerTableRoles } from "Datasource/dataCommonTable";
import useMessage from "Hooks/useMessage";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";

interface Props {}

interface FormSubmit {
  name?: string;
  description?: string;
  permissions?: string;
}

function RolesPage(props: Props) {
  const {} = props;

  const { openMessage } = useMessage();

  const [isOpenModal, setOpenModal] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>("add");
  const [isOpenDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedRoleID, setSelectedRoleID] = useState<string>("");
  const [dataRolesList, setDataRolesList] = useState<any>();
  const [pageNumberRolesList, setPageNumberRolesList] = useState<number>(1);
  const [listPermissions, setListPermissions] = useState<any>();

  const dispatch = useAppDispatch();

  const {
    register: register2,
    setValue: setValue2,
    control: control2,
    reset: reset2,
    formState: { errors: errors2 },
    handleSubmit: handleSearch,
  } = useForm<FormSubmit>();

  const getRolesList = async (pageNumber: number, sortBy: string, orderBy: string) => {
    try {
      const param: any = {
        page: pageNumber,
        sortBy,
        orderBy
      };

      const {
        data: { META_DATA },
      }: any = await contentApi.getRoles(param);

      setPageNumberRolesList(META_DATA.currentPage);
      setDataRolesList(META_DATA);
      dispatch(changeLoading(false));
    } catch (error) {
      dispatch(changeLoading(false));

      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const getListPermissions = async () => {
    try {
      const {
        data: { META_DATA },
      } = await contentApi.getAllPermissions();
      let newData: any = [];
      META_DATA.map((item: any, index: any) => {
        let newItem = {
          value: item._id,
          label: item.name,
        };
        newData.push(newItem);
      });
      setListPermissions(newData);
      dispatch(changeLoading(false));
    } catch (error) {
      dispatch(changeLoading(false));
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const handleChangeRow = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    dispatch(changeLoading(true));
    getRolesList(pageNumberRolesList, "", "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumberRolesList]);

  useEffect(() => {
    dispatch(changeLoading(true));
    getListPermissions();
  }, []);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm<FormSubmit>();

  const renderModal = useMemo(() => {
    return (
      <>
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextFieldComponent
              label="Tên chức vụ"
              value={value}
              onChangeValue={onChange}
              warningText={errors.name && "Đây là 1 trường bắt buộc"}
            />
          )}
        />
        <Controller
          name="description"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <TextFieldComponent
              label="Miêu tả"
              value={value}
              onChangeValue={onChange}
              warningText={errors.description && "Đây là 1 trường bắt buộc"}
            />
          )}
        />
        <Controller
          name="permissions"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, onBlur, value } }) => (
            <>
              <SelectComponent
                value={value}
                onChange={onChange}
                data={listPermissions}
                className="mb-4"
                placeholder="Thêm quyền"
                isMulti
              />
              {errors.permissions && (
                <FormHelperText
                  id="component-error-text"
                  className="text-red-500"
                >
                  Đây là 1 trường bắt buộc
                </FormHelperText>
              )}
            </>
          )}
        />
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listPermissions]);

  const setValueEditingRole = useCallback(
    (item: any) => {
      const permissionsSelectedList = listPermissions.filter(
        (permission: any) => item.permissions.includes(permission.value)
      );
      setValue("name", item.name);
      setValue("description", item.description);
      setValue("permissions", permissionsSelectedList);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [listPermissions]
  );

  const handlerDeleteRole = async (id: string) => {
    try {
      const res = await contentApi.handlerDeleteRole(id).then((res: any) => {
        openMessage({
          variant: `${res.data.STATUS_CODE === 204 ? "success" : "error"}`,
          message: `${
            res.data.STATUS_CODE === 204
              ? "Xóa chức vụ thành công"
              : res.data.MESSAGE_CLIENT
          }`,
        });
        if (res.data.STATUS_CODE === 204) {
          setOpenDeleteModal(false);
          setSelectedRoleID("");
          getRolesList(1, "", "");
        }
      });
      return res;
    } catch (error) {
      const { response } = error as AxiosError;
      const { ...errorObject }: any = response;
      openMessage({
        variant: "error",
        message: errorObject?.data.message,
      });
    }
  };

  const onSubmit = async (data: any) => {
    try {
      if (modalType === "add") {
        dispatch(changeLoading(true));
        const listPermission: string[] = [];
        data.permissions.map((permission: any) => {
          listPermission.push(permission.value);
        });
        const params = {
          ...data,
          permissions: listPermission,
        };
        const res = await contentApi.postNewRole(params).then((res: any) => {
          openMessage({
            variant: `${res.data.STATUS_CODE === 201 ? "success" : "error"}`,
            message: `${
              res.data.STATUS_CODE === 201
                ? "Thêm mới chức vụ thành công"
                : res.data.MESSAGE_CLIENT
            }`,
          });
          if (res.data.STATUS_CODE === 201) {
            setOpenModal(false);
            getRolesList(1, "createdAt", "desc");
            dispatch(changeLoading(false));
            setModalType("add");
            reset();
          }
        });
        return res;
      } else if (modalType === "edit") {
        dispatch(changeLoading(true));
        const listPermission: string[] = [];
        data.permissions.map((permission: any) => {
          listPermission.push(permission.value);
        });
        const params = {
          ...data,
          permissions: listPermission,
        };
        const res = await contentApi
          .handlerEditRole(selectedRoleID, params)
          .then((res: any) => {
            openMessage({
              variant: `${res.data.STATUS_CODE === 200 ? "success" : "error"}`,
              message: `${
                res.data.STATUS_CODE === 200
                  ? "Sửa chức vụ thành công"
                  : res.data.MESSAGE_CLIENT
              }`,
            });
            if (res.data.STATUS_CODE === 200) {
              setOpenModal(false);
              getRolesList(1, "", "");
              dispatch(changeLoading(false));
              setModalType("add");
              setSelectedRoleID("");
              reset();
            }
          });
        return res;
      }
    } catch (error) {
      dispatch(changeLoading(false));
      if (error instanceof Error) {
        const { response } = error as AxiosError;
        const { ...errorObject }: any = response;
        openMessage({
          variant: "error",
          message: errorObject?.data.message,
        });
      }
    }
  };

  const search = (param: any) => {
    //TODO: search apis
  }

  return (
    <>
      <BoxLightBlue />
      <BoxContent>
        <Box>
          <Accordion>
            <AccordionSummary
                className="filter-div"
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
              <Typography className='font-bold'>Bộ lọc</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <form
                  onSubmit={handleSearch(search)}
                  id="content-form"
                  className="mt-5"
              >
                <Box
                    display={"flex"}
                    sx={{"gap": "10px"}}
                    justifyContent={"space-between"}
                    marginTop={1}
                >
                  <Controller
                      name="name"
                      control={control2}
                      rules={{ required: false }}
                      render={({ field: { onChange, onBlur, value } }) => (
                          <TextFieldComponent
                              label="Tên chức vụ"
                              value={value}
                              onChangeValue={onChange}
                              // className="w-50"
                              customStyle={{ height: "40px" }}
                          />
                      )}
                  />
                </Box>
                <Box className="w-full" display={"flex"}>
                  <Button
                      className="m-button m-button-warning m-button-middle-div"
                      type="submit"
                  >
                    <SearchIcon />
                    Tìm kiếm
                  </Button>
                </Box>
              </form>
            </AccordionDetails>
          </Accordion>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            className="my-4"
          >
            <h2><Typography>Danh sách chức vụ</Typography></h2>
            <Button
              className="m-button m-button-success"
              type="button"
              onClick={() => {
                setOpenModal(true);
                setModalType("add");
              }}
            >
              <AddIcon/> Thêm mới
            </Button>
          </Box>
          <CardTable
            dataHeaderTable={headerTableRoles}
            className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-white overflow-x-auto"
            dataBodyTable={dataRolesList?.items}
            handleAction={handleChangeRow}
            currentPage={dataRolesList?.currentPage}
          >
            {dataRolesList &&
              dataRolesList.items?.map((item: any, index: any) => (
                <tr key={index}>
                  <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                    <Typography className={"ml-3 font-bold text-blueGray-600"}>
                      {dataRolesList.currentPage <= 1
                        ? index + 1
                        : (dataRolesList.currentPage - 1) * 10 + index + 1}
                    </Typography>
                  </th>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center ">
                    <Typography>{item?.name}</Typography>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-wrap p-4">
                    <Typography>{item?.description}</Typography>
                  </td>
                  <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-center w-100">
                    <Box display={"flex"}>
                      <IconButton aria-label="delete" className='m-button-rounded m-button-warning mr-5' onClick={() => {
                        setOpenModal(true);
                        setValueEditingRole(item);
                        setModalType("edit");
                        setSelectedRoleID(item._id);
                      }}>
                        <EditIcon />
                      </IconButton>
                      <IconButton aria-label="delete" className='m-button-rounded m-button-danger' onClick={() => {
                        setOpenDeleteModal(true);
                        setSelectedRoleID(item._id);
                      }}>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </td>
                </tr>
              ))}
          </CardTable>
          <Pagination
            style={{ justifyContent: "center", display: "flex" }}
            count={dataRolesList?.lastPage}
            onChange={(e, numberPage: number) => {
              setPageNumberRolesList(numberPage);
            }}
            color="primary"
          />
        </Box>
      </BoxContent>
      <ModalComponent
        classNameContent="bg-white"
        open={isOpenModal}
        handleClose={() => {
          setOpenModal(false);
          reset();
        }}
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        modalType={modalType}
      >
        <>
          <Typography variant="h6">
            {modalType === "add" ? "Thêm mới chức vụ" : "Sửa chức vụ"}
          </Typography>
          <form
            onSubmit={handleSubmit(onSubmit)}
            id="content-form-a"
            className="mt-5"
          >
            <Box overflow={"visible"} maxHeight={600}>
              {renderModal}
            </Box>
          </form>
        </>
      </ModalComponent>
      <ModalDelete
        classNameContent="bg-white"
        styleComponent={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
        isOpenModal={isOpenDeleteModal}
        onCloseModal={() => {
          setOpenDeleteModal(false);
          setSelectedRoleID("");
          setModalType("add");
        }}
        onClickConfirm={() => {
          handlerDeleteRole(selectedRoleID);
          setSelectedRoleID("");
        }}
      />
    </>
  );
}

export default RolesPage;
