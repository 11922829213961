import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {Box} from "@mui/system";
import dayjs from "dayjs";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface Props {
    inputProps : Array<any>;
}

const RevenueChart = (props: Props) => {
    const {
        inputProps
    } = props;

    console.log("inputProps", inputProps);

    const options_balance = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                position: 'bottom' as const,
            },
            title: {
                display: true,
                text: 'Quỹ tồn theo tháng',
            },
        },
    };

    const options_total_revenue = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom' as const,
            },
            title: {
                display: true,
                text: 'Tổng thu và Tổng chi theo tháng',
            },
        },
    };

    const labels = inputProps.map(x => dayjs(`${x.month}`).format("MM/YYYY"));

    const balance = {
        labels,
        datasets: [
            {
                label: '',
                data: inputProps.map(x => x.remaining),
                backgroundColor: 'rgba(66, 133, 244, 1)',
            },
        ],
    };

    const total_revenue = {
        labels,
        datasets: [
            {
                label: 'Tổng thu',
                data: inputProps.map(x => x.totalRevenue),
                backgroundColor: 'rgba(147, 196, 125, 1)\n',
            },
            {
                label: 'Tổng chi',
                data: inputProps.map(x => x.totalExpense),
                backgroundColor: 'rgba(234, 67, 53, 1)\n',
            },
        ],
    };

    return(
        <Box>
            <Bar options={options_balance} data={balance} />
            <Bar options={options_total_revenue} data={total_revenue} />
        </Box>
    );
};

export default RevenueChart;