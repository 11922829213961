import { Box, Button, Modal } from "@mui/material";
import React from "react";

interface Props {
  open: boolean;
  handleClose: () => void;
  styleComponent?: any;
  children?: JSX.Element;
  classNameContent?: string;
  modalType?: any;
  innerStack?: any;
  setActionDetailTechStack?: any;
  hideSaveButton?: boolean;
}

function ModalComponentNoButton(props: Props) {
  const {
    open,
    handleClose,
    styleComponent,
    children,
    classNameContent,
    modalType = "add",
    innerStack = "close",
    setActionDetailTechStack,
    hideSaveButton = "false",
  } = props;

  const handleOpenInnerStack = () => {
    setActionDetailTechStack(true);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleComponent} className={classNameContent}>
        <Box display={"flex"} flexDirection={"column"}>
          {children}
          <div className="m-dialog-footer">
          {innerStack === 'open' ? (
                <Button className={"ml-auto w-fit w-btnAction text-white text-bold bg-lightBlue-600 mr-10 " + (hideSaveButton === true ? "hidden-button" : "")} onClick={handleOpenInnerStack} 
                form="content-form-add">
                    {modalType === "add" ? "Thêm mới" : "Sửa"}
                </Button>)
                    :
                ( <Button className={"ml-auto w-fit w-btnAction text-white text-bold bg-lightBlue-600 mr-10 " + (hideSaveButton === true ? "hidden-button" : "")} type="submit" 
                form="content-form-add">
                    {modalType === "add" ? "Thêm mới" : "Sửa"}
                </Button>)
              }
          </div>
        </Box>
      </Box>
    </Modal>
  );
}

export default ModalComponentNoButton;
